<script>
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
import DrawerRating from "./DrawerRating.vue";
export default {
  name: "DrawerTitle",
  components: { ChargemapSkeletonLoader, DrawerRating },
  props: {
    pool: {
      type: Object,
      default: () => {
      }
    },
    loadingInfo: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    chargemapPoolDetailsUrl() {
      return `https://www.chargemap.com/${this.pool?.slug}.html`;
    },
    stationName() {
      return this.pool?.name || "";
    }
  }
};
</script>

<template>
    <div
        v-if="loadingInfo"
        class="h-full w-full shrink flex-col justify-end items-start gap-2 inline-flex px-2"
        data-testid="drawer-title"
    >
        <ChargemapSkeletonLoader class="w-full h-8" />
        <ChargemapSkeletonLoader class="w-full h-6" />
    </div>
    <div
        v-else
        class="h-full shrink flex-col justify-end items-start gap-2 inline-flex"
        data-testid="drawer-title"
    >
        <a
            class="text-slate-800 text-xl font-bold"
            :href="chargemapPoolDetailsUrl"
            target="_blank"
            rel="noopener noreferrer"
        >
            {{ stationName }}
        </a>

        <DrawerRating :pool />
    </div>
</template>
