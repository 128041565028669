import usePartnersStore from '~/store/partners'
import useNetworksStore from '~/store/networks'
import useSessionStore from '~/store/session'
import type { PartnerRights } from '~/interfaces/user'

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (
        to.name === 'login' ||
        to.name === 'password-reset' ||
        to.name === 'password-reset-update'
    ) {
        return
    }

    // Redirect from old route
    if (to.name === 'partners-idPartner-networks') {
        return navigateTo(`/networks`, { redirectCode: 301 })
    }

    const partnersStore = usePartnersStore()
    const networkStore = useNetworksStore()
    const sessionStore = useSessionStore()

    const {
        currentNetwork,
        currentCommunityNetwork,
        setCurrentCommunityNetwork
    } = networkStore
    const { currentPartner } = partnersStore
    const { idPartner, networkType, idNetwork } = to.params
    const { partnersRights, isSuperAdmin } = sessionStore

    if (networkType) {
        if (
            networkType &&
            !['interop', 'community'].includes(networkType as string)
        ) {
            abortNavigation(
                createError({
                    statusCode: 404,
                    statusMessage: 'Not Found'
                })
            )
        }
    }

    if (to.name === 'partners' && !isSuperAdmin) {
        return navigateTo('/networks')
    }

    useSessionStore().lastRoute = from.fullPath

    const idPartnerInRoute = !!idPartner && idPartner !== 'undefined'

    if (!idPartnerInRoute) {
        if (!currentPartner) {
            partnersStore.fetchPartners(undefined, true).then((result) => {
                if (result?.items.length) {
                    partnersStore.setCurrentPartner(result.items[0])
                }
                abortNavigation()
                navigateTo(`/networks`)
            })

            return
        } else if (idPartner === 'undefined') {
            abortNavigation()

            return navigateTo('/networks')
        }
    } else {
        const partnerRole =
            partnersRights.find(
                (item: PartnerRights) =>
                    item.id === parseInt(idPartner as string, 10)
            )?.role || null

        if (!partnerRole && !isSuperAdmin) {
            partnersStore.setCurrentPartner(null)

            abortNavigation()

            return navigateTo(`/networks?error=unauthorized&time=${Date.now()}`)
        }

        if (!currentPartner?.id || currentPartner.id.toString() !== idPartner) {
            try {
                await partnersStore.fetchPartner(
                    parseInt(idPartner as string, 10)
                )
            } catch {
                abortNavigation()

                return navigateTo(
                    `/networks?error=unauthorized&time=${Date.now()}`
                )
            }
        }
    }

    if (networkType && idNetwork && idPartnerInRoute) {
        if (
            (networkType === 'community' && !currentCommunityNetwork?.id) ||
            (currentCommunityNetwork?.id !==
                parseInt(idNetwork as string, 10) &&
                networkType === 'community')
        ) {
            try {
                const fetchedNetwork = await networkStore.fetchNetwork(
                    idPartner as string,
                    parseInt(idNetwork as string, 10),
                    'community'
                )

                setCurrentCommunityNetwork(fetchedNetwork || null)
            } catch {
                navigateTo('/networks')

                return
            }
        } else if (
            (networkType === 'interop' && !currentNetwork?.id) ||
            (currentNetwork?.id !== parseInt(idNetwork as string, 10) &&
                networkType === 'interop')
        ) {
            try {
                await networkStore.fetchNetwork(
                    idPartner as string,
                    parseInt(idNetwork as string, 10),
                    'interop'
                )
            } catch {
                navigateTo('/networks')

                return
            }
        }
    }

    if (to.name === 'index' && currentPartner) {
        abortNavigation()

        if (currentNetwork) {
            return navigateTo({
                name: 'partners-idPartner-networks-idNetwork-networkType-data',
                params: {
                    idPartner: currentPartner.id,
                    idNetwork: currentNetwork.id,
                    networkType: 'interop'
                }
            })
        } else if (currentCommunityNetwork) {
            return navigateTo({
                name: 'partners-idPartner-networks-networkType-idNetwork-data',
                params: {
                    idPartner: currentPartner.id,
                    idNetwork: currentCommunityNetwork.id,
                    networkType: 'community'
                }
            })
        } else {
            abortNavigation()

            return navigateTo('/networks')
        }
    }

    if (
        to.name === 'partners-idPartner' &&
        (currentNetwork || currentCommunityNetwork)
    ) {
        const networkType = currentNetwork ? 'interop' : 'community'

        abortNavigation()

        return navigateTo({
            name: 'partners-idPartner-networks-networkType-idNetwork-data',
            params: {
                idPartner: currentPartner?.id,
                idNetwork: currentNetwork?.id || currentCommunityNetwork?.id,
                networkType
            }
        })
    } else if (
        to.name === 'partners-idPartner' &&
        !currentNetwork &&
        !currentCommunityNetwork
    ) {
        abortNavigation()

        return navigateTo('/networks')
    }
})
