<script>
import BaseButton from "../../common/global/BaseButton.vue";
export default {
  name: "ChargemapB2CButton",
  components: { BaseButton },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return [
          "primary",
          "secondary",
          "tertiary",
          "floating-neutral"
        ].includes(value);
      }
    },
    size: {
      type: String,
      default: "",
      validator(value) {
        return ["", "large", "medium", "small", "shrink"].includes(
          value
        );
      }
    },
    variant: {
      type: String,
      default: ""
    },
    custom: {
      type: String,
      default: ""
    },
    customClassesForBaseButton: {
      type: String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      let classes = `cmp-button--${this.type}`;
      if (this.size !== "") {
        classes += ` cmp-button--${this.type}__${this.size}`;
      }
      if (this.variant !== "") {
        classes += ` cmp-button--${this.type}__${this.variant}`;
      }
      if (this.custom !== "") {
        classes += ` cmp-button--${this.custom}`;
      }
      return classes;
    }
  }
};
</script>

<template>
    <BaseButton
        data-testid="chargemap-button"
        class="cmp-button"
        :class="classes"
        :custom-classes="customClassesForBaseButton"
        v-bind="$attrs"
        :is-disabled="isDisabled"
    >
        <template #icon>
            <slot v-if="$slots.icon" name="icon" />
        </template>
        <slot />
    </BaseButton>
</template>

<style scoped>
.cmp-button {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  cursor: pointer;
  transition: all 400ms;
}
.cmp-button--align-center {
  align-items: center;
}
.cmp-button--full-width {
  width: 100%;
}
.cmp-button--min-width {
  min-width: 300px;
}
@media (max-width: 768px) {
  .cmp-button--min-width {
    min-width: 260px;
  }
}
.cmp-button--less-padding {
  padding: 12px 10px !important;
}
.cmp-button:hover, .cmp-button:focus {
  outline: none;
  text-decoration: none !important;
}
.cmp-button--primary {
  background-color: #0da2e7 !important;
  border: 1px solid #0da2e7;
  border-radius: 8px;
  color: #ffffff !important;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 120%;
  padding: 14px 18px;
}
.cmp-button--primary__large {
  font-size: 15px;
  padding: 11px 16px;
}
.cmp-button--primary__medium {
  font-size: 13px;
  letter-spacing: -0.13px;
  padding: 8px 10px;
  border-radius: 6px;
}
.cmp-button--primary__small {
  font-size: 11px;
  letter-spacing: -0.11px;
  padding: 5px 8px;
  border-radius: 4px;
}
.cmp-button--primary:hover {
  color: #ffffff !important;
  background-color: #22bcff !important;
  border: 1px solid #22bcff;
}
.cmp-button--primary:focus {
  background-color: #0080c5 !important;
  border: 1px solid #0080c5;
}
.cmp-button--primary:disabled {
  color: #a1a1aa !important;
  background-color: #e4e4e7 !important;
  border: 1px solid #e4e4e7;
  cursor: not-allowed;
}
.cmp-button--secondary {
  background-color: #ffffff !important;
  border: 1px solid #3f3f46;
  border-radius: 8px;
  color: #3f3f46 !important;
  text-align: center;
  font-size: 17px;
  padding: 14px 18px;
  font-weight: 600;
  line-height: 120%;
}
.cmp-button--secondary:disabled, .cmp-button--secondary:disabled:hover, .cmp-button--secondary:disabled:focus {
  color: #a1a1aa !important;
  background-color: #e4e4e7 !important;
  border: 1px solid #e4e4e7;
  cursor: not-allowed;
}
.cmp-button--secondary__transparent-bg {
  background: transparent !important;
}
.cmp-button--secondary__large {
  font-size: 15px;
  padding: 11px 16px;
}
.cmp-button--secondary__medium {
  font-size: 13px;
  letter-spacing: -0.13px;
  padding: 8px 10px;
  border-radius: 6px;
}
.cmp-button--secondary__small {
  font-size: 11px;
  letter-spacing: -0.11px;
  padding: 5px 8px;
  border-radius: 4px;
}
.cmp-button--secondary:hover {
  color: #71717a !important;
  background-color: #ffffff !important;
  border: 1px solid #71717a;
}
.cmp-button--secondary:focus {
  text-decoration: none;
  color: #ffffff !important;
  background-color: #3f3f46 !important;
  border: 1px solid #3f3f46;
}
.cmp-button--secondary__blue {
  color: #0069a6 !important;
  background-color: transparent !important;
  border: 1px solid #0069a6;
}
.cmp-button--secondary__blue:hover {
  color: #0da2e7 !important;
  background-color: transparent !important;
  border: 1px solid #0da2e7;
}
.cmp-button--secondary__blue:focus {
  color: #ffffff !important;
  background-color: #0069a6 !important;
  border: 1px solid #0069a6;
}
.cmp-button--secondary__red {
  color: #ba1c1c !important;
  background-color: #ffffff !important;
  border: 1px solid #ba1c1c;
}
.cmp-button--secondary__red:hover {
  color: #ef4343 !important;
  background-color: #ffffff !important;
  border: 1px solid #ef4343;
}
.cmp-button--secondary__red:focus {
  color: #ffffff !important;
  background-color: #ba1c1c !important;
  border: 1px solid #ba1c1c;
}
.cmp-button--secondary__green {
  color: #158037 !important;
  background-color: #ffffff !important;
  border: 1px solid #158037;
}
.cmp-button--secondary__green:hover {
  color: #22c543 !important;
  background-color: #ffffff !important;
  border: 1px solid #22c543;
}
.cmp-button--secondary__green:focus {
  color: #ffffff !important;
  background-color: #158037 !important;
  border: 1px solid #158037;
}
.cmp-button--secondary__yellow {
  color: #b35309 !important;
  background-color: #ffffff !important;
  border: 1px solid #b35309;
}
.cmp-button--secondary__yellow:hover {
  color: #f59f0a !important;
  background-color: #ffffff !important;
  border: 1px solid #f59f0a;
}
.cmp-button--secondary__yellow:focus {
  color: #ffffff !important;
  background-color: #b35309 !important;
  border: 1px solid #b35309;
}
.cmp-button--secondary__purple {
  color: #ffffff !important;
  background-color: #9c4eb8 !important;
  border: 1px solid #9c4eb8;
}
.cmp-button--secondary__purple:hover {
  color: #ffffff !important;
  background-color: #7f3c97 !important;
  border: 1px solid #7f3c97;
}
.cmp-button--secondary__purple:focus {
  color: #ffffff !important;
  background-color: #9c4eb8 !important;
  border: 1px solid #9c4eb8;
}
.cmp-button--secondary__orange {
  color: #ffffff !important;
  background-color: #f2a93c !important;
  border: 1px solid #f2a93c;
}
.cmp-button--secondary__orange:hover {
  color: #ffffff !important;
  background-color: #eb9310 !important;
  border: 1px solid #eb9310;
}
.cmp-button--secondary__orange:focus {
  color: #ffffff !important;
  background-color: #f2a93c !important;
  border: 1px solid #f2a93c;
}
.cmp-button--secondary__full-green {
  color: #ffffff !important;
  background-color: #188412 !important;
  border: 1px solid #188412;
}
.cmp-button--secondary__full-green:hover {
  color: #ffffff !important;
  background-color: #10570c !important;
  border: 1px solid #10570c;
}
.cmp-button--secondary__full-green:focus {
  color: #ffffff !important;
  background-color: #188412 !important;
  border: 1px solid #188412;
}
.cmp-button--secondary__full-pink {
  color: #ffffff !important;
  background-color: #f43f5e !important;
  border: 1px solid #f43f5e;
}
.cmp-button--secondary__full-pink:hover {
  color: #ffffff !important;
  background-color: #f10f36 !important;
  border: 1px solid #f10f36;
}
.cmp-button--secondary__full-pink:focus {
  color: #ffffff !important;
  background-color: #f43f5e !important;
  border: 1px solid #f43f5e;
}
.cmp-button--secondary__pink {
  color: #881337 !important;
  background-color: transparent !important;
  border: 1px solid #881337;
}
.cmp-button--secondary__pink:hover {
  color: #881337 !important;
  background-color: #ffe6e9 !important;
  border: 1px solid #881337;
}
.cmp-button--secondary__pink:focus {
  color: #881337 !important;
  background-color: transparent !important;
  border: 1px solid #881337;
}
.cmp-button--secondary__dark-blue {
  color: #fff !important;
  background-color: transparent !important;
  border: 1px solid #fff;
}
.cmp-button--secondary__dark-blue:hover {
  color: #fff !important;
  background-color: #2372f5 !important;
  border: 1px solid #fff;
}
.cmp-button--secondary__dark-blue:focus {
  color: #fff !important;
  background-color: transparent !important;
  border: 1px solid #fff;
}
.cmp-button--secondary__full-red {
  color: #ffffff !important;
  background-color: #dc2626 !important;
  border: 1px solid #dc2626;
}
.cmp-button--secondary__full-red:hover {
  color: #ffffff !important;
  background-color: #dc2626 !important;
  border: 1px solid #dc2626;
}
.cmp-button--secondary__full-red:focus {
  color: #ffffff !important;
  background-color: #dc2626 !important;
  border: 1px solid #dc2626;
}
.cmp-button--secondary__grey {
  color: #ffffff !important;
  background-color: #495057 !important;
  border: 1px solid #495057;
}
.cmp-button--secondary__grey:hover {
  color: #ffffff !important;
  background-color: #6c757d !important;
  border: 1px solid #6c757d;
}
.cmp-button--secondary__grey:focus {
  color: #ffffff !important;
  background-color: #495057 !important;
  border: 1px solid #495057;
}
.cmp-button--tertiary {
  border: 0;
  color: #0069a6 !important;
  text-align: center;
  font-size: 17px;
  padding: 4px;
  font-weight: 600;
  line-height: 120%;
}
.cmp-button--tertiary__large {
  font-size: 15px;
  padding: 2px;
}
.cmp-button--tertiary__medium {
  font-size: 13px;
  letter-spacing: -0.13px;
  padding: 2px;
}
.cmp-button--tertiary__small {
  font-size: 11px;
  letter-spacing: -0.11px;
  padding: 2px;
}
.cmp-button--tertiary:hover {
  color: #0da2e7 !important;
  background: transparent !important;
}
.cmp-button--tertiary:focus {
  text-decoration: none;
  color: #0069a6 !important;
  outline: none;
}
.cmp-button--tertiary:disabled {
  color: #a1a1aa !important;
  cursor: not-allowed;
}
.cmp-button--tertiary__red {
  color: #ba1c1c !important;
}
.cmp-button--tertiary__red:hover {
  color: #ef4343 !important;
}
.cmp-button--tertiary__red:focus {
  color: #ba1c1c !important;
}
.cmp-button--tertiary__green {
  color: #158037 !important;
}
.cmp-button--tertiary__green:hover {
  color: #22c543 !important;
}
.cmp-button--tertiary__green:focus {
  color: #ffffff !important;
}
.cmp-button--tertiary__black {
  color: #3f3f46 !important;
}
.cmp-button--tertiary__black:hover {
  color: #71717a !important;
}
.cmp-button--tertiary__black:focus {
  color: #3f3f46 !important;
}
.cmp-button--tertiary__pink {
  color: #881337 !important;
}
.cmp-button--tertiary__pink:hover {
  color: #f43f5e !important;
}
.cmp-button--tertiary__pink:focus {
  color: #881337 !important;
}
.cmp-button--floating-neutral {
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  border-radius: 800px;
  box-shadow: 0 8px 16px 0 rgba(19, 47, 68, 0.2), 0 0 2px 0 rgba(19, 47, 68, 0.1);
  color: #27272a;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 120%;
  padding: 14px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.cmp-button--floating-neutral i {
  font-size: 24px;
}
.cmp-button--floating-neutral span {
  transition: all 400ms ease-in-out;
}
.cmp-button--floating-neutral__large {
  font-size: 15px;
  padding: 11px 16px;
}
.cmp-button--floating-neutral__large i {
  font-size: 20px;
}
.cmp-button--floating-neutral__medium {
  font-size: 15px;
  padding: 7px 10px;
}
.cmp-button--floating-neutral__medium i {
  font-size: 16px;
}
.cmp-button--floating-neutral__small {
  font-size: 11px;
  letter-spacing: -0.11px;
  padding: 5px 8px;
}
.cmp-button--floating-neutral__small i {
  font-size: 12px;
}
.cmp-button--floating-neutral:hover {
  color: #27272a !important;
  background-color: #e4e4e7 !important;
  border: 1px solid #e4e4e7;
}
.cmp-button--floating-neutral:focus {
  color: #ffffff !important;
  background-color: #52525b !important;
  border: 1px solid #52525b;
}
@media (max-width: 768px) {
  .cmp-button--floating-neutral__shrink {
    gap: 0;
    padding: 12px;
  }
  .cmp-button--floating-neutral__shrink span {
    font-size: 0;
  }
}
</style>
