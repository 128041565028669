<script>
const SHAPES = ["rectangle", "circle"];
export default {
  name: "ChargemapSkeletonLoader",
  props: {
    type: {
      type: String,
      default: SHAPES[0],
      validator(value) {
        return SHAPES.includes(value);
      }
    },
    cssClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      LOADER_CSS_CLASSES: {
        rectangle: "rounded",
        circle: "rounded-full"
      }
    };
  },
  computed: {
    loaderClass() {
      return this.cssClass || this.LOADER_CSS_CLASSES[this.type];
    }
  }
};
</script>

<template>
    <div
        :class="[loaderClass, 'bg-[#F4F4F5] relative overflow-hidden']"
        data-testid="skeleton-loader"
    >
        <div class="shimmer absolute top-0 right-0 bottom-0 left-0" />
        <slot />
    </div>
</template>

<style scoped>
.shimmer{animation:shimmer 2s infinite;background:linear-gradient(90deg,#f4f4f5,#e4e4e7,#f4f4f5);transform:translateX(-100%)}@keyframes shimmer{to{transform:translateX(100%)}}
</style>
