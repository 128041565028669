<script>
import ChargemapButton from "../../b2b/global/ChargemapButton.vue";
import ChargemapDrawer from "./ChargemapDrawer.vue";
export default {
  name: "ChargemapFiltersContainer",
  components: { ChargemapButton, ChargemapDrawer },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    textApply: {
      type: String,
      required: true
    },
    textCancel: {
      type: String,
      required: true
    },
    inDrawer: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "right"
    }
  },
  emits: ["updateFilters", "updateVisible"],
  data() {
    return {
      closeDrawerSwitch: false,
      showCloseButton: false
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.closeDrawerSwitch = true;
        this.$nextTick(() => {
          this.closeDrawerSwitch = false;
        });
      }
    }
  },
  methods: {
    updateFilters() {
      this.$emit("updateFilters");
    },
    updateVisible(val) {
      this.$emit("updateVisible", val);
    },
    updateShowCloseButton(val) {
      this.showCloseButton = val;
    }
  }
};
</script>

<template>
    <ChargemapDrawer
        data-testid="chargemap-filters-container"
        :visible="show"
        :close-height="200"
        :forced-mobile="inDrawer"
        :force-full-size="inDrawer"
        :position="position"
        :title="$t('filters.title')"
        :close-trigger="closeDrawerSwitch"
        @updateVisible="updateVisible"
        @show-close-button="updateShowCloseButton"
    >
        <div class="flex flex-col h-full justify-between overflow-auto">
            <slot />
            <div
                class="flex flex-row gap-2 sticky bottom-0 p-4 bg-white border-t border-gray-200 z-1"
            >
                <ChargemapButton
                    class="flex-1"
                    btn-alt="clear"
                    @click="updateVisible(false)"
                >
                    {{ textCancel }}
                </ChargemapButton>
                <ChargemapButton class="flex-1" @click="updateFilters">
                    {{ textApply }}
                </ChargemapButton>
            </div>
        </div>
    </ChargemapDrawer>
</template>
