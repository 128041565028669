<script>
import { DateTime } from "luxon";
import IconChatAlt from "../../../icons/IconChatAlt.vue";
export default {
  name: "CommentHeader",
  components: {
    IconChatAlt
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    isAnonymous: {
      type: Boolean,
      required: true
    },
    creationDate: {
      type: Object,
      default: DateTime.now()
    },
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    displayDate() {
      const now = DateTime.now();
      if (now.diff(this.creationDate, "hours").hours >= 24) {
        return this.creationDate.toRelativeCalendar({
          locale: this.locale
        });
      }
      return this.creationDate.toRelative({ locale: this.locale });
    },
    getUsername() {
      return this.user?.username;
    }
  }
};
</script>

<template>
    <div class="flex flex-row gap-4 items-center" data-testid="comment-header">
        <IconChatAlt class="h-[18px] w-[18px] text-gray-comment" is-solid />
        <div
            v-if="isAnonymous"
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            <span class="text-gray-500 font-normal">
                {{ $t('anonymous.has_commented') }} {{ displayDate }}
            </span>
        </div>
        <div
            v-else
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            {{ getUsername }}
            <span class="text-gray-500 font-normal">
                {{ $t('has_commented') }} {{ displayDate }}
            </span>
        </div>
    </div>
</template>
