<script>
import { AccessType, LocationType } from "../../../../interfaces/poolDetail";
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
import DrawerInfoAccess from "./info/DrawerInfoAccess.vue";
import DrawerInfoAmenities from "./info/DrawerInfoAmenities.vue";
import DrawerInfoDescription from "./info/DrawerInfoDescription.vue";
import DrawerInfoHours from "./info/DrawerInfoHours.vue";
import DrawerInfoNetwork from "./info/DrawerInfoNetwork.vue";
import DrawerOtherInfo from "./info/DrawerOtherInfo.vue";
export default {
  name: "DrawerTabInfo",
  components: {
    DrawerInfoHours,
    DrawerInfoDescription,
    DrawerInfoAccess,
    DrawerInfoNetwork,
    DrawerInfoAmenities,
    ChargemapSkeletonLoader,
    DrawerOtherInfo
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    },
    isPartner: {
      type: Boolean,
      required: true
    },
    loadingInfo: {
      type: Boolean,
      required: true
    },
    hasPass: {
      type: Boolean,
      required: true
    }
  },
  emits: ["orderPass"],
  data() {
    return {
      LocationType,
      AccessType
    };
  },
  computed: {
    showAmenities() {
      return this.pool?.amenities && this.pool?.amenities.length > 0 || false;
    },
    getAmenities() {
      return this.pool?.amenities || [];
    },
    getDescription() {
      return this.pool?.description;
    },
    getSchedules() {
      return this.pool?.schedules || [];
    },
    getAlwaysOpen() {
      return this.pool?.alwaysOpen || false;
    },
    getLocationType() {
      return this.pool?.location;
    },
    getAccessType() {
      return this.pool?.access;
    },
    getParkingFree() {
      return this.pool?.parkingFree;
    },
    getFloorLevel() {
      return this.pool?.floorLevel;
    },
    getAuthMethods() {
      if (!this.pool?.stations || this.pool?.stations?.length === 0) {
        return [];
      }
      const authMethods = this.pool?.stations?.flatMap(
        (station) => station.authenticationMethods
      );
      const res = [];
      authMethods.forEach((authMethod) => {
        if (!res.includes(authMethod)) res.push(authMethod);
      });
      return res;
    }
  }
};
</script>

<template>
    <div data-testid="info-tab">
        <div
            v-if="loadingInfo"
            class="flex flex-col justify-center items-center gap-5"
        >
            <ChargemapSkeletonLoader class="rounded w-20 h-6" />

            <ChargemapSkeletonLoader
                class="rounded-2xl w-full h-64 border border-zinc-300"
            />
        </div>
        <div v-else class="flex flex-col gap-5">
            <DiscoverChargemapPass
                v-if="!hasPass"
                croppedPass
                :compatible="!!pool?.chargemapPassCompatible"
                @click="$emit('orderPass', $event)"
            />
            <DrawerInfoAccess
                :floor-number="getFloorLevel"
                :is-parking-free="getParkingFree"
                :location-type="getLocationType"
                :access-type="getAccessType"
                :authentication-methods="getAuthMethods"
            />
            <DrawerInfoDescription
                v-if="getDescription"
                :description="getDescription"
            />
            <DrawerInfoHours
                :is-always-open="getAlwaysOpen"
                :schedules="getSchedules"
            />
            <DrawerInfoNetwork v-if="!isPartner" :pool="pool" />
            <DrawerInfoAmenities
                v-if="showAmenities"
                :amenities="getAmenities"
            />
            <DrawerOtherInfo v-if="pool?.dateUpdated" :pool />
        </div>
    </div>
</template>
