<script>
import { ReliabilityActions } from "../../../../interfaces/drawer";
import ChargemapButton from "../../../b2b/global/ChargemapButton.vue";
import IconLightningBolt from "../../../icons/IconLightningBolt.vue";
import IconSpeaker from "../../../icons/IconSpeaker.vue";
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
import PoolRating from "../../rating/PoolRating.vue";
import RatingDetailPopup from "../../rating/RatingDetailPopup.vue";
export default {
  name: "DrawerReliabilityBar",
  components: {
    PoolRating,
    IconLightningBolt,
    IconSpeaker,
    ChargemapButton,
    RatingDetailPopup,
    ChargemapSkeletonLoader
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    },
    loadingInfo: {
      type: Boolean,
      required: true
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  emits: {
    reliabilityClicked(payload) {
      return payload;
    }
  },
  computed: {
    getGlobalScore() {
      return this.pool?.statistic?.globalNoteAverage || 0;
    },
    getMaterialScore() {
      return this.pool?.statistic?.materialNoteAverage || 0;
    },
    getPriceScore() {
      return this.pool?.statistic?.priceNoteAverage || 0;
    },
    getLocationScore() {
      return this.pool?.statistic?.locationNoteAverage || 0;
    },
    getSecurityScore() {
      return this.pool?.statistic?.securityNoteAverage || 0;
    },
    getRating() {
      return this.pool?.rating || 0;
    },
    getRatingsCount() {
      return this.pool?.ratingCount || 0;
    },
    getChargesCount() {
      return this.pool?.statistic?.checkinsCount || 0;
    },
    getReportsCount() {
      return this.pool?.statistic?.reportsCount || 0;
    },
    isDisabled() {
      return this.loadingInfo || this.getRatingsCount === 0;
    }
  },
  methods: {
    chargeClicked() {
      this.$emit("reliabilityClicked", ReliabilityActions.CHARGE);
    },
    reportsClicked() {
      this.$emit("reliabilityClicked", ReliabilityActions.REPORT);
    },
    ratingsClicked() {
      this.$emit("reliabilityClicked", ReliabilityActions.RATING);
      this.$refs.ratingDetailPopup.show();
    }
  }
};
</script>

<template>
    <div
        class="grid grid-cols-[1fr_2fr] gap-2"
        data-testid="drawer-reliability-bar"
    >
        <div
            class="h-fit transition-all duration-300 ease-in-out rounded-lg border border-zinc-300 flex justify-center items-center overflow-hidden"
        >
            <ChargemapSkeletonLoader
                v-if="loadingInfo"
                class="w-full h-14 flex-1"
            />
            <ChargemapButton
                v-else
                btn-alt="clear-full"
                class="h-full w-full flex-1 py-3 !rounded-none"
                :class="isDisabled && '!bg-transparent'"
                :is-disabled="isDisabled"
                @click="ratingsClicked"
            >
                <PoolRating
                    class="justify-center items-center"
                    :rating="getRating"
                    :rating-count="getRatingsCount"
                    :disabled="isDisabled"
                    :display-rating-count="true"
                    orientation="vertical"
                    size="small"
                    responsive
                />
            </ChargemapButton>
        </div>
        <div
            class="transition-all duration-300 ease-in-out rounded-lg border border-zinc-300 divide-x-1 divide-zinc-300 flex justify-center items-center overflow-hidden"
        >
            <ChargemapSkeletonLoader
                v-if="loadingInfo"
                class="w-full h-14 flex-1 rounded-none"
            />
            <ChargemapButton
                v-else
                btn-alt="clear-full-no-focus"
                :is-disabled="isDisabled"
                :class="isDisabled && '!bg-transparent'"
                class="h-full w-full flex-1 !rounded-none"
                @click="chargeClicked"
            >
                <div
                    class="flex flex-col md:flex-row items-center justify-center md:gap-4"
                >
                    <IconLightningBolt
                        class="size-8 md:w-10 md:h-10"
                        :class="
                            isDisabled ? '!text-zinc-300' : '!text-green-500'
                        "
                    />
                    <span
                        class="text-xs md:text-base font-normal"
                        :class="
                            isDisabled ? '!text-zinc-300' : '!text-zinc-800'
                        "
                    >
                        {{ getChargesCount }}
                    </span>
                </div>
            </ChargemapButton>
            <ChargemapSkeletonLoader
                v-if="loadingInfo"
                class="w-full h-14 flex-1 rounded-none"
            />
            <ChargemapButton
                v-else
                :is-disabled="isDisabled"
                btn-alt="clear-full-no-focus"
                :class="isDisabled && '!bg-transparent'"
                class="h-full w-full flex-1 !rounded-none"
                @click="reportsClicked"
            >
                <div
                    class="flex flex-col md:flex-row items-center justify-center md:gap-4"
                >
                    <IconSpeaker
                        class="size-8 md:w-10 md:h-10"
                        :class="isDisabled ? '!text-zinc-300' : '!text-red-500'"
                    />
                    <span
                        class="text-xs md:text-base font-normal"
                        :class="
                            isDisabled ? '!text-zinc-300' : '!text-zinc-800'
                        "
                    >
                        {{ getReportsCount }}
                    </span>
                </div>
            </ChargemapButton>
        </div>

        <RatingDetailPopup
            ref="ratingDetailPopup"
            :global-score-average="getGlobalScore"
            :location-score-average="getLocationScore"
            :material-score-average="getMaterialScore"
            :price-score-average="getPriceScore"
            :security-score-average="getSecurityScore"
            :cdnUrl
        >
            <template #title>
                {{ $t('evaluation') }}
            </template>
        </RatingDetailPopup>
    </div>
</template>
../../../../interfaces/drawer;d ../../../../interfaces/drawer
