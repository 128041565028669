<script>
import IconCross from '../../icons/IconCross.vue'
import IconSearch from '../../icons/IconSearch.vue'

export default {
    name: 'Search',
    components: {
        IconCross,
        IconSearch
    },
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: 'input-search'
        },
        name: {
            type: String,
            default: 'search'
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'change'],
    data() {
        return {
            search: this.value
        }
    },
    watch: {
        value() {
            this.search = this.value
        }
    },
    methods: {
        onInput() {
            this.$emit('input', this.search)
        },
        onChange() {
            this.$emit('change', this.search)
        },
        clear() {
            this.search = ''
            this.$emit('input', this.search)
            this.$emit('change', this.search)
        }
    }
}
</script>

<template>
    <div class="search" data-testid="search">
        <div class="search__container">
            <input
                :id="id"
                v-bind="$attrs"
                ref="inputSearch"
                v-model.trim="search"
                :type="type"
                :name="name"
                class="search__input placeholder-muted"
                :placeholder="placeholder"
                :disabled="isDisabled"
                @input="onInput"
                @change="onChange"
            />
            <div
                class="absolute inset-y-0 right-0 mr-4 flex items-center cursor-pointer"
                @click="clear"
            >
                <IconCross v-if="value" class="size-4 text-globalSecondary" />
                <IconSearch v-else class="size-4 text-globalSecondary" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.search {
  @apply fixed md:static
        top-12 inset-x-0
        flex flex-col
        w-full lg:w-2/5
        h-11.5
        mt-2 md:mt-0
        transform -translate-y-28 md:transform-none
        transition-transform z-40 md:z-auto;
}
.search--show {
  @apply translate-y-0;
}
.search__container {
  @apply relative
            rounded-md shadow-sm;
}
.search__input {
  @apply block w-full
             h-11.5
            py-2 px-4
            text-sm leading-5
            border
            border-gray-300 rounded-md;
}
.search__input:focus {
  @apply ring-blue-500 border-blue-500;
}
.search__input:disabled {
  @apply bg-disabled text-gray-400 border-gray-200;
}
.search__input ::placeholder {
  color: blue;
  font-size: 1.5em;
}
.search__input--error {
  @apply block w-full
                pr-10
                text-red-900 placeholder-red-300
                border-red-300 rounded-md;
}
.search__input--error:focus {
  @apply ring-red-500 border-red-500;
}
</style>
