<script>
import Report from "./report/Report.vue";
import Checkin from "./checkin/Checkin.vue";
import Comment from "./comment/Comment.vue";
import CommentResponse from "./comment/CommentResponse.vue";
import FeedbackCard from "./FeedbackCard.vue";
export default {
  name: "Feedback",
  components: {
    FeedbackCard,
    Checkin,
    Comment,
    Report,
    CommentResponse
  },
  props: {
    feedbackType: {
      type: String,
      required: true
    },
    commonProps: {
      type: Object,
      required: true
    },
    reportProps: {
      type: Object,
      default() {
        return {};
      }
    },
    checkinProps: {
      type: Object,
      default() {
        return {};
      }
    },
    commentProps: {
      type: Object,
      default() {
        return {};
      }
    },
    displayOlderCommentsLabel: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      required: true
    },
    cardNoBorder: {
      type: Boolean,
      default: false
    },
    cardNoPadding: {
      type: Boolean,
      default: false
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    getResponse() {
      return this.commonProps?.response?.content;
    },
    getResponseName() {
      return this.commonProps?.response?.name;
    },
    getResponseDate() {
      return this.commonProps?.response?.creationDate;
    },
    getResponseSecondary() {
      return this.commonProps?.responseSecondary?.content;
    },
    getResponseSecondaryName() {
      return this.commonProps?.responseSecondary?.name;
    },
    getResponseSecondaryDate() {
      return this.commonProps?.responseSecondary?.creationDate;
    },
    getMustDisplayWholeComment() {
      return this.commonProps?.mustDisplayWholeComment;
    },
    commentsAreReversed() {
      return this.getResponseDate > this.getResponseSecondaryDate;
    }
  }
};
</script>

<template>
    <div class="flex flex-col gap-2 w-full" data-testid="feedback">
        <span
            v-if="displayOlderCommentsLabel"
            class="text-gray-500 font-medium text-base leading-4 tracking-wider pt-4 pb-2 text-center w-full"
        >
            {{ $t('older_comments').toUpperCase() }}
        </span>
        <FeedbackCard :no-border="cardNoBorder" :no-padding="cardNoPadding">
            <template #title>
                <component :is="feedbackType" v-bind="$props" />
            </template>
        </FeedbackCard>
        <div
            class="flex flex-col gap-2"
            :class="commentsAreReversed && 'flex-col-reverse'"
        >
            <CommentResponse
                v-if="getResponse"
                :content="getResponse"
                :creation-date="getResponseDate"
                :author-name="getResponseName"
                :must-display-whole-comment="getMustDisplayWholeComment"
                :locale
            />
            <CommentResponse
                v-if="getResponseSecondary"
                :content="getResponseSecondary"
                :creation-date="getResponseSecondaryDate"
                :author-name="getResponseSecondaryName"
                :must-display-whole-comment="getMustDisplayWholeComment"
                :locale
                :is-secondary-response="true"
            />
        </div>
    </div>
</template>
