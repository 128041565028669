<script>
import { DateTime } from "luxon";
import { BadgeType } from "../../../interfaces/UI";
import {
  ExpenseReportState
} from "../../../interfaces/expenseReport";
import ChargemapBadge from "../../common/global/ChargemapBadge.vue";
import IconCheckCircle from "../../icons/IconCheckCircle.vue";
import IconClock from "../../icons/IconClock.vue";
import IconPlay from "../../icons/IconPlay.vue";
import ChargemapB2CButton from "./ChargemapB2CButton.vue";
export default {
  name: "ExpenseReport",
  components: {
    ChargemapB2CButton,
    ChargemapBadge,
    IconClock,
    IconPlay,
    IconCheckCircle
  },
  props: {
    expenseReport: {
      type: Object,
      required: true
    }
  },
  emits: ["goToExpenseReport", "goToInvoice"],
  data() {
    return {
      BadgeType
    };
  },
  computed: {
    periodStart() {
      if (!this.expenseReport?.startDate) return "";
      return DateTime.fromISO(this.expenseReport.startDate, {
        zone: "UTC"
      }).toFormat("dd/LL/yyyy");
    },
    periodEnd() {
      if (!this.expenseReport?.endDate) return "";
      return DateTime.fromISO(this.expenseReport.endDate, {
        zone: "UTC"
      }).toFormat("dd/LL/yyyy");
    },
    hasInvoice() {
      return !!this.expenseReport.proofUrl;
    },
    statusType() {
      if (!this.expenseReport?.chargeRefundStatus) return void 0;
      switch (this.expenseReport.chargeRefundStatus) {
        case ExpenseReportState.BILLED:
          return BadgeType.NEUTRAL;
        case ExpenseReportState.REFUNDABLE:
          return BadgeType.WARNING;
        default:
          return BadgeType.SUCCESS;
      }
    },
    statusIcon() {
      if (!this.expenseReport?.chargeRefundStatus) return "";
      switch (this.expenseReport.chargeRefundStatus) {
        case ExpenseReportState.BILLED:
          return "IconClock";
        case ExpenseReportState.REFUNDABLE:
          return "IconPlay";
        default:
          return "IconCheckCircle";
      }
    },
    statusIconColor() {
      if (!this.expenseReport?.chargeRefundStatus) return "";
      switch (this.expenseReport.chargeRefundStatus) {
        case ExpenseReportState.BILLED:
          return "text-icon-secondary-neutral";
        case ExpenseReportState.REFUNDABLE:
          return "text-icon-secondary-warning";
        default:
          return "text-icon-secondary-positive";
      }
    },
    statusText() {
      if (!this.expenseReport?.chargeRefundStatus) return "";
      return this.$t(
        `expense_report.status.${this.expenseReport.chargeRefundStatus}`
      );
    }
  },
  methods: {
    goToExpenseReport() {
      this.$emit("goToExpenseReport", this.expenseReport);
    },
    goToInvoice() {
      this.$emit("goToInvoice", this.expenseReport);
    }
  }
};
</script>

<template>
    <div
        data-testid="expense-report"
        class="flex flex-col gap-4 border border-zinc-300 rounded-lg p-6 w-464"
    >
        <ChargemapBadge
            v-if="expenseReport.chargeRefundStatus"
            :type="statusType"
            class="max-w-fit"
            :is-b-2-b="false"
        >
            <div class="flex gap-1 justify-center items-center">
                <component
                    :is="statusIcon"
                    class="size-5"
                    :class="statusIconColor"
                />
                <span>{{ statusText }}</span>
            </div>
        </ChargemapBadge>

        <div class="text-zinc-800 text-fifteen-exploitation leading-4.5">
            {{ $t('expense_report.period_from') }} {{ periodStart }}
            {{ $t('expense_report.period_to') }}
            {{ periodEnd }}
        </div>

        <div class="flex gap-2 text-zinc-700">
            <ChargemapB2CButton
                type="secondary"
                size="large"
                custom="full-width"
                @click="goToExpenseReport"
            >
                {{ $t('expense_report.expense_report') }}
            </ChargemapB2CButton>
            <ChargemapB2CButton
                type="secondary"
                size="large"
                custom="full-width"
                :disabled="!hasInvoice"
                @click="goToInvoice"
            >
                {{ $t('expense_report.check_invoice') }}
            </ChargemapB2CButton>
        </div>
    </div>
</template>
