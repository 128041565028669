import { defineStore } from 'pinia'

import type { PoolPhoto, PoolDetail } from '#cmui/types/poolDetail'
import type { InversedMapBounds } from '#cmui/types/map'
import type { PoolStation } from '#cmui/types/station'
import useSessionStore from './session'

const useMapStore = defineStore('map', {
    state: () => ({
        loadingMap: false
    }),
    actions: {
        async fetchMapData(
            idPartner: string,
            idNetwork: string,
            bounds: InversedMapBounds
        ) {
            const { $throwError, $api } = useNuxtApp()

            if (
                !bounds?.NW?.lat ||
                !bounds?.NW?.lng ||
                !bounds?.SE?.lat ||
                !bounds?.SE?.lng
            ) {
                return {
                    items: []
                }
            }

            const payload = {
                viewportNW: `${bounds.NW.lat};${bounds.NW.lng}`,
                viewportSE: `${bounds.SE.lat};${bounds.SE.lng}`,
                community_networks_ids: [idNetwork]
            }

            this.loadingMap = true

            return $api
                .get(`/partners-api/partners/${idPartner}/zones/map`, {
                    params: payload
                })
                .then((response) => {
                    if (response?.status === 200) {
                        return {
                            items: response?.data?.response?.content?.items
                        }
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch map data')
                })
                .finally(() => {
                    this.loadingMap = false
                })
        },
        async fetchKeyData() {
            const { $throwError, $api } = useNuxtApp()

            const locale = useSessionStore().user?.longLocale || 'en-US'

            return $api
                .get(`/mappy-api/legend.json?locale=${locale}`)
                .then((response: any) => {
                    if (response.status === 200) {
                        return response.data
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch key data')
                })
                .finally(() => {
                    this.loadingMap = false
                })
        },
        async fetchPoolDetails(
            idPartner: string,
            poolSlug: string
        ): Promise<PoolDetail> {
            const { $throwError, $toCamel, $api } = useNuxtApp()

            return $api
                .get<PoolDetail>(
                    `/partners-api/partners/${idPartner}/zones/map/${poolSlug}`
                )
                .then((response: any) => {
                    if (response.status === 200) {
                        return $toCamel(response.data)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch pool details')
                })
                .finally(() => {
                    this.loadingMap = false
                })
        },
        async fetchPoolStations(idPartner: string, poolId: number) {
            const { $throwError, $toCamel, $api } = useNuxtApp()

            return $api
                .get<PoolStation[]>(
                    `/partners-api/partners/${idPartner}/zones/map/${poolId}/stations.json`
                )
                .then((response: any) => {
                    if (response.status === 200) {
                        return $toCamel(response.data.stations)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch pool stations')
                })
                .finally(() => {
                    this.loadingMap = false
                })
        },
        async fetchPoolPictures(idPartner: string, stationId: number) {
            const { $throwError, $toCamel, $api } = useNuxtApp()

            return $api
                .get<PoolPhoto>(
                    `/partners-api/partners/${idPartner}/zones/map/${stationId}/photos.json`
                )
                .then((response: any) => {
                    if (response.status === 200) {
                        return $toCamel(response.data.photos)
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch pool pictures')
                })
                .finally(() => {
                    this.loadingMap = false
                })
        },
        async fetchPoolMessage(
            idPartner: string,
            stationId: number,
            longLocale: string | undefined
        ) {
            const { $throwError, $toCamel, $api } = useNuxtApp()

            return $api
                .get<PoolPhoto>(
                    `/partners-api/partners/${idPartner}/zones/map/${stationId}/message`
                )
                .then((response: any) => {
                    if (response.status === 200) {
                        const locale = longLocale || 'en-US'
                        const results = $toCamel(response.data)

                        const res = results.filter(
                            (item: any) =>
                                (item.locale as string).replace('_', '-') ===
                                locale
                        )

                        return res.length > 0 ? res[0] : undefined
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch pool message')
                })
                .finally(() => {
                    this.loadingMap = false
                })
        }
    },
    persist: {
        storage: localStorage
    }
})

export default useMapStore
