import { defineStore } from 'pinia'
import type { FiltersStationList } from '~/interfaces/filters'
import type { RatingZone } from '~/interfaces/ratings'
import type { ListResponse } from '~/interfaces/DTO'
import filterQueryBuilder from './utils/filterQueryBuilder'
import usePeriodStore from './period'

const useRatingsStore = defineStore('ratings', {
    state: () => ({
        loadingRatings: false
    }),
    actions: {
        async fetchZonesRatingsByIds(
            zoneIds: number[],
            currentPeriod: boolean = false
        ) {
            const { getCurrentPeriod, getPreviousPeriod } = usePeriodStore()

            const period = currentPeriod ? getCurrentPeriod : getPreviousPeriod

            const payload = {
                ...period,
                zoneIds
            }

            return this.getZoneRatings(payload)
        },
        async fetchZonesRatings(filters: FiltersStationList) {
            const {
                keyword,
                orderColumn,
                orderDirection,
                pages,
                stationFilters,
                hideEmpty
            } = filters
            const offset = (pages.index - 1) * pages.perPage || (0 as number)
            const limit = pages.perPage || (10 as number)

            const { getCurrentPeriod } = usePeriodStore()

            const payload = {
                keyword: keyword || undefined,
                offset,
                limit,
                orderBy:
                    orderColumn && orderDirection
                        ? `${orderColumn},${orderDirection}`
                        : undefined,
                hideEmpty,
                ...getCurrentPeriod,
                ...filterQueryBuilder(stationFilters)
            }

            return this.getZoneRatings(payload)
        },
        async getZoneRatings(payload: any) {
            const route = useRoute()

            const { idPartner, idNetwork, networkType } = route.params

            if (!idPartner || !idNetwork || !networkType) {
                return Promise.reject(new Error('Missing parameters'))
            }

            const { $throwError } = useNuxtApp()

            const urlNetwork =
                networkType === 'community' ? 'community_networks' : 'networks'

            this.loadingRatings = true

            return $fetchApiRaw<ListResponse<RatingZone>>(
                `/partners-api/partners/${idPartner}/${urlNetwork}/${idNetwork}/ratings/by_zone`,
                {
                    params: payload
                }
            )
                .then((response) => {
                    const totalItems = parseInt(
                        response.headers.get('x-total-count') || '0',
                        10
                    )

                    return {
                        totalItems,
                        items: response._data?.items || []
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch engagements zones')

                    throw error
                })
                .finally(() => {
                    this.loadingRatings = false
                })
        }
    },
    persist: {
        storage: localStorage
    }
})

export default useRatingsStore
