import type { Feature } from './flags'
import type { User } from './user'

export enum NetworkPhotoType {
    AVATAR = 'avatar',
    ICON = 'icon'
}

export interface CommunityNetworkFeatures {
    communityNetworkId: number
    features: Feature[]
}

export interface NetworkPhotoAnswer {
    iconUrl?: string
    avatarUrl?: string
}

export interface Operator {
    id: number
    name: string
    code: string
}

export interface Network {
    id: number
    name: string
    zonesCount: number
    code: string
    rating: number
    selected?: boolean
}

export interface CommunityNetwork {
    id: number
    name: string
    zonesCount: number
    rightText?: string
    rating: number
    networks: Network[]
    avatarUrl?: string
    iconUrl?: string
    zonesGlobalDescription?: string
    lastUpdateUser?: User
    dateUpdated?: string
    features: Feature[]
}

export interface SelectedNetwork {
    communityNetworkId: undefined | number
    networkId: undefined | number
}

export enum NetworkType {
    COMMUNITY = 'community',
    INTEROP = 'network'
}

export enum LinkNetworkError {
    NO_NETWORK_TO_ATTACH = 'NO_NETWORK_TO_ATTACH',
    NETWORK_ALREADY_ATTACHED = 'NETWORK_ALREADY_ATTACHED'
}
