export enum Features {
    TIME_RANGE = 'TIME_RANGE',
    NETWORK_BRANDING = 'NETWORK_BRANDING',
    ZONE_BRANDING = 'ZONE_BRANDING',
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
    PROMOTION = 'PROMOTION'
}

export enum Packages {
    Export = 'Export',
    Branding = 'Branding',
    Promotion = 'Promotion'
}

export const FEATURES_BY_PACKAGE: Record<Packages, Features[]> = {
    [Packages.Export]: [Features.EXPORT, Features.TIME_RANGE],
    [Packages.Branding]: [
        Features.NETWORK_BRANDING,
        Features.ZONE_BRANDING,
        Features.IMPORT
    ],
    [Packages.Promotion]: [Features.PROMOTION]
}

export interface Feature {
    featureName: Features
    dateActivated: string
}

export enum FeatureTableColumns {
    EMPTY = 'EMPTY',
    NETWORK = 'NETWORK',
    NETWORK_TYPE = 'NETWORK_TYPE',
    DATE_ACTIVATED = 'DATE_ACTIVATED',
    ACTIVATED = 'ACTIVATED'
}
