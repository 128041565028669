import { PhotoCategory, PhotoSizes, type Photo } from '#cmui/types'
import { getPhotoUrlBySize } from '#cmui/utils'

// We pass the references from the station store so it doesn't loop when importing
export default (
    stationPhotos: Ref<Photo[]>,
    movePhotosFromCategory: (
        photos: Photo[],
        category: PhotoCategory
    ) => Promise<void>,
    addPhotosByCategory: (
        photos: string[],
        category: PhotoCategory
    ) => Promise<Photo[]>,
    deletePhotos: (photos: Photo[]) => Promise<void>
) => {
    // This array will contain the photos and their new category
    const photosToMove = ref<{ photo: Photo; category: PhotoCategory }[]>([])

    // This array will contain the photos that are not yet published with a temporary id
    const photosToAdd = ref<Photo[]>([])

    const photosToDelete = ref<Photo[]>([])

    const hasEditedPhotos = computed(() => {
        return (
            photosToMove.value.length > 0 ||
            photosToAdd.value.length > 0 ||
            photosToDelete.value.length > 0
        )
    })

    const { $throwError } = useNuxtApp()

    // We create a computed value that will contain all the added photos with all the sizes (this is to avoid recomputing it multiple times and storing all sizes in cache)
    const photosToAddAllSizes = computed(() => {
        return photosToAdd.value.map((photo) => {
            return {
                id: photo.id,
                category: photo.category,
                files: Object.values(PhotoSizes).map((size) => {
                    return {
                        size,
                        url: getPhotoUrlBySize(photo, PhotoSizes.ORIGINAL) || ''
                    }
                })
            }
        })
    })

    const cachedAllPhotos = computed(() => {
        // We first filter out the photos that are in the delete list
        const resultPhotos: Array<Photo> = stationPhotos.value.filter(
            (stationPhoto) => {
                return !photosToDelete.value.find(
                    (photo) => photo.id === stationPhoto.id
                )
            }
        )

        // We then add the photos that are in the add list

        resultPhotos.push(...photosToAddAllSizes.value)

        // We then update the category of the photos that are in the move list
        photosToMove.value.forEach(({ photo, category }) => {
            const movedPhoto = resultPhotos.find(
                (value) => value.id === photo.id
            )

            if (movedPhoto) {
                movedPhoto.category = category
            }
        })

        return resultPhotos
    })

    const cachedPhotos = computed<Array<Photo>>(() => {
        // We remove the cover photo from the list
        return cachedAllPhotos.value.filter(
            (photo) => photo.category !== PhotoCategory.COVER
        )
    })

    const coverPhoto = computed(() =>
        cachedAllPhotos.value.find(
            (photo) => photo.category === PhotoCategory.COVER
        )
    )

    const cacheMovePhotos = (photos: Photo[], category: PhotoCategory) => {
        photos.forEach((photoValue) => {
            // If the photo id is a string, it is a temporary id before publication and we update the category in the add list
            if (typeof photoValue.id === 'string') {
                const photo = photosToAdd.value.find(
                    (photo) => photo.id === photoValue.id
                )

                if (!photo) {
                    const errorMessage = `cannot move temporary photo ${photoValue.id} that is not in the add list`

                    $throwError(new Error(errorMessage), errorMessage)
                    // We don't refresh the page because it resolves itself
                } else {
                    photo.category = category
                }
            } else {
                const photoInStationPhotos = stationPhotos.value.find(
                    (photo) => photo.id === photoValue.id
                )

                // Cache miss: we exit early
                if (!photoInStationPhotos) {
                    const errorMessage = `cannot move photo ${photoValue.id} that is not in the station photos`

                    $throwError(new Error(errorMessage), errorMessage)

                    // We refresh the page because the full state is inconsistent
                    reloadNuxtApp()
                }

                const photoToMove = photosToMove.value.find(
                    (item) => item.photo.id === photoValue.id
                )

                // If the photo was already in the move list, we update it's category
                if (photoToMove) {
                    photoToMove.category = category
                } else {
                    photosToMove.value.push({
                        photo: photoValue,
                        category
                    })
                }
            }
        })
    }

    const cacheDeletePhotos = (photos: Photo[]) => {
        photos.forEach((photoValue) => {
            // If it's a temporary id, we attempt to remove it from the add list
            if (typeof photoValue.id === 'string') {
                const photoInAddedPhotos = photosToAdd.value.find(
                    (photo) => photo.id === photoValue.id
                )

                if (!photoInAddedPhotos) {
                    const errorMessage = `cannot delete temporary photo ${photoValue.id} that is not in the add list`

                    $throwError(new Error(errorMessage), errorMessage)
                    // We don't refresh the page because it resolves itself
                } else {
                    // If the photo is in the add list, we remove it
                    photosToAdd.value = photosToAdd.value.filter(
                        (photo) => photo.id !== photoInAddedPhotos.id
                    )
                }
            } else {
                const photoInStationPhotos = stationPhotos.value.find(
                    (photo) => photo.id === photoValue.id
                )

                if (!photoInStationPhotos) {
                    const errorMessage = `cannot delete photo ${photoValue.id} that is not in the station photos`

                    $throwError(new Error(errorMessage), errorMessage)

                    // We refresh the page because the full state is inconsistent
                    return reloadNuxtApp()
                } else {
                    // We remove the photo from the move list if it was in it
                    const movementIndex = photosToMove.value.findIndex(
                        (item) => item.photo.id === photoValue.id
                    )

                    if (movementIndex !== -1) {
                        photosToMove.value.splice(movementIndex, 1)
                    }

                    photosToDelete.value.push(photoValue)
                }
            }
        })
    }

    // We keep track of the temporary index for the photos that are not yet published
    const currentTemporaryIndex = ref(0)

    const getIndexString = (id: number) => {
        return `toAdd-${id}`
    }

    const cacheAddCoverPhoto = (photo: string) => {
        const existingCover = stationPhotos.value.find(
            (photo) => photo.category === PhotoCategory.COVER
        )

        const deletedCover = photosToDelete.value.find(
            (photo) => photo.category === PhotoCategory.COVER
        )

        const coverInToAddList = photosToAdd.value.find(
            (photo) => photo.category === PhotoCategory.COVER
        )

        if (existingCover && !deletedCover) {
            photosToDelete.value.push(existingCover)
        }

        if (coverInToAddList) {
            photosToAdd.value = photosToAdd.value.filter(
                (photo) => photo.category !== PhotoCategory.COVER
            )
        }

        photosToAdd.value.push({
            id: getIndexString(currentTemporaryIndex.value),
            files: [
                {
                    url: photo,
                    size: PhotoSizes.ORIGINAL
                }
            ],
            category: PhotoCategory.COVER
        })
    }

    const cacheAddPhotos = (photos: string[], category: PhotoCategory) => {
        // We add the photos to the add list with a temporary id
        photos.forEach((photo) => {
            photosToAdd.value.push({
                id: getIndexString(currentTemporaryIndex.value),
                files: [
                    {
                        url: photo,
                        size: PhotoSizes.ORIGINAL
                    }
                ],
                category
            })

            // increment the temporary index
            currentTemporaryIndex.value++
        })
    }

    const resetCache = () => {
        photosToMove.value = []
        photosToAdd.value = []
        photosToDelete.value = []
    }

    const handleMovePhotos = () => {
        if (photosToMove.value.length === 0) {
            return Promise.resolve()
        }

        const photosByCategory: {
            category: PhotoCategory
            photos: Photo[]
        }[] = []

        // We build an array of photos by category
        photosToMove.value.forEach(({ photo, category }) => {
            const photoToMove = stationPhotos.value.find(
                (value) => value.id === photo.id
            )

            if (photoToMove) {
                let categoryEntry = photosByCategory.find(
                    (entry) => entry.category === category
                )

                if (!categoryEntry) {
                    categoryEntry = { category, photos: [] }
                    photosByCategory.push(categoryEntry)
                }

                categoryEntry.photos.push(photoToMove)
            }
        })

        return Promise.all(
            photosByCategory.map((value) => {
                movePhotosFromCategory(value.photos, value.category)
            })
        )
    }

    const handleAddPhotos = async () => {
        if (photosToAdd.value.length === 0) {
            return Promise.resolve()
        }

        const photosByCategory: {
            category: PhotoCategory
            photos: string[]
        }[] = []

        // We build an array of photos by category
        photosToAdd.value.forEach((photo) => {
            const categoryEntry = photosByCategory.find(
                (entry) => entry.category === photo.category
            )

            const photoBlob = getPhotoUrlBySize(photo, PhotoSizes.ORIGINAL)

            if (!photoBlob) {
                return Promise.reject()
            }

            if (categoryEntry) {
                categoryEntry.photos.push(photoBlob)
            } else {
                photosByCategory.push({
                    category: photo.category,
                    photos: [photoBlob]
                })
            }
        })

        try {
            for (const value of photosByCategory) {
                await addPhotosByCategory(value.photos, value.category).catch(
                    (error) => {
                        $throwError(
                            error,

                            `cannot add photos ${JSON.stringify(value.photos)}`
                        )

                        return Promise.reject(error)
                    }
                )
            }
        } catch (error) {
            return Promise.reject(error)
        }

        return Promise.resolve()
    }

    const handleDeletePhotos = () => {
        if (photosToDelete.value.length === 0) {
            return Promise.resolve()
        }

        return deletePhotos(photosToDelete.value).then(() => {
            photosToDelete.value = []
        })
    }

    const handlePublishPhotos = async () => {
        try {
            await handleDeletePhotos()
            await handleMovePhotos()
            await handleAddPhotos()
            resetCache()

            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        // Computed
        hasEditedPhotos,
        cachedPhotos,
        coverPhoto,
        cachedAllPhotos,

        // Actions
        cacheMovePhotos,
        cacheDeletePhotos,
        cacheAddPhotos,
        cacheAddCoverPhoto,
        resetCache,

        // Publish
        handlePublishPhotos,

        // Cached values
        photosToMove,
        photosToAdd,
        photosToDelete,

        // Expose for testing
        handleMovePhotos,
        handleAddPhotos,
        handleDeletePhotos
    }
}
