<script>
export default {
  name: "IconPolygone"
};
</script>

<template>
    <svg
        width="20"
        height="13"
        viewBox="0 0 20 13"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.83623 0.92896C9.23613 0.446462 9.97619 0.446462 10.3761 0.928959L18.8158 11.1119C19.356 11.7637 18.8925 12.75 18.0459 12.75H1.16646C0.31987 12.75 -0.143708 11.7637 0.396522 11.1119L8.83623 0.92896Z"
        />
    </svg>
</template>
