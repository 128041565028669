<script>
import { BadgeType } from "../../../interfaces/UI";
import { PoolRealtimeState } from "../../../interfaces/pool";
import ChargemapBadge from "../global/ChargemapBadge.vue";
import ChargemapLabel from "../global/ChargemapLabel.vue";
export default {
  name: "ConnectorLine",
  components: { ChargemapLabel, ChargemapBadge },
  props: {
    realtimeState: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      RealtimeState: PoolRealtimeState
    };
  },
  computed: {
    badgeType() {
      switch (this.realtimeState) {
        case PoolRealtimeState.AVAILABLE:
          return BadgeType.SUCCESS;
        case PoolRealtimeState.FAULTED:
        case PoolRealtimeState.OUT_OF_ORDER:
          return BadgeType.ERROR;
        case PoolRealtimeState.UNAVAILABLE:
          return BadgeType.NEUTRAL;
        case PoolRealtimeState.UNKNOWN:
        default:
          return BadgeType.WARNING;
      }
    }
  }
};
</script>

<template>
    <div
        class="py-4 px-8 flex justify-between flex-row items-center"
        data-testid="connector-line"
    >
        <ChargemapLabel border>{{ id }}</ChargemapLabel>
        <ChargemapBadge
            :type="badgeType"
            size="extra-large"
            custom-text-size="text-xs"
            class="font-normal"
        >
            {{ $t(`status.${realtimeState || RealtimeState.UNKNOWN}`) }}
        </ChargemapBadge>
    </div>
</template>
