export default function arraysEqual(
    a: unknown[] | undefined,
    b: unknown[] | undefined
): boolean {
    if (!a || !b) return false

    if (a === b) return true

    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; ++i) {
        if (!b.some((entry) => entry === a[i])) return false
    }

    return true
}
