export var PhotoCategory = /* @__PURE__ */ ((PhotoCategory2) => {
  PhotoCategory2["STATION"] = "STATION";
  PhotoCategory2["ENTRANCE"] = "ENTRANCE";
  PhotoCategory2["EVSE"] = "EVSE";
  PhotoCategory2["SURROUNDINGS"] = "SURROUNDINGS";
  PhotoCategory2["COMMUNITY"] = "COMMUNITY";
  PhotoCategory2["COVER"] = "COVER";
  return PhotoCategory2;
})(PhotoCategory || {});
export var PhotoSizes = /* @__PURE__ */ ((PhotoSizes2) => {
  PhotoSizes2["ORIGINAL"] = "1500x1500";
  PhotoSizes2["COVER"] = "1126x474";
  PhotoSizes2["STANDARD"] = "840x560";
  PhotoSizes2["SMALL"] = "320x320";
  return PhotoSizes2;
})(PhotoSizes || {});
