import useAuthStore from '~/store/auth'
import useSessionStore from '~/store/session'
import { DateTime } from 'luxon'
import useTokenStore from '~/store/token'

export default defineNuxtRouteMiddleware(async (to, from) => {
    // skip middleware if nuxtApp is not instanced
    const nuxtApp = useNuxtApp()

    if (!nuxtApp || !nuxtApp?.vueApp || !nuxtApp?.$pinia) {
        return
    }

    if (to.path === '/') {
        abortNavigation()

        return navigateTo('/networks')
    }

    const authStore = useAuthStore()

    const { token, refreshToken, tokenExpiration, refreshTokenExpiration } =
        useSessionStore()

    useTokenStore().token = token

    const sessionStore = useSessionStore()

    const dateExpired = new Date()
    dateExpired.setDate(dateExpired.getDate() + 1)

    const tokenIsValid =
        !!token &&
        tokenExpiration &&
        DateTime.now().toUnixInteger() <
            DateTime.fromISO(tokenExpiration).toUnixInteger()

    const refreshTokenIsValid =
        !!refreshToken &&
        refreshTokenExpiration &&
        DateTime.now().toUnixInteger() <
            DateTime.fromISO(refreshTokenExpiration).toUnixInteger()

    if (
        !tokenIsValid &&
        !refreshTokenIsValid &&
        to?.name !== 'login' &&
        to?.name !== 'password-reset' &&
        to?.name !== 'password-reset-update'
    ) {
        sessionStore.setLastRoute(from.fullPath)

        abortNavigation()

        return navigateTo('/login')
    }

    // Try to get new token with refresh token
    if (!tokenIsValid && refreshTokenIsValid) {
        try {
            await authStore.refreshUserToken()

            abortNavigation()

            return navigateTo(to)
        } catch {
            sessionStore.logout()
            sessionStore.setLastRoute(from.fullPath)

            abortNavigation()

            return navigateTo('/login')
        }
    }

    if (to.name === 'login' && tokenIsValid) {
        abortNavigation()

        if (from.name !== 'login' && from.name) {
            return navigateTo(from)
        }

        return navigateTo('/')
    }
})
