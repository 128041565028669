import useEngagementsStore from '~/store/engagements'
import useMapStore from '~/store/map'
import useMenuStore from '~/store/menu'
import useNetworksStore from '~/store/networks'
import usePartnersStore from '~/store/partners'
import usePhotoStore from '~/store/photos'
import usePoolsStore from '~/store/pools'
import useRatingsStore from '~/store/ratings'
import useSessionStore from '~/store/session'
import useStationStore from '~/store/station'

export default () => {
    const reset = () => {
        // Old stores
        useMapStore().$reset()
        useMenuStore().$reset()
        usePartnersStore().$reset()
        usePoolsStore().$reset()
        useRatingsStore().$reset()
        useSessionStore().$reset()

        // New stores
        useEngagementsStore().reset()
        useStationStore().reset()
        useNetworksStore().reset()
        usePhotoStore().reset()
    }

    return {
        reset
    }
}
