import useEngagementsStore from '~/store/engagements'

export default defineNuxtRouteMiddleware((to, from) => {
    const { idPartner, networkType, idNetwork } = to.params

    if (
        idPartner &&
        idNetwork &&
        networkType &&
        (JSON.stringify(to.path) !== JSON.stringify(from.path) ||
            useEngagementsStore().unreadFeedbackCount === 0)
    ) {
        useEngagementsStore().fetchUnreadFeedbackCount(
            networkType as string,
            idPartner as string,
            idNetwork as string
        )
    }
})
