<script>
import IconChargingBadge from "../../icons/IconChargingBadge.vue";
import IconEuro from "../../icons/IconEuro.vue";
import IconDeviceMobile from "../../icons/IconDeviceMobile.vue";
export default {
  name: "PoolChargemapCompatibility",
  components: { IconDeviceMobile, IconChargingBadge, IconEuro },
  props: {
    isCompatible: {
      type: Boolean,
      required: true
    },
    isRemoteCompatible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["showPrices"]
};
</script>

<template>
    <div data-testid="pool-chargemap-compatibility">
        <div v-if="!isCompatible">
            <div
                class="w-full bg-al-500 h-12 rounded-lg p-2 flex items-center gap-4"
            >
                <IconChargingBadge class="size-6 text-white" is-solid />
                <p class="text-fifteen-exploitation text-white">
                    {{ $t('pass.incompatible') }}
                </p>
            </div>
        </div>
        <div v-else>
            <div
                class="w-full bg-branding-500 min-h-12 rounded-lg p-2 text-white leading-4.5 flex flex-wrap gap-1 items-center justify-center sm:justify-between"
            >
                <div
                    class="text-fifteen-exploitation mr-auto"
                    :class="{
                        'grid grid-rows-2 gap-1': isRemoteCompatible
                    }"
                >
                    <div class="flex items-center gap-2">
                        <IconChargingBadge class="size-6" is-solid />
                        <p>
                            {{ $t('pass.compatible') }}
                        </p>
                    </div>
                    <div
                        v-if="isRemoteCompatible"
                        class="flex items-center gap-2"
                    >
                        <IconDeviceMobile class="size-6" is-solid />
                        <p>
                            {{ $t('remote_charge.compatible') }}
                        </p>
                    </div>
                </div>
                <div
                    class="border border-white px-2 py-1.5 rounded-md text-thirteen-exploitation cursor-pointer"
                    @click="$emit('showPrices')"
                >
                    <IconEuro class="size-4 mr-1.5 float-left" />
                    <p
                        class="whitespace-nowrap overflow-hidden tracking-light leading-4 font-semibold"
                    >
                        {{ $t('chargemap_prices') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
