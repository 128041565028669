<script>
export default {
  name: "IconNotificationInfoCircle",
  props: {
    type: {
      type: String,
      default: "info"
    }
  }
};
</script>

<template>
    <svg
        v-if="type === 'info'"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 6.33332C9.31812 6.33332 6.33335 9.31809 6.33335 13C6.33335 16.6819 9.31812 19.6667 13 19.6667C16.6819 19.6667 19.6667 16.6819 19.6667 13C19.6667 9.31809 16.6819 6.33332 13 6.33332ZM4.66669 13C4.66669 8.39762 8.39765 4.66666 13 4.66666C17.6024 4.66666 21.3334 8.39762 21.3334 13C21.3334 17.6024 17.6024 21.3333 13 21.3333C8.39765 21.3333 4.66669 17.6024 4.66669 13ZM12.1667 9.87499C12.1667 9.41475 12.5398 9.04166 13 9.04166H13.0063C13.4665 9.04166 13.8396 9.41475 13.8396 9.87499V9.88124C13.8396 10.3415 13.4665 10.7146 13.0063 10.7146H13C12.5398 10.7146 12.1667 10.3415 12.1667 9.88124V9.87499ZM12.4015 13.2081C12.0867 13.2179 11.7798 13.0479 11.6297 12.7477C11.4238 12.336 11.5907 11.8355 12.0023 11.6296L12.0369 11.6123C13.1513 11.0551 14.4061 12.0617 14.1039 13.2704L13.5985 15.2919C13.9134 15.2821 14.2203 15.4521 14.3704 15.7523C14.5762 16.1639 14.4094 16.6645 13.9977 16.8703L13.9631 16.8876L13.9631 16.8876C12.8487 17.4448 11.594 16.4383 11.8961 15.2296L12.4015 13.2081Z"
            fill="#2463EB"
        />
        <circle cx="13" cy="13" r="12" stroke="#BEDBFE" stroke-width="2" />
    </svg>

    <svg
        v-else
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 6.33335C9.31812 6.33335 6.33335 9.31812 6.33335 13C6.33335 16.6819 9.31812 19.6667 13 19.6667C16.6819 19.6667 19.6667 16.6819 19.6667 13C19.6667 9.31812 16.6819 6.33335 13 6.33335ZM4.66669 13C4.66669 8.39765 8.39765 4.66669 13 4.66669C17.6024 4.66669 21.3334 8.39765 21.3334 13C21.3334 17.6024 17.6024 21.3334 13 21.3334C8.39765 21.3334 4.66669 17.6024 4.66669 13ZM12.1667 9.87502C12.1667 9.41478 12.5398 9.04169 13 9.04169H13.0063C13.4665 9.04169 13.8396 9.41478 13.8396 9.87502V9.88127C13.8396 10.3415 13.4665 10.7146 13.0063 10.7146H13C12.5398 10.7146 12.1667 10.3415 12.1667 9.88127V9.87502ZM12.4015 13.2081C12.0867 13.2179 11.7798 13.0479 11.6297 12.7477C11.4238 12.336 11.5907 11.8355 12.0023 11.6297L12.0369 11.6124C13.1513 11.0552 14.4061 12.0617 14.1039 13.2704L13.5985 15.2919C13.9134 15.2821 14.2203 15.4521 14.3704 15.7523C14.5762 16.164 14.4094 16.6645 13.9977 16.8704L13.9631 16.8877L13.9631 16.8877C12.8487 17.4449 11.594 16.4383 11.8961 15.2296L12.4015 13.2081Z"
            fill="#65758B"
        />
        <circle cx="13" cy="13" r="12" stroke="#E1E7EF" stroke-width="2" />
    </svg>
</template>
