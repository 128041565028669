<script>
import isString from "../../../utils/shared/isString";
import IconEye from "../../icons/IconEye.vue";
import IconEyeClosed from "../../icons/IconEyeClosed.vue";
import IconQuestionMarkCircleB2B from "../../icons/IconQuestionMarkCircleB2B.vue";
import IconSearch from "../../icons/IconSearch.vue";
import IconXCircle from "../../icons/IconXCircle.vue";
import ChargemapTooltip from "./ChargemapTooltip.vue";
export default {
  name: "ChargemapInput",
  // ChargemapInput because Input is reserved
  components: {
    IconXCircle,
    IconEye,
    IconEyeClosed,
    IconSearch,
    IconQuestionMarkCircleB2B,
    ChargemapTooltip
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: null,
      default: null
    },
    isValid: {
      type: Boolean,
      default: true
    },
    unit: {
      type: String,
      default: ""
    },
    errorMessage: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    typeInput: {
      type: String,
      default: "text"
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    isPassword: {
      type: Boolean,
      default: false
    },
    isFocus: {
      type: Boolean,
      default: false
    },
    noX: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: void 0
    },
    min: {
      type: Number,
      default: void 0
    },
    max: {
      type: Number,
      default: void 0
    },
    label: {
      type: String,
      default: void 0
    },
    tooltipText: {
      type: String,
      default: ""
    }
  },
  emits: ["input", "update:modelValue"],
  data: () => ({
    canvas: void 0,
    isRevealed: false
  }),
  computed: {
    hasValue() {
      return !!this.modelValue;
    },
    canShowEyeIcon() {
      return !!(this.isPassword && this.isValid && this.modelValue);
    },
    canShowXIcon() {
      return (!this.isPassword || !this.isValid) && !this.noX;
    },
    canShowTooltip() {
      return !!this.tooltipText && !this.hasValue;
    },
    getTypeInput() {
      return this.isPassword && !this.isRevealed ? "password" : this.typeInput;
    },
    getSizeInputText() {
      let size = 1;
      if (this.canvas) {
        const ctx = this.canvas.getContext("2d");
        if (ctx) {
          ctx.font = "inherit";
          const text = ctx.measureText(this.modelValue);
          size = text.width;
        }
      }
      return size * 0.1;
    },
    calculatePositionLeft() {
      let totalLeft = 0.75;
      const spaceBetweenTextAndSuffix = 0.25;
      totalLeft += this.getSizeInputText + spaceBetweenTextAndSuffix;
      return totalLeft;
    }
  },
  watch: {
    isFocus(val) {
      if (val) {
        this.focusInput();
      }
    }
  },
  mounted() {
    if (typeof document !== "undefined") {
      this.canvas = this.$refs.canvasTextInput;
    }
    if (this.isFocus) {
      this.focusInput();
    }
  },
  methods: {
    handleReveal() {
      this.isRevealed = !this.isRevealed;
    },
    updateValue(value) {
      let val = value;
      if (!isString(value)) {
        val = value.value;
      }
      this.$emit("update:modelValue", val);
    },
    focusInput() {
      if (this.$refs?.ChargemapInput) {
        this.$refs.ChargemapInput.focus();
      }
    }
  }
};
</script>

<template>
    <div
        class="flex flex-col w-full h-full gap-1.5"
        data-testid="chargemap-input"
    >
        <div v-if="label" class="text-sm font-medium">{{ label }}</div>
        <div class="relative flex items-center w-full h-full">
            <div
                v-if="isSearch"
                class="absolute left-2 flex flex-inline items-center"
            >
                <IconSearch class="text-slate-500 h-5 w-5" />
            </div>
            <input
                ref="ChargemapInput"
                class="w-full h-[46px] py-2.5 px-3 border rounded-lg shadow-sm placeholder:text-slate-500"
                :class="[
                    !isValid ? 'border-red-300' : 'border-slate-300',
                    isSearch && 'pl-8',
                    (canShowEyeIcon || canShowXIcon || canShowTooltip) &&
                        '!pr-8',
                    disabled && 'bg-slate-50 !placeholder:text-slate-400'
                ]"
                v-bind="$attrs"
                :value="modelValue"
                :placeholder="placeholder"
                :type="getTypeInput"
                :disabled="disabled"
                :step="step"
                :min="min"
                :max="max"
                @input="updateValue($event.target)"
            />
            <div
                class="absolute flex flex-inline items-center"
                :style="`left:${calculatePositionLeft}rem`"
            >
                <span
                    v-if="unit && hasValue"
                    class="mr-2"
                    :class="isValid ? 'text-slate-400' : 'text-red-900'"
                >
                    {{ unit }}
                </span>
            </div>
            <div
                v-if="canShowEyeIcon"
                class="absolute right-2 flex flex-inline items-center"
                @click.stop="handleReveal"
            >
                <IconEyeClosed
                    v-if="isRevealed"
                    class="text-slate-500 h-5 w-5"
                />
                <IconEye v-else class="text-slate-500 h-5 w-5" />
            </div>

            <div
                v-if="canShowXIcon"
                class="absolute right-2 flex flex-inline items-center"
            >
                <IconXCircle
                    v-if="hasValue"
                    class="h-5 w-5 cursor-pointer"
                    :class="isValid ? 'text-slate-500' : 'text-red-500'"
                    @click.prevent="updateValue('')"
                />
            </div>

            <div
                v-if="canShowTooltip"
                class="absolute right-2 flex flex-inline items-center"
                @click.stop="handleReveal"
            >
                <ChargemapTooltip :text="tooltipText">
                    <IconQuestionMarkCircleB2B class="h-4 w-4 text-slate-500" />
                </ChargemapTooltip>
            </div>
        </div>
        <p v-show="!isValid && !!errorMessage" class="text-sm text-red-600">
            {{ errorMessage }}
        </p>
        <canvas ref="canvasTextInput" class="hidden" />
    </div>
</template>
