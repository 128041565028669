<script>
import IconCheckCircle from "../../icons/IconCheckCircle.vue";
import CommunityBlue from "../../images/CommunityBlue.vue";
import CommunityWhite from "../../images/CommunityWhite.vue";
export default {
  name: "JoinEvDrivers",
  components: { IconCheckCircle, CommunityBlue, CommunityWhite },
  props: {
    isBlue: {
      type: Boolean,
      default: false
    },
    driverNumber: {
      type: Number,
      required: true
    }
  },
  emits: ["redirectTo"],
  computed: {
    formatDriverNumber() {
      return this.driverNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
};
</script>

<template>
    <div
        data-testid="join-ev-drivers"
        :class="{ 'bg-light-blue-600': isBlue }"
        class="max-w-612 py-5 px-6 rounded-13 bg-white"
    >
        <div
            class="w-full h-auto mb-5 text-light-blue-primary-medium"
            @click="$emit('redirectTo', 'register')"
        >
            <span
                :class="{ 'text-white': isBlue }"
                class="text-twenty-eight mb-4 leading-120"
            >
                {{ $t('join_ev_drivers.title1') }}
                <span
                    :class="{ 'text-amber-l-500': isBlue }"
                    class="text-light-blue-primary font-bold"
                >
                    {{ formatDriverNumber }} {{ $t('join_ev_drivers.title2') }}
                </span>
                {{ $t('join_ev_drivers.title3') }}
            </span>
            <div class="flex flex-col 425:flex-row text-blue-gray-l-700">
                <div class="flex justify-center">
                    <CommunityBlue
                        v-if="isBlue"
                        class="mr-4 max-w-185 425:max-w-auto"
                        width="184"
                        height="138"
                        alt="community"
                    />
                    <CommunityWhite
                        v-else
                        class="mr-4 max-w-185 425:max-w-auto"
                        width="184"
                        height="138"
                        alt="community"
                    />
                </div>
                <div
                    class="flex flex-col text-fifteen-exploitation mt-3 425:mt-0"
                    :class="{ 'text-white': isBlue }"
                >
                    <div class="mb-1 grid grid-flow-col">
                        <IconCheckCircle
                            is-solid
                            :class="{ 'text-amber-l-500': isBlue }"
                            class="mr-2 mt-1 size-4 text-light-blue-primary-medium"
                        />
                        {{ $t('join_ev_drivers.desc.find') }}
                    </div>
                    <div class="mb-1 grid grid-flow-col">
                        <IconCheckCircle
                            is-solid
                            :class="{ 'text-amber-l-500': isBlue }"
                            class="mr-2 mt-1 size-4 text-light-blue-primary-medium"
                        />
                        {{ $t('join_ev_drivers.desc.join') }}
                    </div>
                    <div class="mb-1 grid grid-flow-col">
                        <IconCheckCircle
                            is-solid
                            :class="{ 'text-amber-l-500': isBlue }"
                            class="mr-2 mt-1 size-4 text-light-blue-primary-medium"
                        />
                        {{ $t('join_ev_drivers.desc.benefit') }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex flex-col justify-center gap-2 mx-4 text-center items-center"
        >
            <button
                class="w-max bg-lb-400 text-white px-3 py-2 rounded-lg font-medium text-seventeen-exploitation"
                @click="$emit('redirectTo', 'register')"
            >
                {{ $t('join_chargemap.signup') }}
            </button>
            <span
                :class="{ 'text-amber-l-500': isBlue }"
                class="py-3.5 text-gray-l-200 text-fifteen-exploitation"
            >
                {{ $t('join_chargemap.signin1') }}
                <span class="text-sky-600">
                    <a
                        :class="{ 'text-white': isBlue }"
                        class="cursor-pointer"
                        @click="$emit('redirectTo', 'signin')"
                    >
                        {{ $t('join_chargemap.signin2') }}
                    </a>
                </span>
            </span>
        </div>
    </div>
</template>
