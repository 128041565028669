<script>
import DrawerCloseButton from "./DrawerCloseButton.vue";
export default {
  name: "DrawerTitleBar",
  components: { DrawerCloseButton },
  props: {
    title: {
      type: String,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      required: true
    }
  },
  emits: ["closeDrawer"],
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer");
    }
  }
};
</script>

<template>
    <div
        class="sticky top-0 flex flex-row justify-between px-6 py-4 z-20 bg-white"
        data-testid="drawer-title-bar"
    >
        <span class="text-slate-800 text-2xl font-semibold">{{ title }}</span>
        <DrawerCloseButton
            :show-close-button="showCloseButton"
            @close-drawer="closeDrawer"
        />
    </div>
</template>
