<script>
export default {
  name: "IconInfoCircle",
  props: {
    type: {
      type: String,
      default: "error"
    }
  }
};
</script>

<template>
    <svg
        v-if="type === 'error'"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 6.33335C9.31812 6.33335 6.33335 9.31812 6.33335 13C6.33335 16.6819 9.31812 19.6667 13 19.6667C16.6819 19.6667 19.6667 16.6819 19.6667 13C19.6667 9.31812 16.6819 6.33335 13 6.33335ZM4.66669 13C4.66669 8.39765 8.39765 4.66669 13 4.66669C17.6024 4.66669 21.3334 8.39765 21.3334 13C21.3334 17.6024 17.6024 21.3334 13 21.3334C8.39765 21.3334 4.66669 17.6024 4.66669 13ZM13 9.66669C13.4603 9.66669 13.8334 10.0398 13.8334 10.5V13.625C13.8334 14.0853 13.4603 14.4584 13 14.4584C12.5398 14.4584 12.1667 14.0853 12.1667 13.625V10.5C12.1667 10.0398 12.5398 9.66669 13 9.66669ZM12.1667 16.125C12.1667 15.6648 12.5398 15.2917 13 15.2917H13.0063C13.4665 15.2917 13.8396 15.6648 13.8396 16.125V16.1313C13.8396 16.5915 13.4665 16.9646 13.0063 16.9646H13C12.5398 16.9646 12.1667 16.5915 12.1667 16.1313V16.125Z"
            fill="#E21D48"
        />
        <circle cx="13" cy="13" r="12" stroke="#FECDD3" stroke-width="2" />
    </svg>

    <svg
        v-else
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 6.33329C9.31812 6.33329 6.33335 9.31806 6.33335 13C6.33335 16.6819 9.31812 19.6666 13 19.6666C16.6819 19.6666 19.6667 16.6819 19.6667 13C19.6667 9.31806 16.6819 6.33329 13 6.33329ZM4.66669 13C4.66669 8.39759 8.39765 4.66663 13 4.66663C17.6024 4.66663 21.3334 8.39759 21.3334 13C21.3334 17.6023 17.6024 21.3333 13 21.3333C8.39765 21.3333 4.66669 17.6023 4.66669 13ZM13 9.66663C13.4603 9.66663 13.8334 10.0397 13.8334 10.5V13.625C13.8334 14.0852 13.4603 14.4583 13 14.4583C12.5398 14.4583 12.1667 14.0852 12.1667 13.625V10.5C12.1667 10.0397 12.5398 9.66663 13 9.66663ZM12.1667 16.125C12.1667 15.6647 12.5398 15.2916 13 15.2916H13.0063C13.4665 15.2916 13.8396 15.6647 13.8396 16.125V16.1312C13.8396 16.5914 13.4665 16.9645 13.0063 16.9645H13C12.5398 16.9645 12.1667 16.5914 12.1667 16.1312V16.125Z"
            fill="#E9590C"
        />
        <circle cx="13" cy="13" r="12" stroke="#FED6A9" stroke-width="2" />
    </svg>
</template>
