<script>
import CommentContent from "./CommentContent.vue";
import CommentHeader from "./CommentHeader.vue";
export default {
  name: "Comment",
  components: { CommentHeader, CommentContent },
  props: {
    commonProps: {
      type: Object,
      required: true
    },
    commentProps: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  }
};
</script>

<template>
    <div class="flex flex-col gap-4 w-full" data-testid="comment">
        <CommentHeader
            :user="commonProps.user"
            :is-anonymous="commonProps.isAnonymous"
            :creation-date="commonProps.creationDate"
            :locale
        />
        <CommentContent
            :content="commentProps.content"
            :must-display-whole-comment="commonProps.mustDisplayWholeComment"
        />
    </div>
</template>
