<script>
import IconBadgeCheck from "../../../icons/IconBadgeCheck.vue";
export default {
  name: "ReportContent",
  components: {
    IconBadgeCheck
  },
  props: {
    reasonType: {
      type: String,
      required: true
    },
    isOperatorWarned: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    displayReasonType() {
      return this.$t(this.reasonType);
    }
  }
};
</script>

<template>
    <div
        class="flex flex-col ml-8.5 gap-1 font-normal leading-4 text-fifteen-exploitation"
    >
        <div class="text-red-700">{{ displayReasonType }}</div>
        <div v-if="isOperatorWarned" class="flex gap-1 items-end">
            {{ $t('operator.is_warned') }}
            <IconBadgeCheck class="text-gray-500 w-3.5 h-3.5" is-solid />
        </div>
        <div v-else>
            {{ $t('operator.is_not_warned') }}
        </div>
    </div>
</template>
