import { defineStore } from 'pinia'
import type {
    PartnerRights,
    TokenJWTPayload,
    User,
    UserJSON
} from '~/interfaces/user'
import * as Sentry from '@sentry/vue'
import useTokenStore from './token'
import type { Features } from '~/interfaces/flags'
import { keysToCamel } from '~/plugins/json/utils'

const dateExpired = new Date()
dateExpired.setDate(dateExpired.getDate() + 1)

const useSessionStore = defineStore('session', {
    state: () => ({
        user: null as User | null,
        superAdmin: false,
        partnersRights: [] as PartnerRights[],
        token: null as string | null,
        tokenExpiration: null as string | null,
        refreshToken: null as string | null,
        refreshTokenExpiration: null as string | null,
        lastRoute: null as string | null
    }),
    getters: {
        getUser(state) {
            return state.user
        },
        isSuperAdmin(state) {
            return state.superAdmin || false
        },
        getToken(state) {
            return state.token
        },
        getTokenExpiration(state) {
            return state.tokenExpiration
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        getRefreshTokenExpiration(state) {
            return state.refreshTokenExpiration
        },
        getLastRoute(state) {
            return state.lastRoute
        },
        getNumberSeparator(state) {
            const locale = state.user?.locale || 'en'

            const numberToSlice = 1.1

            return numberToSlice.toLocaleString(locale).slice(1, 2)
        }
    },
    actions: {
        getFeatures(partnerId: number, networkId: number): Features[] {
            const partnerRights = this.partnersRights.find(
                (item: PartnerRights) => {
                    return item.id === partnerId
                }
            )

            if (!partnerRights) {
                return []
            }

            const networkRights = partnerRights.communityNetworkFeatures?.find(
                (item) => {
                    return item.communityNetworkId === networkId
                }
            )

            if (!networkRights) {
                return []
            }

            return getFeaturesArray(networkRights.features)
        },
        async setLastRoute(route: string | null) {
            this.lastRoute = route
        },
        async setUpUser(params: TokenJWTPayload) {
            const { token, refreshToken, user, rights } = keysToCamel(params)
            const tokenStore = useTokenStore()

            tokenStore.token = token.value
            this.token = token.value
            this.tokenExpiration = token.expiration
            this.refreshToken = refreshToken.value
            this.refreshTokenExpiration = refreshToken.expiration
            this.superAdmin = rights?.superAdmin || false
            this.partnersRights = rights?.partnersRights || []

            await this.updateUser(user)
        },
        async logout() {
            const tokenStore = useTokenStore()

            tokenStore.token = null

            this.user = null
            this.token = null
            this.tokenExpiration = null
            this.refreshToken = null
            this.refreshTokenExpiration = null
        },
        async updateUser(user: UserJSON) {
            const { $toCamel } = useNuxtApp()
            this.user = $toCamel(user)
            Sentry.setUser({ id: user.id, email: user.email })

            if (this.user) {
                this.user.fullName = `${this.user.firstName} ${this.user.lastName}`
                switch (this.user.locale?.toLowerCase()) {
                    case 'fr':
                        this.user.longLocale = 'fr-FR'
                        break
                    case 'en':
                        this.user.longLocale = 'en-US'
                        break
                    case 'de':
                        this.user.longLocale = 'de-DE'
                        break
                    default:
                        this.user.longLocale = 'en-US'
                }
            }
        }
    },
    persist: {
        storage: localStorage
    }
})

type SessionStore = Omit<
    ReturnType<typeof useSessionStore>,
    keyof ReturnType<typeof defineStore>
>

export default useSessionStore

export type { SessionStore }
