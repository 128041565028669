<script>
import {
  MoodColorsTailwind,
  MoodColorsTextTailwind,
  Moods,
  MoodTypeBadge
} from "../../../interfaces/mood";
import { getFeedbackProps } from "../../../utils/shared/feedbackFormat";
import ChargemapButton from "../../b2b/global/ChargemapButton.vue";
import ChargemapCard from "../global/ChargemapCard.vue";
import IconMap from "../../icons/IconMap.vue";
import ChargemapGeneralMood from "../feedback/ChargemapGeneralMood.vue";
import Feedback from "../feedback/Feedback.vue";
import ChargemapBadge from "./ChargemapBadge.vue";
export default {
  name: "ChargemapFeedbackContainer",
  components: {
    ChargemapCard,
    Feedback,
    ChargemapButton,
    IconMap,
    ChargemapBadge,
    ChargemapGeneralMood
  },
  props: {
    feedback: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    isPartner: {
      type: Boolean,
      default: true
    },
    isDrawer: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: false
    },
    showMapButton: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    unarchiveButtonText: {
      type: String,
      default: ""
    },
    archiveButtonText: {
      type: String,
      default: ""
    },
    answerButtonText: {
      type: String,
      default: ""
    },
    sentimentNameKeyStart: {
      type: String,
      default: ""
    }
  },
  emits: ["updateFeedback", "answerButtonClick", "mapButtonClick"],
  data() {
    return {
      showCommentModal: false,
      showStationModal: false,
      feedbackProps: getFeedbackProps(this.feedback)
    };
  },
  computed: {
    feedbackStation() {
      return this.feedback?.zone || null;
    },
    stationSlug() {
      return this.feedbackStation?.slug || null;
    },
    stationName() {
      return this.feedback?.zone?.name || "";
    },
    stationAddress() {
      const locationInfo = this.feedback?.zone?.locationInfo;
      if (locationInfo) {
        const streetNumber = locationInfo.streetNumber || "";
        const streetName = locationInfo.streetName || "";
        const postalCode = locationInfo.postalCode || "";
        const city = locationInfo.city || "";
        return streetNumber || streetName ? `${streetNumber} ${streetName} - ${postalCode} ${city}`.trim() : `${postalCode} ${city}`.trim();
      }
      return "";
    },
    generalMood() {
      if (!this.feedback?.commentSentiment) return {};
      return {
        name: this.$t(
          `${this.sentimentNameKeyStart}${this.feedback.commentSentiment}`
        ),
        color: MoodColorsTailwind[this.feedback.commentSentiment],
        dotColor: `${MoodColorsTailwind[this.feedback.commentSentiment]}-${this.feedback.commentSentiment === Moods.NEGATIVE ? "600" : "500"}`,
        textColor: MoodColorsTextTailwind[this.feedback.commentSentiment],
        badgeType: MoodTypeBadge[this.feedback.commentSentiment]
      };
    },
    partnerAlreadyAnswered() {
      return !!this.feedback.partnerResponse;
    },
    hasComment() {
      return !!this.feedback?.comment || false;
    }
  },
  watch: {
    feedback(val) {
      this.feedbackProps = getFeedbackProps(val);
    }
  },
  methods: {
    answerButtonClicked() {
      this.$emit("answerButtonClick", this.feedback.id);
    },
    mapButtonClicked() {
      this.$emit("mapButtonClick", this.feedback.id);
    },
    updateFeedback(isArchived) {
      this.$emit("updateFeedback", isArchived, this.feedback.id);
    }
  }
};
</script>

<template>
    <ChargemapCard
        data-testid="chargemap-feedback-container"
        :no-hover="true"
        :has-padding="!isDrawer"
        :has-border="false"
        :has-shadow="isPartner"
        class="!border-gray-300 border"
    >
        <div class="flex flex-col gap-4">
            <div
                v-if="isPartner || generalMood.name || showMapButton"
                class="flex flex-col md:flex-row justify-between"
            >
                <div class="flex flex-col">
                    <div class="flex gap-2 items-center">
                        <span v-if="isPartner" class="font-bold text-gray-900">
                            {{ stationName }}
                        </span>
                        <ChargemapBadge
                            v-if="generalMood.name"
                            class="w-max hidden md:block"
                            :color="generalMood.color"
                        >
                            <ChargemapGeneralMood
                                class="text-sm font-medium"
                                :general-mood="generalMood"
                            />
                        </ChargemapBadge>
                    </div>

                    <span v-if="isPartner" class="text-sm text-gray-550">
                        {{ stationAddress }}
                    </span>
                </div>

                <div class="flex justify-between items-center">
                    <ChargemapBadge
                        v-if="generalMood.name"
                        class="w-max block md:hidden"
                        :type="generalMood.badgeType"
                    >
                        <ChargemapGeneralMood
                            class="text-sm font-medium"
                            :general-mood="generalMood"
                        />
                    </ChargemapBadge>

                    <ChargemapButton
                        v-if="showMapButton"
                        class="h-max"
                        btn-alt="clear"
                        @click="mapButtonClicked"
                    >
                        <IconMap class="size-5" />
                    </ChargemapButton>
                </div>
            </div>

            <Feedback
                class="flex-1"
                v-bind="feedbackProps"
                :card-no-border="!isPartner"
                :card-no-padding="isDrawer"
                :display-older-comments-label="false"
                :locale
            />

            <div
                v-if="showActions && hasComment"
                class="flex flex-col justify-between flex-1"
                :class="isPartner && 'pt-4'"
            >
                <ChargemapButton
                    v-if="feedback.isArchived && !isDrawer"
                    class="w-full"
                    :is-loading="isLoading"
                    @click="updateFeedback(false)"
                >
                    {{ unarchiveButtonText }}
                </ChargemapButton>

                <div v-else class="flex gap-2" :class="isPartner && 'py-3.5'">
                    <ChargemapButton
                        class="w-full"
                        :disabled="partnerAlreadyAnswered"
                        @click="answerButtonClicked"
                    >
                        {{ answerButtonText }}
                    </ChargemapButton>

                    <ChargemapButton
                        v-if="!isDrawer"
                        class="w-full"
                        btn-alt="clear"
                        :is-loading="isLoading"
                        @click="updateFeedback(true)"
                    >
                        {{ archiveButtonText }}
                    </ChargemapButton>
                </div>
            </div>
        </div>
    </ChargemapCard>
</template>
