<script>
export default {
  name: "IconNoPass"
};
</script>

<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            fill="#000"
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16s7.163-16 16-16z"
        ></path>
        <path
            fill="#fff"
            d="M16 8c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8zM6.222 16c0-5.4 4.378-9.778 9.778-9.778s9.778 4.378 9.778 9.778c0 5.4-4.378 9.778-9.778 9.778s-9.778-4.378-9.778-9.778z"
        ></path>
        <path
            fill="#fff"
            d="M19.295 12.705c0.347 0.347 0.347 0.91 0 1.257l-5.333 5.333c-0.347 0.347-0.91 0.347-1.257 0s-0.347-0.91 0-1.257l5.333-5.333c0.347-0.347 0.91-0.347 1.257 0z"
        ></path>
        <path
            fill="#fff"
            d="M12.705 12.705c0.347-0.347 0.91-0.347 1.257 0l5.333 5.333c0.347 0.347 0.347 0.91 0 1.257s-0.91 0.347-1.257 0l-5.333-5.333c-0.347-0.347-0.347-0.91 0-1.257z"
        ></path>
    </svg>
</template>
