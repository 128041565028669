<script lang="ts">
import useAuthStore from '@/store/auth'
import { mapActions, mapState } from 'pinia'
import useSessionStore from '~/store/session'
import * as Sentry from '@sentry/browser'

export default {
    name: 'Login',
    setup() {
        definePageMeta({
            layout: 'login'
        })
    },
    data() {
        return {
            login: '',
            password: '',
            isLoading: false,
            error: '',
            success: '',
            rememberUser: true
        }
    },
    computed: {
        ...mapState(useSessionStore, ['user', 'lastRoute']),
        captchaEnabled(): string {
            return this.$config.public.CAPTCHA_ENABLED as string
        },
        siteKey(): string {
            return this.$config.public.PUBLIC_KEY_CAPTCHA as string
        },
        contactUsLink() {
            const lang = this.$i18n.locale

            if (lang === 'en')
                return 'https://www.chargemap-partners.com/en/contact-us/'

            if (lang === 'de')
                return 'https://www.chargemap-partners.com/de/kontakt/'

            return 'https://www.chargemap-partners.com/contactez-nous/'
        }
    },
    watch: {
        login(val) {
            if (!val) this.error = ''
        },
        password(val) {
            if (!val) this.error = ''
        }
    },
    mounted() {
        if (this.captchaEnabled) this.setUpCaptcha()

        this.success = (this.$route.query.success as string) || ''
    },
    methods: {
        ...mapActions(useAuthStore, ['authenticateUser']),
        ...mapActions(useSessionStore, ['setLastRoute']),
        setUpCaptcha(this: any) {
            const isBrowser = typeof window !== 'undefined'

            if (isBrowser) {
                try {
                    const windowCaptcha = window as any
                    const recaptchaScript = document.createElement('script')
                    recaptchaScript.type = 'text/javascript'
                    recaptchaScript.async = true
                    recaptchaScript.defer = true
                    recaptchaScript.setAttribute(
                        'src',
                        'https://www.google.com/recaptcha/api.js'
                    )
                    document.head.appendChild(recaptchaScript)
                    windowCaptcha.recaptchaCheck = this.recaptchaCheck
                } catch {
                    Sentry.captureMessage('Captcha error')
                }
            }
        },
        recaptchaCheck(this: any, token: string | undefined) {
            if (!token) {
                this.error = this.$t('login.error.captcha')

                return
            }
            this.onSubmit(token)
        },
        onSubmit(this: any, token: string | Event): Promise<void> {
            if (!this.captchaEnabled) {
                token = ''
            }
            this.isLoading = true
            this.error = ''

            return this.authenticateUser({
                tokenCaptcha: token,
                login: this.login,
                password: this.password
            })
                .then(() => {
                    if (this.lastRoute && this.lastRoute !== '/login') {
                        this.$router.push(this.lastRoute)
                        this.setLastRoute(null)
                    } else {
                        this.$router.push('/') // redirection handled by the middlewares
                    }
                })
                .catch((e: any) => {
                    if (e.response?.status === 500 || !e.response) {
                        this.error = 'error.500'
                    } else {
                        this.error = 'error.unknownAccount'
                    }
                    this.$throwError(e, 'cannot authenticate user')
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<template>
    <div
        class="flex flex-col items-center justify-center w-full max-w-md"
        data-testid="login-form"
    >
        <div class="text-2xl text-gray-800 font-semibold">
            <i18n-t keypath="login.welcomeBack" tag="div" for="login.partner">
                <template #partner>
                    <span class="text-primary-500">
                        {{ $t('login.partner') }}
                    </span>
                </template>
            </i18n-t>
        </div>

        <form
            id="login-form"
            class="flex flex-col items-center justify-center mt-10 lg:mt-10 w-full gap-6"
            @submit.prevent="onSubmit"
        >
            <ChargemapAlert
                v-if="!!error"
                id="login-error"
                class="w-full"
                identifier="login_error"
                data-testid="login-alert-error"
                type="error"
            >
                <template #title>{{ $t(`login.${error}`) }}</template>
            </ChargemapAlert>

            <ChargemapAlert
                v-if="!!success"
                id="login-success"
                class="w-full"
                identifier="login_success"
                data-testid="login-alert-success"
                type="validate"
            >
                <template #title>{{ $t(`login.success.${success}`) }}</template>
            </ChargemapAlert>

            <ChargemapInput
                id="login"
                v-model="login"
                :is-valid="!error"
                :disabled="isLoading"
                :placeholder="$t('login.identifier')"
                required
            />

            <ChargemapInput
                id="password"
                v-model="password"
                :is-valid="!error"
                :disabled="isLoading"
                :placeholder="$t('login.password')"
                required
                :is-password="true"
            />

            <div
                class="flex sm:items-center items-start sm:justify-between flex-col sm:flex-row w-full"
            >
                <div class="flex items-center">
                    <input
                        id="remember_me"
                        v-model="rememberUser"
                        value="yes"
                        :disabled="isLoading"
                        type="checkbox"
                        class="form-checkbox h-4 w-4 rounded-md text-primary-500 transition duration-150 ease-in-out"
                    />
                    <label
                        for="remember_me"
                        class="ml-2 block text-sm leading-5 text-gray-900"
                    >
                        {{ $t('login.stayConnected') }}
                    </label>
                </div>

                <div class="text-sm leading-5 mt-2 sm:mt-0">
                    <nuxt-link
                        to="/password-reset"
                        class="font-medium text-gray-800 tracking-tight hover:text-primary-600 focus:outline-none transition focus:underline ease-in-out duration-150"
                    >
                        {{ $t('login.forgotPassword') }}
                    </nuxt-link>
                </div>
            </div>

            <ChargemapButton
                v-if="captchaEnabled"
                id="login-button"
                class="g-recaptcha"
                :data-sitekey="siteKey"
                data-callback="recaptchaCheck"
                data-action="submit"
                data-testid="login-submit"
                :is-loading="isLoading"
                btn-type="submit"
                btn-style="group relative w-full flex justify-center button-sm"
            >
                {{ $t('login.login') }}
            </ChargemapButton>

            <ChargemapButton
                v-else
                data-testid="login-submit"
                :is-loading="isLoading"
                btn-type="submit"
                btn-style="group relative w-full flex justify-center button-sm"
            >
                {{ $t('login.login') }}
            </ChargemapButton>

            <div
                class="flex flex-col items-center justify-center font-semibold"
            >
                <span class="text-gray-800 text-center">
                    {{ $t('login.wishToHaveAccess') }}
                </span>
                <a
                    :href="contactUsLink"
                    target="_blank"
                    class="text-primary-500"
                >
                    {{ $t('login.contactUs') }}
                </a>
            </div>
        </form>
    </div>
</template>
