<script>
export default {
  name: "IconCross"
};
</script>

<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
        viewBox="0 0 32 32"
    >
        <path
            d="M0.669 0.669c0.893-0.893 2.34-0.893 3.232 0l12.098 12.098 12.098-12.098c0.893-0.893 2.34-0.893 3.232 0s0.893 2.34 0 3.232l-12.098 12.098 12.098 12.098c0.893 0.893 0.893 2.34 0 3.232s-2.34 0.893-3.232 0l-12.098-12.098-12.098 12.098c-0.893 0.893-2.34 0.893-3.232 0s-0.893-2.34 0-3.232l12.098-12.098-12.098-12.098c-0.893-0.893-0.893-2.34 0-3.232z"
        ></path>
    </svg>
</template>
