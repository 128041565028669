<script>
const FIVE_LINES_HEIGHT = 120;
export default {
  name: "CommentContent",
  props: {
    content: {
      type: String,
      required: true
    },
    mustDisplayWholeComment: {
      type: Boolean,
      required: true
    },
    smallMarginLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasClickedToUnfold: false,
      hasSmallContentHeight: false
    };
  },
  computed: {
    displayEverything() {
      return this.hasSmallContentHeight || this.mustDisplayWholeComment || this.hasClickedToUnfold;
    }
  },
  mounted() {
    this.hasSmallContentHeight = this.$refs.content.clientHeight < FIVE_LINES_HEIGHT;
  },
  methods: {
    onClickUnfold() {
      this.hasClickedToUnfold = true;
    },
    onClickFold() {
      this.hasClickedToUnfold = false;
    }
  }
};
</script>

<template>
    <div
        class="flex flex-col bg-gray-100 rounded gap-2 p-2 pb-3"
        :class="smallMarginLeft ? 'ml-2.5' : 'ml-8'"
        data-testid="comment-content"
    >
        <span
            ref="content"
            class="text-fifteen-exploitation font-normal text-gray-500 leading-6 tracking-light break-words"
            :class="{ 'max-h-[30px] overflow-hidden': !displayEverything }"
        >
            {{ content }}
        </span>
        <a
            v-if="!displayEverything"
            class="text-sky-600 font-medium text-thirteen-exploitation tracking-light cursor-pointer"
            @click="onClickUnfold"
        >
            {{ $t('show.more') }}
        </a>
        <a
            v-if="hasClickedToUnfold"
            class="text-sky-600 font-medium text-thirteen-exploitation tracking-light cursor-pointer"
            @click="onClickFold"
        >
            {{ $t('show.less') }}
        </a>
    </div>
</template>
