<script>
import ReportContent from "./ReportContent.vue";
import ReportHeader from "./ReportHeader.vue";
import CommentContent from "../comment/CommentContent.vue";
import FeedbackRating from "../FeedbackRating.vue";
import IconChatAlt from "../../../icons/IconChatAlt.vue";
const ReasonType = {
  DELIVERED_POWER_TOO_WEAK: "DELIVERED_POWER_TOO_WEAK",
  CONNEXION_OR_AUTHENTICATION_IMPOSSIBLE: "CONNEXION_OR_AUTHENTICATION_IMPOSSIBLE",
  SESSION_NOT_STARTING: "SESSION_NOT_STARTING",
  SESSION_INTERRUPTED: "SESSION_INTERRUPTED",
  CONNECTOR_DAMAGED: "CONNECTOR_DAMAGED",
  PLACE_OCCUPIED_BY_NON_CHARGING_VEHICLE: "PLACE_OCCUPIED_BY_NON_CHARGING_VEHICLE",
  ZONE_OCCUPIED_BY_CHARGING_VEHICLE: "ZONE_OCCUPIED_BY_CHARGING_VEHICLE",
  POOL_NOT_PUBLIC: "POOL_NOT_PUBLIC",
  POOL_DEFINITIVELY_CLOSED: "POOL_DEFINITIVELY_CLOSED",
  POOL_TEMPORARILY_INACCESSIBLE: "POOL_TEMPORARILY_INACCESSIBLE",
  CHARGING_IMPOSSIBLE_OUTSIDE_OPENING_HOURS: "CHARGING_IMPOSSIBLE_OUTSIDE_OPENING_HOURS",
  POOL_POWERED_OFF: "POOL_POWERED_OFF"
};
export default {
  name: "Report",
  components: {
    CommentContent,
    ReportHeader,
    ReportContent,
    FeedbackRating,
    IconChatAlt
  },
  props: {
    commonProps: {
      type: Object,
      required: true
    },
    reportProps: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    isFailedCharge() {
      return [
        ReasonType.DELIVERED_POWER_TOO_WEAK,
        ReasonType.CONNEXION_OR_AUTHENTICATION_IMPOSSIBLE,
        ReasonType.SESSION_NOT_STARTING,
        ReasonType.SESSION_INTERRUPTED,
        ReasonType.CONNECTOR_DAMAGED,
        ReasonType.POOL_POWERED_OFF
      ].includes(this.reportProps.reasonType);
    },
    hasRating() {
      return this.commonProps.rating && this.commonProps.rating.globalScore;
    },
    hasComment() {
      return this.reportProps.comment;
    }
  }
};
</script>

<template>
    <div class="flex flex-col gap-1 w-full">
        <FeedbackRating
            v-if="hasRating"
            :user="commonProps.user"
            :is-anonymous="commonProps.isAnonymous"
            :rating="commonProps.rating"
            :cdnUrl
        />
        <ReportHeader
            :creation-date="commonProps.creationDate"
            :is-anonymous="commonProps.isAnonymous"
            :is-failed-charge="isFailedCharge"
            :username="commonProps.user.username"
            :locale
        />
        <ReportContent
            :is-operator-warned="reportProps.isOperatorWarned"
            :reason-type="reportProps.reasonType"
        />
        <div
            v-if="hasComment"
            class="flex flex-row pl-1.5 items-start mt-3 w-full"
        >
            <IconChatAlt class="h-[18px] w-[18px] text-gray-comment" is-solid />
            <CommentContent
                :content="reportProps.comment"
                :must-display-whole-comment="
                    commonProps.mustDisplayWholeComment
                "
                :small-margin-left="true"
                class="w-full"
            />
        </div>
    </div>
</template>
