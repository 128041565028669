<script>
export default {
  name: "IconChargemapPartners",
  props: {
    isColored: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
    <svg
        v-if="isColored"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 7.21863C0 4.87321 4.26106e-07 3.70234 0.436082 2.8048C0.817737 2.01317 1.4321 1.36669 2.19113 0.958004C3.049 0.499999 4.17495 0.5 6.42327 0.5H25.0783C27.3266 0.5 28.449 0.499999 29.3068 0.958004C30.0674 1.36461 30.6823 2.01167 31.0619 2.8048C31.5015 3.70234 31.5015 4.87321 31.5015 7.21863V25.6866C31.5015 28.032 31.5015 29.2029 31.0619 30.1004C30.6823 30.8935 30.0674 31.5406 29.3068 31.9472C28.449 32.4015 27.3266 32.4015 25.0783 32.4015H6.42327C4.17495 32.4015 3.049 32.4015 2.19113 31.9472C1.4321 31.5385 0.817737 30.892 0.436082 30.1004C4.26106e-07 29.2029 0 28.032 0 25.6866V7.21863Z"
            fill="#895AF6"
        />
        <path
            d="M18.8908 8.53769V4.55969H16.7961V8.53769H14.6979V4.55969H12.6033V8.53769H10.498V10.5581C10.498 13.3652 11.6704 16.49 14.7051 17.0736V24.409C14.7051 25.1071 14.7051 25.6279 14.4978 25.8421C14.3476 26.001 13.8973 26.2669 12.589 26.2669C11.5667 26.2669 10.9126 26.0933 10.6874 25.7757C10.4622 25.458 10.498 24.9852 10.498 24.409V20.4495C10.498 18.5399 10.1727 16.4974 6.30516 16.4974H0.00341797V18.684H6.27298C8.41764 18.684 8.41764 19.0977 8.41764 20.4532V24.409C8.41764 25.2179 8.41764 26.2558 9.02172 27.1017C9.67942 28.0214 10.8089 28.4498 12.5962 28.4498C14.2047 28.4498 15.2734 28.1174 15.9597 27.4008C16.7818 26.5402 16.8104 25.3029 16.8069 24.409V17.0736C19.838 16.49 21.014 13.38 21.014 10.5581V8.53769H18.8908Z"
            fill="white"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 22 23"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.81485 4.78815C2.58179 5.25237 2.58179 5.85797 2.58179 7.07106V16.623C2.58179 17.8361 2.58179 18.4417 2.81485 18.9059C3.01883 19.3154 3.34718 19.6497 3.75286 19.8611C4.21135 20.0961 4.81311 20.0961 6.01473 20.0961H15.9851C17.1867 20.0961 17.7866 20.0961 18.245 19.8611C18.6515 19.6508 18.9802 19.3161 19.183 18.9059C19.418 18.4417 19.418 17.8361 19.418 16.623V7.07106C19.418 5.85797 19.418 5.25237 19.183 4.78815C18.9802 4.37793 18.6515 4.04326 18.245 3.83296C17.7866 3.59607 17.1867 3.59607 15.9851 3.59607H6.01475C4.81312 3.59607 4.21135 3.59607 3.75286 3.83296C3.34718 4.04434 3.01883 4.37871 2.81485 4.78815ZM12.6782 5.69583V7.75332H13.813V8.7983C13.813 10.2578 13.1844 11.8664 11.5644 12.1682V15.9622C11.5664 16.4246 11.5511 17.0645 11.1117 17.5097C10.7449 17.8803 10.1737 18.0522 9.31401 18.0522C8.35881 18.0522 7.75513 17.8306 7.40362 17.3549C7.08076 16.9174 7.08076 16.3806 7.08076 15.9622V13.9162C7.08076 13.2151 7.08076 13.0012 5.93453 13.0012H2.58372V11.8702H5.95173C8.01877 11.8702 8.19261 12.9266 8.19261 13.9143V15.9622C8.19261 15.999 8.19232 16.035 8.19203 16.0701C8.19002 16.3194 8.18836 16.5251 8.29386 16.6691C8.41421 16.8334 8.76382 16.9232 9.31019 16.9232C10.0094 16.9232 10.2501 16.7856 10.3303 16.7035C10.4411 16.5927 10.4411 16.3233 10.4411 15.9622V12.1682C8.81922 11.8664 8.19261 10.2502 8.19261 8.7983V7.75332H9.31783V5.69583H10.4373V7.75332H11.5587V5.69583H12.6782Z"
            />
        </g>
    </svg>
</template>
