export default function validateParams(params: {
    [key: string]: string | string[] | undefined
}): void {
    Object.keys(params).forEach((key) => {
        const param = params[key]

        if (!param || (Array.isArray(param) && !param.length)) {
            throw new Error(`missing ${key}`)
        }
    })
}
