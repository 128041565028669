import { defineStore } from 'pinia'
import type { ListResponse } from '~/interfaces/DTO'
import {
    NetworkPhotoType,
    type CommunityNetwork,
    type CommunityNetworkFeatures,
    type Network,
    type NetworkPhotoAnswer
} from '~/interfaces/network'
import validateParams from './utils/validateParams'
import useTokenStore from './token'

const useNetworksStore = defineStore(
    'networks',
    () => {
        const loadingNetwork = ref(false)
        const currentCommunityNetwork = ref<CommunityNetwork | null>(null)
        const currentNetwork = ref<Network | null>(null)

        const { $throwError } = useNuxtApp()

        const fetchLinkedNetworks = (
            idPartner: string | number,
            keyword: string,
            idNetwork?: number
        ) => {
            const { $throwError } = useNuxtApp()

            loadingNetwork.value = true

            return $fetchApi<ListResponse<any>>(
                `/partners-api/partners/${idPartner}/community_networks`,
                {
                    params: {
                        keyword: keyword !== '' ? keyword : null,
                        limit: 100,
                        network_id: idNetwork
                    }
                }
            )
                .then((response) => ({
                    items: response.items
                }))
                .catch((error: any) => {
                    $throwError(
                        error,
                        'cannot fetch networks linked to partner'
                    )
                })
                .finally(() => {
                    loadingNetwork.value = false
                })
        }

        const fetchCommunityNetworks = (name: string) => {
            const { $throwError } = useNuxtApp()

            loadingNetwork.value = true

            return $fetchApi<ListResponse<CommunityNetwork>>(
                '/partners-api/community_networks',
                {
                    params: {
                        name
                    }
                }
            )
                .then((response) => ({
                    items: response.items
                }))
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch community networks')
                })
                .finally(() => {
                    loadingNetwork.value = false
                })
        }

        const fetchNetwork = (
            idPartner: string | number,
            idNetwork: number,
            networkType: string
        ) => {
            const { $throwError } = useNuxtApp()

            if (networkType === 'community') {
                loadingNetwork.value = true

                if (currentCommunityNetwork.value?.id !== idNetwork) {
                    resetCache()
                }

                return $fetchApi<CommunityNetwork>(
                    `/partners-api/partners/${idPartner}/community_networks/${idNetwork}`
                )
                    .then((response) => {
                        return response
                    })
                    .catch((error: any) => {
                        $throwError(error, 'cannot fetch network')
                    })
                    .finally(() => {
                        loadingNetwork.value = false
                    })
            }

            if (networkType === 'interop') {
                return fetchLinkedNetworks(idPartner, '', idNetwork).then(
                    (res) => {
                        if (res?.items && res.items.length === 1) {
                            currentCommunityNetwork.value = res.items[0]
                            currentNetwork.value = res.items[0].networks[0]
                        } else {
                            $throwError(
                                null,
                                `cannot fetch network : ${JSON.stringify(res)}`
                            )
                        }
                    }
                )
            }

            $throwError(null, `wrong network type : ${networkType}`)

            return Promise.reject()
        }

        const linkNetwork = (idPartner: string, network: CommunityNetwork) => {
            loadingNetwork.value = true

            const payload = {
                id: network.id,
                networks: network.networks.map((net) => net.id)
            }

            return $fetchApi<any>(
                `/partners-api/partners/${idPartner}/community_networks`,
                { body: payload, method: 'POST' }
            )
                .then((response) => response)
                .catch((e: any) => {
                    throw e
                })
                .finally(() => {
                    loadingNetwork.value = false
                })
        }

        const setCurrentCommunityNetwork = (
            network: CommunityNetwork | null
        ) => {
            currentNetwork.value = null
            currentCommunityNetwork.value = network
        }

        const unlinkNetwork = (idPartner: string, networkId: number) => {
            return $fetchApi(
                `/partners-api/partners/${idPartner}/networks/${networkId}`,
                {
                    method: 'PUT'
                }
            )
                .then((response) => response)
                .catch((error: any) => {
                    $throwError(error, 'cannot unlink network')
                })
        }

        const updateNetworkPhoto = (
            photo: string | null,
            type: NetworkPhotoType
        ) => {
            const { $throwError } = useNuxtApp()
            const { idPartner } = useRoute().params

            validateParams({ idPartner })

            const networkId = currentCommunityNetwork.value?.id

            if (!currentCommunityNetwork.value) {
                return Promise.reject('no community network selected')
            }

            if (!networkId) {
                return Promise.reject('no community network selected')
            }

            return $fetchApi<NetworkPhotoAnswer>(
                `/partners-api/partners/${idPartner}/community_networks/${networkId}`,
                {
                    method: 'PATCH',
                    body: JSON.stringify({
                        [`photo_${type}`]: photo
                    })
                }
            )
                .then((response) => {
                    if (
                        type === NetworkPhotoType.AVATAR &&
                        currentCommunityNetwork.value
                    ) {
                        currentCommunityNetwork.value.avatarUrl =
                            response.avatarUrl
                    } else if (
                        type === NetworkPhotoType.ICON &&
                        currentCommunityNetwork.value
                    ) {
                        currentCommunityNetwork.value.iconUrl = response.iconUrl
                    }

                    return response
                })
                .catch((error) => {
                    $throwError(
                        error,
                        `cannot update network ${networkId} ${type} photo`
                    )

                    return Promise.reject(error)
                })
        }

        const updateNetworkDescription = (
            preserveDescription: boolean,
            description?: string
        ) => {
            const { $throwError } = useNuxtApp()
            const { idPartner } = useRoute().params

            validateParams({ idPartner })

            const networkId = useNetworksStore().currentCommunityNetwork?.id

            if (!networkId) {
                return Promise.reject('no community network selected')
            }

            return $fetchApi(
                `/partners-api/partners/${idPartner}/community_networks/${networkId}/description`,
                {
                    method: 'PATCH',
                    body: {
                        description,
                        preserveDescription
                    }
                }
            )
                .then(() => {
                    if (preserveDescription) {
                        currentCommunityNetwork.value!.zonesGlobalDescription =
                            description
                    } else {
                        currentCommunityNetwork.value!.zonesGlobalDescription =
                            undefined
                    }
                })
                .catch((error) => {
                    $throwError(
                        error,
                        `cannot update network ${networkId} description`
                    )

                    return Promise.reject(error)
                })
        }

        const updateNetworkFeatures = async (
            editedCommunityNetworkFeatures: CommunityNetworkFeatures[]
        ) => {
            const { idPartner } = useRoute().params

            validateParams({ idPartner })

            for (const communityNetworkFeatures of editedCommunityNetworkFeatures) {
                await $fetchApi<CommunityNetwork>(
                    `/partners-api/partners/${idPartner}/community_networks/${communityNetworkFeatures.communityNetworkId}/features`,
                    {
                        method: 'PATCH',
                        body: {
                            featureNames: communityNetworkFeatures.features.map(
                                (value) => value.featureName
                            )
                        }
                    }
                )
                    .then((network) => {
                        if (currentCommunityNetwork.value?.id === network.id) {
                            currentCommunityNetwork.value.features =
                                network.features
                        }
                    })
                    .catch((error) => {
                        $throwError(
                            error,
                            `cannot update network ${communityNetworkFeatures.communityNetworkId} features`
                        )
                    })
            }
        }

        const importStations = (formData: FormData) => {
            const { idPartner } = useRoute().params

            validateParams({ idPartner })

            const networkId = currentCommunityNetwork.value?.id

            if (!networkId) {
                return Promise.reject('no community network selected')
            }

            return $fetch(
                `/partners-api/partners/${idPartner}/community_networks/${networkId}/zones/import/json`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: useTokenStore().token || ''
                    },
                    body: formData
                }
            )
                .then((response) => response)
                .catch((error) => {
                    $throwError(
                        error,
                        `cannot import data to network ${networkId} for partner ${idPartner}`
                    )

                    return Promise.reject(error)
                })
        }

        const {
            handlePublishNetwork,
            cachedIcon,
            cachedAvatar,
            editedDescription,
            globalDescription,
            cachedDescription,
            addedIcon,
            addedAvatar,
            deletedIcon,
            deletedAvatar,
            cacheUpdateAvatar,
            cacheUpdateIcon,
            hasEditedInfos,
            hasEditedDescription,
            resetCache
        } = useNetworkCache(
            currentCommunityNetwork,
            updateNetworkPhoto,
            updateNetworkDescription
        )

        const reset = () => {
            loadingNetwork.value = false
            currentCommunityNetwork.value = null
            currentNetwork.value = null
            resetCache()
        }

        return {
            loadingNetwork,
            currentCommunityNetwork,
            currentNetwork,
            fetchLinkedNetworks,
            fetchCommunityNetworks,
            fetchNetwork,
            linkNetwork,
            setCurrentCommunityNetwork,
            updateNetworkDescription,
            updateNetworkPhoto,
            unlinkNetwork,
            updateNetworkFeatures,
            importStations,

            // Cache
            // Cache actions
            handlePublishNetwork,
            cacheUpdateAvatar,
            cacheUpdateIcon,
            resetCache,

            // Computed values
            cachedIcon,
            cachedAvatar,
            cachedDescription,
            hasEditedInfos,
            hasEditedDescription,

            // Cache values for local storage
            deletedIcon,
            globalDescription,
            editedDescription,
            deletedAvatar,
            addedIcon,
            addedAvatar,

            reset
        }
    },
    {
        persist: {
            storage: localStorage
        }
    }
)

type NetworksStore = Omit<
    ReturnType<typeof useNetworksStore>,
    keyof ReturnType<typeof defineStore>
>

export default useNetworksStore

export type { NetworksStore }
