import useEngagementsNavigation from '~/composables/useEngagementsNavigation'
import { defineStore } from 'pinia'

import type { EngagementItem } from '~/interfaces/engagement'
import {
    ChargeSuccess,
    FilterFeedbackType,
    type FiltersFeedback
} from '~/interfaces/feedback'
import { FilterFeedbackType as SimplifiedFilterFeedbackType } from '#cmui/types'
import hash from 'object-hash'
import type { Feedback, FeedbackFilters } from '#cmui/types/feedback'
import {
    FilterType,
    type CheckboxFilter,
    type FilterSection,
    type FiltersEngagement,
    type FiltersStationList
} from '~/interfaces/filters'
import type { ListResponse } from '../interfaces/DTO'
import usePeriodStore from './period'
import filterQueryBuilder from './utils/filterQueryBuilder'
import { hasFilter } from './utils/filtersUtils'

const useEngagementsStore = defineStore(
    'engagements',
    () => {
        const loadingEngagements = ref(false)
        const zoneFeedbacks = ref<Feedback[]>([])
        const zoneFeedbacksCount = ref(0)
        const feedbacks = ref<Feedback[]>([])
        const currentFeedback = ref<Feedback>()
        const currentZone = ref<number>()
        const previousFilters = ref<string>()
        const unreadFeedbackCount = ref(0)

        const fetchFeedback = async (idEngagement: number) => {
            const { $throwError } = useNuxtApp()
            loadingEngagements.value = true

            const { idPartner } = useRoute().params

            return $fetchApi<Feedback>(
                `/partners-api/partners/${idPartner}/engagements/${idEngagement}`
            )
                .then((response) => {
                    currentFeedback.value = response

                    return response
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch feedback')

                    throw error
                })
                .finally(() => {
                    loadingEngagements.value = false
                })
        }

        const fetchZonesEngagementsByIds = async (
            zoneIds: number[],
            currentPeriod: boolean = false
        ) => {
            const { getCurrentPeriod, getPreviousPeriod } = usePeriodStore()

            const period = currentPeriod ? getCurrentPeriod : getPreviousPeriod

            const payload = {
                ...period,
                zoneIds
            }

            return getZoneEngagements(payload)
        }

        const fetchZonesEngagements = async (filters: FiltersStationList) => {
            const {
                keyword,
                orderColumn,
                orderDirection,
                pages,
                stationFilters,
                hideEmpty
            } = filters

            const offset = (pages.index - 1) * pages.perPage || (0 as number)
            const limit = pages.perPage || (10 as number)

            const { getCurrentPeriod } = usePeriodStore()

            const payload = {
                keyword: keyword || undefined,
                offset,
                limit,
                orderBy:
                    orderColumn && orderDirection
                        ? `${orderColumn},${orderDirection}`
                        : undefined,
                hideEmpty,
                ...getCurrentPeriod,
                ...filterQueryBuilder(stationFilters)
            }

            return getZoneEngagements(payload)
        }

        const getZoneEngagements = async (payload: any) => {
            const route = useRoute()

            const { idPartner, idNetwork, networkType } = route.params

            if (!idPartner || !idNetwork || !networkType) {
                return Promise.reject(new Error('Missing parameters'))
            }

            const { $throwError } = useNuxtApp()

            const urlNetwork =
                networkType === 'community' ? 'community_networks' : 'networks'

            loadingEngagements.value = true

            return $fetchApiRaw<ListResponse<EngagementItem>>(
                `/partners-api/partners/${idPartner}/${urlNetwork}/${idNetwork}/engagements/by_zone`,
                {
                    params: payload
                }
            )
                .then((response) => {
                    const totalItems = parseInt(
                        response.headers.get('x-total-count') || '0',
                        10
                    )

                    return {
                        totalItems,
                        items: response._data?.items || []
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch engagements zones')

                    throw error
                })
                .finally(() => {
                    loadingEngagements.value = false
                })
        }

        const isArchivedFilter = (filters: FilterSection[]) => {
            let isArchived: boolean | undefined

            const archivedFilterChecked = hasFilter(
                filters,
                FilterFeedbackType.ARCHIVED
            )

            const unreadFilterChecked = hasFilter(
                filters,
                FilterFeedbackType.UNREAD
            )

            if (archivedFilterChecked && !unreadFilterChecked) {
                isArchived = true
            } else if (!archivedFilterChecked && unreadFilterChecked) {
                isArchived = false
            }

            return isArchived
        }

        const hasCommentFilter = (engagementFilters: FilterSection[]) => {
            const withCommentFilter = hasFilter(
                engagementFilters,
                FilterFeedbackType.WITH_COMMENT
            )

            const reportWithoutCommentFilter = hasFilter(
                engagementFilters,
                FilterFeedbackType.REPORT_WITHOUT_COMMENT
            )

            let hasComment: boolean | undefined

            if (withCommentFilter && !reportWithoutCommentFilter) {
                hasComment = true
            } else if (!withCommentFilter && reportWithoutCommentFilter) {
                hasComment = false
            }

            return hasComment
        }

        const chargeSuccessFilter = (engagementFilters: FilterSection[]) => {
            const hasChargeSuccess = hasFilter(
                engagementFilters,
                ChargeSuccess.SUCCESS
            )

            const chargeFailedFilter = hasFilter(
                engagementFilters,
                ChargeSuccess.FAILED
            )

            let chargeSuccess: boolean | undefined

            if (hasChargeSuccess && !chargeFailedFilter) {
                chargeSuccess = true
            } else if (!hasChargeSuccess && chargeFailedFilter) {
                chargeSuccess = false
            }

            return chargeSuccess
        }

        const buildFeedbackFilters = (filters: FilterSection[]) =>
            JSON.parse(JSON.stringify(filters)).map(
                (filterSection: FilterSection) => {
                    const filteredFilters = filterSection.filters.filter(
                        (filter) =>
                            (filter.type === FilterType.CHECKBOX &&
                                ![
                                    FilterFeedbackType.ARCHIVED,
                                    FilterFeedbackType.UNREAD,
                                    FilterFeedbackType.RATED,
                                    FilterFeedbackType.ANSWERED,
                                    FilterFeedbackType.UNANSWERED,
                                    FilterFeedbackType.WITH_COMMENT,
                                    ChargeSuccess.FAILED,
                                    ChargeSuccess.SUCCESS
                                ].includes(
                                    filter.value as FilterFeedbackType
                                )) ||
                            filter.type === FilterType.RANGE
                    )

                    const reportWithoutCommentFilter = filteredFilters.find(
                        (filter) =>
                            filter.value ===
                            FilterFeedbackType.REPORT_WITHOUT_COMMENT
                    ) as CheckboxFilter

                    if (reportWithoutCommentFilter?.checked) {
                        reportWithoutCommentFilter.checked = false

                        const reportFilter = filteredFilters.find(
                            (filter) =>
                                filter.value === FilterFeedbackType.REPORT
                        ) as CheckboxFilter

                        if (reportFilter) {
                            reportFilter.checked = true
                        }
                    }

                    return {
                        ...filterSection,
                        filters: [...filteredFilters]
                    }
                }
            )

        const fetchFeedbacks = async (
            filters: FiltersEngagement,
            resetCache: boolean = false,
            offsetIndex: number | undefined = undefined
        ) => {
            const { $throwError } = useNuxtApp()
            const route = useRoute()
            const { getCurrentPeriod } = usePeriodStore()
            const { idPartner, idNetwork, networkType } = route.params

            const {
                keyword,
                orderColumn,
                orderDirection,
                engagementFilters,
                pages
            } = filters

            const offset =
                offsetIndex !== undefined
                    ? offsetIndex
                    : (pages.index - 1) * pages.perPage || (0 as number)
            const limit = pages.perPage || (10 as number)

            if (resetCache) {
                cachedFilters.value = filters
                cacheCurrentPeriod.value = getCurrentPeriod
            }
            minIndexOffset.value = offset

            const urlNetwork =
                networkType === 'community' ? 'community_networks' : 'networks'

            const hasRating =
                hasFilter(engagementFilters, FilterFeedbackType.RATED) ||
                undefined

            let isAnswered: boolean | undefined = undefined

            // Check if the user has selected both answered and unanswered filters
            if (
                hasFilter(engagementFilters, FilterFeedbackType.ANSWERED) &&
                !hasFilter(engagementFilters, FilterFeedbackType.UNANSWERED)
            ) {
                isAnswered = true
            } else if (
                hasFilter(engagementFilters, FilterFeedbackType.UNANSWERED) &&
                !hasFilter(engagementFilters, FilterFeedbackType.ANSWERED)
            ) {
                isAnswered = false
            }

            const backendHandledFilters: FilterSection[] =
                buildFeedbackFilters(engagementFilters)

            const payload = {
                keyword: keyword || undefined,
                offset,
                limit,
                orderBy:
                    orderColumn && orderDirection
                        ? `${orderColumn},${orderDirection}`
                        : undefined,
                isArchived: isArchivedFilter(engagementFilters),
                hasComment: hasCommentFilter(engagementFilters),
                chargeSuccess: chargeSuccessFilter(engagementFilters),
                hasRating,
                isAnswered,
                ...cacheCurrentPeriod.value,
                ...filterQueryBuilder(backendHandledFilters)
            }

            return $fetchApiRaw<ListResponse<Feedback>>(
                `/partners-api/partners/${idPartner}/${urlNetwork}/${idNetwork}/engagements`,
                {
                    params: payload
                }
            )
                .then((response) => {
                    const totalCount = parseInt(
                        response.headers.get('x-total-count') || '0',
                        10
                    )

                    feedbacks.value = response._data?.items || []

                    if (resetCache) {
                        cachedFeedbacks.value = response._data?.items || []
                        initialCachedFeedbacks.value =
                            JSON.parse(JSON.stringify(response._data?.items)) ||
                            []
                        cacheTotalCount.value = totalCount
                    } else if (offset > minIndexOffset.value) {
                        cachedFeedbacks.value = cachedFeedbacks.value.concat(
                            response._data?.items || []
                        )
                        initialCachedFeedbacks.value =
                            initialCachedFeedbacks.value.concat(
                                response._data?.items || []
                            )
                    } else {
                        cachedFeedbacks.value = (
                            response._data?.items || []
                        ).concat(cachedFeedbacks.value)
                        initialCachedFeedbacks.value = (
                            response._data?.items || []
                        ).concat(initialCachedFeedbacks.value)
                    }

                    return {
                        totalItems: totalCount,
                        items: response._data?.items
                    }
                })
                .catch((error) => {
                    $throwError(error, 'cannot fetch engagements feedbacks')

                    throw error
                })
                .finally(() => {
                    loadingEngagements.value = false
                })
        }

        const {
            cachedFeedbacks,
            initialCachedFeedbacks,
            cachedFilters,
            cacheTotalCount,
            cacheCurrentPeriod,
            currentFeedbackIndex,
            minIndexOffset
        } = useEngagementsNavigation(
            currentFeedback,
            loadingEngagements,
            fetchFeedbacks
        )

        const legacyFetchFeedbacks = async (
            idPartner: string,
            idNetwork: string,
            typeNetwork: string,
            filters: FiltersFeedback
        ) => {
            const {
                keyword,
                orderColumn,
                orderDirection,
                feedbackTypeFilter,
                moodFilter,
                pages,
                range
            } = filters
            const offset = (pages.index - 1) * pages.perPage || (0 as number)
            const limit = pages.perPage || (10 as number)

            const hasRating = feedbackTypeFilter.includes(
                FilterFeedbackType.RATED
            )
                ? true
                : undefined

            let isArchived

            if (
                feedbackTypeFilter.includes(FilterFeedbackType.ARCHIVED) &&
                !feedbackTypeFilter.includes(FilterFeedbackType.UNREAD)
            ) {
                isArchived = true
            } else if (
                feedbackTypeFilter.includes(FilterFeedbackType.UNREAD) &&
                !feedbackTypeFilter.includes(FilterFeedbackType.ARCHIVED)
            ) {
                isArchived = false
            }

            const listFeedbackType = [
                FilterFeedbackType.REPORT,
                FilterFeedbackType.COMMENT,
                FilterFeedbackType.CHECKIN
            ]

            const payload = {
                keyword: keyword || undefined,
                offset,
                limit,
                orderBy:
                    orderColumn && orderDirection
                        ? `${orderColumn},${orderDirection}`
                        : undefined,
                type: feedbackTypeFilter.filter((item: FilterFeedbackType) =>
                    listFeedbackType.includes(item)
                ),
                moodFilter,
                ...usePeriodStore().getCurrentPeriod,
                hasRating,
                isArchived,
                ratingMin: range?.min,
                ratingMax: range?.max
            }

            const { $throwError } = useNuxtApp()

            const urlNetwork =
                typeNetwork === 'community' ? 'community_networks' : 'networks'

            loadingEngagements.value = true

            return $fetchApiRaw<ListResponse<Feedback>>(
                `/partners-api/partners/${idPartner}/${urlNetwork}/${idNetwork}/engagements`,
                {
                    params: payload
                }
            )
                .then((response) => {
                    const totalCount = parseInt(
                        response.headers.get('x-total-count') || '0',
                        10
                    )

                    return {
                        totalItems: totalCount,
                        items: response._data?.items
                    }
                })
                .catch((error) => {
                    $throwError(
                        error,
                        'cannot legacy fetch engagements feedbacks'
                    )
                })
                .finally(() => {
                    loadingEngagements.value = false
                })
        }

        const fetchZoneFeedbacks = async (
            idPartner: string,
            zoneId: number,
            filters: FeedbackFilters,
            more: boolean = false
        ) => {
            const { keyword, feedbackTypeFilter, moodFilter, range } = filters

            let offset = 0
            const limit = 50

            const filtersHash = hash(JSON.stringify(filters))

            if (
                currentZone.value !== zoneId ||
                previousFilters.value !== filtersHash ||
                !more
            ) {
                currentZone.value = zoneId

                previousFilters.value = filtersHash
                zoneFeedbacks.value = []
            } else {
                offset = zoneFeedbacks.value.length
            }

            const hasRating =
                feedbackTypeFilter?.includes(
                    SimplifiedFilterFeedbackType.RATED
                ) || range
                    ? true
                    : undefined

            const listFeedbackType = [
                SimplifiedFilterFeedbackType.REPORT,
                SimplifiedFilterFeedbackType.COMMENT,
                SimplifiedFilterFeedbackType.CHECKIN
            ]

            let isArchived

            if (
                feedbackTypeFilter?.includes(
                    SimplifiedFilterFeedbackType.ARCHIVED
                ) &&
                !feedbackTypeFilter?.includes(
                    SimplifiedFilterFeedbackType.UNREAD
                )
            ) {
                isArchived = true
            } else if (
                feedbackTypeFilter?.includes(
                    SimplifiedFilterFeedbackType.UNREAD
                ) &&
                !feedbackTypeFilter?.includes(
                    SimplifiedFilterFeedbackType.ARCHIVED
                )
            ) {
                isArchived = false
            }

            const payload = {
                keyword: keyword || undefined,
                offset,
                limit,
                type: feedbackTypeFilter?.filter(
                    (item: SimplifiedFilterFeedbackType) =>
                        listFeedbackType.includes(item)
                ),
                ...usePeriodStore().getCurrentPeriod,
                moodFilter,
                hasRating,
                isArchived,
                minRating: range?.min,
                maxRating: range?.max
            }

            const { $throwError } = useNuxtApp()

            loadingEngagements.value = true

            return $fetchApiRaw<ListResponse<Feedback>>(
                `/partners-api/partners/${idPartner}/engagements/zones/${zoneId}`,
                {
                    params: payload
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        const totalCount = parseInt(
                            response.headers.get('x-total-count') || '0',
                            10
                        )

                        if (response._data?.items) {
                            // We add the new results to the state
                            zoneFeedbacks.value = zoneFeedbacks.value.concat(
                                response._data.items
                            )
                        }
                        zoneFeedbacksCount.value = totalCount

                        return {
                            totalItems: totalCount,
                            items: response._data?.items
                        }
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch zone feedbacks')
                })
                .finally(() => {
                    loadingEngagements.value = false
                })
        }

        const createAnswerFeedback = async (
            idEngagement: number,
            content: string
        ) => {
            const { $throwError } = useNuxtApp()

            const { idPartner } = useRoute().params

            const payload = {
                content
            }

            return $fetchApi<{
                name: string
                content: string
                creationDate: string
            }>(
                `/partners-api/partners/${idPartner}/engagements/${idEngagement}/partner_response`,
                {
                    method: 'POST',
                    body: payload
                }
            )
                .then((result) => {
                    if (currentFeedback.value) {
                        currentFeedback.value.partnerResponse = result
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot create feedback answer')

                    throw error
                })
        }

        const updateAnswerFeedback = async (
            idEngagement: number,
            content: string
        ) => {
            const { $throwError } = useNuxtApp()

            const { idPartner } = useRoute().params

            const payload = {
                content
            }

            return $fetchApiRaw(
                `/partners-api/partners/${idPartner}/engagements/${idEngagement}/partner_response`,
                {
                    method: 'PATCH',
                    body: payload
                }
            )
                .then(() => {
                    feedbacks.value.forEach((feedback) => {
                        if (
                            feedback.partnerResponse &&
                            feedback.id === idEngagement
                        ) {
                            feedback.partnerResponse.content = content
                        }
                    })

                    cachedFeedbacks.value.forEach((feedback) => {
                        if (
                            feedback.partnerResponse &&
                            feedback.id === idEngagement
                        ) {
                            feedback.partnerResponse.content = content
                        }
                    })

                    if (currentFeedback.value?.partnerResponse) {
                        currentFeedback.value.partnerResponse.content = content
                    }
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot update feedback answer')

                    throw error
                })
        }

        const updateFeedback = async (
            idPartner: string,
            idEngagement: number,
            isArchived: boolean
        ) => {
            const { $throwError } = useNuxtApp()

            const payload = {
                archived: isArchived
            }

            return $fetchApi(
                `/partners-api/partners/${idPartner}/engagements/${idEngagement}`,
                {
                    method: 'PATCH',
                    body: payload
                }
            )
                .then(() => {
                    feedbacks.value.forEach((feedback) => {
                        if (feedback.id === idEngagement) {
                            feedback.isArchived = isArchived
                        }
                    })

                    cachedFeedbacks.value.forEach((feedback) => {
                        if (feedback.id === idEngagement) {
                            feedback.isArchived = isArchived
                        }
                    })
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot update feedback')
                    throw error
                })
        }

        const bulkUpdateFeedbacks = async (
            engagementsIds: number[],
            archived: boolean
        ) => {
            const { $throwError } = useNuxtApp()

            const payload = {
                engagements_ids: engagementsIds,
                archived
            }

            const { idPartner } = useRoute().params

            return $fetchApiRaw(
                `/partners-api/partners/${idPartner}/engagements`,
                {
                    method: 'PATCH',
                    body: payload
                }
            )
                .then(() =>
                    feedbacks.value.forEach((feedback) => {
                        if (engagementsIds.includes(feedback.id)) {
                            feedback.isArchived = archived
                        }
                    })
                )
                .catch((error: any) => {
                    $throwError(error, 'cannot update feedbacks')
                    throw error
                })
        }

        const fetchUnreadFeedbackCount = async (
            networkType: string,
            idPartner: string,
            idNetwork: string
        ) => {
            const { $throwError } = useNuxtApp()

            const urlNetwork =
                networkType === 'community' ? 'community_networks' : 'networks'

            const params = {
                ...usePeriodStore().getCurrentPeriod,
                isArchived: false,
                hasComment: true
            }

            return $fetchApi<{
                count: number
            }>(
                `/partners-api/partners/${idPartner}/${urlNetwork}/${idNetwork}/engagements/count`,
                {
                    params
                }
            )
                .then((response) => {
                    unreadFeedbackCount.value = response.count
                })
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch feedback count')
                })
        }

        const reset = () => {
            loadingEngagements.value = false
            zoneFeedbacks.value = []
            zoneFeedbacksCount.value = 0
            feedbacks.value = []
            currentFeedback.value = undefined
            currentZone.value = undefined
            previousFilters.value = undefined
            unreadFeedbackCount.value = 0
        }

        return {
            loadingEngagements,
            zoneFeedbacks,
            zoneFeedbacksCount,
            feedbacks,
            currentFeedback,
            currentZone,
            getZoneEngagements,
            previousFilters,
            unreadFeedbackCount,
            fetchFeedback,
            fetchZonesEngagementsByIds,
            fetchZonesEngagements,
            fetchFeedbacks,
            legacyFetchFeedbacks,
            fetchZoneFeedbacks,
            createAnswerFeedback,
            updateAnswerFeedback,
            updateFeedback,
            bulkUpdateFeedbacks,
            fetchUnreadFeedbackCount,

            // Feedback navigation
            cachedFilters,
            cachedFeedbacks,
            currentFeedbackIndex,
            cacheTotalCount,
            startIndexOffset: minIndexOffset,
            initialCachedFeedbacks,
            cacheCurrentPeriod,

            // For tests
            buildFeedbackFilters,
            chargeSuccessFilter,
            hasCommentFilter,
            isArchivedFilter,
            minIndexOffset,

            // Reset
            reset
        }
    },
    {
        persist: {
            storage: localStorage
        }
    }
)

type EngagementsStore = Omit<
    ReturnType<typeof useEngagementsStore>,
    keyof ReturnType<typeof defineStore>
>

export default useEngagementsStore

export type { EngagementsStore }
