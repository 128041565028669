import useNetworksStore from '~/store/networks'

export default (communityOnly?: boolean) => {
    const { currentCommunityNetwork } = storeToRefs(useNetworksStore())
    const { idNetwork, networkType } = useRoute().params

    if (!currentCommunityNetwork.value) {
        throw new Error('No current community network')
    }

    const urlNetwork =
        networkType === 'community' || communityOnly
            ? `community_networks/${idNetwork}`
            : `community_networks/${currentCommunityNetwork.value.id}/networks/${idNetwork}`

    return urlNetwork
}
