import type { PartnerPoolDetails } from './../interfaces/station'
import { defineStore } from 'pinia'
import type { ListResponse } from '~/interfaces/DTO'
import {
    StationCatalogFilter,
    type FiltersStationCatalog
} from '~/interfaces/filters'
import type { StationListItem } from '~/interfaces/station'
import filterQueryBuilder from './utils/filterQueryBuilder'
import type { PoolDetail, AmenityType } from '#cmui/types'
import useNetworksStore from './networks'

const useStationStore = defineStore(
    'station',
    () => {
        const networkStore = useNetworksStore()

        const stations = ref<StationListItem[]>([])
        const currentStation = ref<PartnerPoolDetails>()
        const totalCount = ref(0)

        const loadingStation = ref(false)

        const editedDescription = ref<string>()
        const editedAmenities = ref<AmenityType[]>([])

        const stationWithCachedValues = computed<PoolDetail | null>(() => {
            if (!currentStation.value) {
                return null
            }

            return {
                ...currentStation.value,
                network: {
                    ...currentStation.value.network,
                    avatarUrl: networkStore.cachedAvatar
                },
                description: editedDescription.value,
                amenities: editedAmenities.value
            } as PoolDetail
        })

        const fetchStationsList = (filters: FiltersStationCatalog) => {
            const route = useRoute()

            const { idPartner } = route.params

            const { keyword, stationFilters, pages, catalogFilter } = filters

            const { $throwError } = useNuxtApp()

            const limit = pages.perPage || 10
            const offset = (pages.index - 1) * pages.perPage || (0 as number)

            const backendFilters = filterQueryBuilder(stationFilters)

            const payload: any = {
                keyword: keyword || undefined,
                limit,
                offset,
                ...backendFilters
            }

            if (catalogFilter !== StationCatalogFilter.ALL) {
                payload[catalogFilter] = true
            }

            const urlNetwork = useNetworkQueryBuilder()

            return $fetchApiRaw<ListResponse<StationListItem>>(
                `/partners-api/partners/${idPartner}/${urlNetwork}/zones/catalog`,
                {
                    params: payload
                }
            )
                .then((response) => {
                    totalCount.value = parseInt(
                        response.headers.get('x-total-count') || '0',
                        10
                    )

                    if (response._data) {
                        stations.value = response._data.items

                        return response._data.items
                    }

                    return []
                })
                .catch((error) => {
                    $throwError(
                        error,
                        `cannot fetch stations ${JSON.stringify(filters)}`
                    )

                    return Promise.reject(error)
                })
        }

        const setStationInfos = (station: PartnerPoolDetails) => {
            if (
                currentStation.value?.id !== station.id ||
                !editedDescription.value
            ) {
                editedDescription.value = station.description
            }

            if (
                currentStation.value?.id !== station.id ||
                editedAmenities.value.length === 0
            ) {
                editedAmenities.value = station.amenities || []
            }

            currentStation.value = station
        }

        const fetchStation = async (stationId: string) => {
            const { idPartner } = useRoute().params

            loadingStation.value = true

            return $fetchApi<PartnerPoolDetails>(
                `/partners-api/partners/${idPartner}/zones/community/${stationId}`
            )
                .then((response) => {
                    setStationInfos(response)

                    return response
                })
                .finally(() => {
                    loadingStation.value = false
                })
        }

        const updateAmenities = (amenities: AmenityType[]) => {
            const { idPartner } = useRoute().params

            const networkQuery = useNetworkQueryBuilder(true)

            return $fetchApi(
                `/partners-api/partners/${idPartner}/${networkQuery}/zones/${currentStation.value?.id}`,
                {
                    method: 'PATCH',
                    body: {
                        amenities
                    }
                }
            ).then(() => {
                if (currentStation.value) {
                    currentStation.value = {
                        ...currentStation.value,
                        amenities
                    }
                }
            })
        }

        const updateDescription = (description: string) => {
            const { idPartner } = useRoute().params

            const networkQuery = useNetworkQueryBuilder(true)

            return $fetchApi(
                `/partners-api/partners/${idPartner}/${networkQuery}/zones/${currentStation.value?.id}`,
                {
                    method: 'PATCH',
                    body: {
                        description
                    }
                }
            ).then(() => {
                if (currentStation.value) {
                    currentStation.value.description = description
                }
            })
        }

        const addStationToFavorite = (station: StationListItem) => {
            const { idPartner } = useRoute().params
            const { $throwError } = useNuxtApp()

            const stationFavoriteState = station.isUserFavorite

            // We update the station state before the API call for a better UX
            stations.value.forEach((s) => {
                if (s.id === station.id) {
                    s.isUserFavorite = !stationFavoriteState
                }
            })

            const networkQuery = useNetworkQueryBuilder(true)

            const method = stationFavoriteState ? 'DELETE' : 'POST'

            return $fetchApi(
                `/partners-api/partners/${idPartner}/${networkQuery}/zones/${station.id}/favorite`,
                {
                    method
                }
            ).catch((error) => {
                $throwError(
                    error,
                    `cannot add station ${station.id} to favorites`
                )

                // We go back to the previous state if the API call fails
                stations.value.forEach((s) => {
                    if (s.id === station.id) {
                        s.isUserFavorite = stationFavoriteState
                    }
                })

                return Promise.reject(error)
            })
        }

        const reset = () => {
            stations.value = []
            currentStation.value = undefined
            totalCount.value = 0
            editedDescription.value = undefined
            editedAmenities.value = []
            loadingStation.value = false
        }

        return {
            addStationToFavorite,
            fetchStationsList,
            stations,
            currentStation,
            totalCount,
            fetchStation,
            editedDescription,
            updateDescription,
            setStationInfos,
            editedAmenities,
            updateAmenities,
            loadingStation,
            stationWithCachedValues,
            reset
        }
    },
    {
        persist: {
            storage: localStorage
        }
    }
)

type StationStore = Omit<
    ReturnType<typeof useStationStore>,
    keyof ReturnType<typeof defineStore>
>

export default useStationStore

export type { StationStore }
