<script>
import BasePagination from "../../common/global/BasePagination.vue";
export default {
  name: "ChargemapPagination",
  components: { BasePagination },
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      default: 0
    }
  },
  emits: ["update:model-value"],
  methods: {
    goTo(index) {
      this.$emit("update:model-value", index);
    }
  }
};
</script>

<template>
    <BasePagination
        data-testid="chargemap-pagination"
        v-bind="$props"
        @update:model-value="goTo"
    />
</template>

<style>
.pagination {
  @apply flex gap-2;
}
.pagination button {
  @apply flex items-center justify-center rounded-full size-10 bg-zinc-100;
}
.pagination button:disabled:not(.pagination--page__active) {
  @apply cursor-not-allowed text-gray-disabled;
}
.pagination--page:hover:not(:disabled) {
  @apply border border-badge-info text-primary-info bg-white;
}
.pagination--page__active {
  @apply border border-light-blue-primary bg-primary-light text-primary-info;
}
.pagination--page:disabled {
  @apply cursor-not-allowed;
}
.pagination--page:disabled {
  @apply cursor-not-allowed;
}
.pagination--page__hidden {
  @apply cursor-default;
}
.pagination--prev svg, .pagination--next svg {
  @apply size-6;
}
.pagination--prev svg, .pagination--next svg {
  @apply size-6;
}
</style>
