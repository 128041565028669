<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import IconX from "../../icons/IconX.vue";
export default {
  name: "ChargemapModal",
  components: {
    IconX
  },
  props: {
    size: {
      type: String,
      default: "md",
      validator(value) {
        return ["fit", "sm", "md", "lg", "xl"].includes(value);
      }
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    textCenter: {
      type: Boolean,
      default: false
    },
    showX: {
      type: Boolean,
      default: false
    },
    noScroll: {
      type: Boolean,
      default: false
    },
    blockOuterScroll: {
      type: Boolean,
      default: false
    },
    clearX: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateIsVisible", "clickedOutside"],
  setup(props, { emit }) {
    const modal = ref(null);
    onClickOutside(modal, () => {
      emit("clickedOutside");
    });
    return { modal };
  },
  data() {
    return {
      originalOverflow: ""
    };
  },
  mounted() {
    if (this.blockOuterScroll) {
      this.originalOverflow = document.body.style.overflow;
      document.body.style.overflow = "hidden";
    }
    document.addEventListener(
      "keyup",
      (event) => this.closeModalWhenEscape(event)
    );
  },
  beforeUnmount() {
    if (this.blockOuterScroll) {
      document.body.style.overflow = this.originalOverflow;
    }
    document.removeEventListener(
      "keyup",
      (event) => this.closeModalWhenEscape(event)
    );
  },
  methods: {
    closeModal() {
      this.$emit("updateIsVisible", false);
    },
    closeModalWhenEscape(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    }
  }
};
</script>

<template>
    <Teleport to="body">
        <div
            data-testid="chargemap-modal"
            class="fixed flex h-full items-center p-6 justify-center inset-0 bg-gray-600 bg-opacity-20 z-50"
        >
            <div
                ref="modal"
                class="relative bg-white rounded-2xl shadow-md overflow-auto max-h-[95vh]"
                :class="[
                    size === 'xs' &&
                        'w-full h-full md:w-[490px] md:max-h-[650px]',
                    size === 'sm' && 'w-2/3 max-w-3xl',
                    size === 'md' && 'w-11/12 md:w-4/6 max-w-3xl',
                    size === 'lg' && 'w-11/12 md:w-10/12 max-w-6xl',
                    size === 'xl' && 'w-full h-full',
                    !noPadding && 'p-6',
                    noScroll ? 'overflow-visible' : 'overflow-auto'
                ]"
            >
                <div
                    class="flex flex-col justify-between gap-6 h-full overflow-auto"
                    :class="[
                        !noPadding && 'p-1',
                        noScroll ? 'overflow-visible' : 'overflow-auto'
                    ]"
                >
                    <div
                        v-if="$slots.title"
                        class="text-xl font-medium leading-6 tracking-light text-gray-900"
                        :class="[textCenter && 'text-center']"
                    >
                        <slot name="title" />
                    </div>
                    <div
                        class="flex flex-col gap-6 h-full"
                        :class="[textCenter && 'text-center']"
                    >
                        <slot />
                    </div>
                    <div
                        v-if="$slots.actions"
                        class="flex justify-end space-x-2"
                    >
                        <slot name="actions" />
                    </div>
                </div>
                <div
                    v-if="showX"
                    data-testid="chargemap-modal-x"
                    class="absolute right-4 top-4 z-20 cursor-pointer h-10 w-10 bg-white p-1 rounded-lg text-zinc-500 hover:text-zinc-700 flex items-center justify-center transition-all duration-300 ease-in-out"
                    :class="[
                        clearX ??
                            'shadow border border-opacity-20 border-black hover:bg-gray-100'
                    ]"
                    @click="closeModal"
                >
                    <IconX class="size-6" />
                </div>
            </div>
        </div>
    </Teleport>
</template>
