<script>
export default {
  name: "IconStarFull",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        class="fill-current"
    >
        <path
            d="M16.002 25.736l8.437 4.967c0.965 0.568 2.155-0.276 1.899-1.346l-2.239-9.361 7.46-6.303c0.851-0.719 0.397-2.083-0.725-2.177l-9.82-0.826-3.839-8.821c-0.439-1.009-1.906-1.009-2.345 0l-3.839 8.821-9.824 0.826c-1.121 0.094-1.576 1.457-0.726 2.176l7.447 6.304-2.226 9.365c-0.254 1.070 0.935 1.912 1.899 1.344l8.442-4.97z"
        ></path>
    </svg>
</template>
