import type { Feature, Packages } from '~/interfaces/flags'
import { FEATURES_BY_PACKAGE } from '~/interfaces/flags'
import type { CommunityNetwork } from '~/interfaces/network'

export const getFeaturesArray = (features?: Feature[]) => {
    if (!features) {
        return []
    }

    return features.map((feature) => feature.featureName)
}

export const hasPackage = (
    communityNetwork: CommunityNetwork,
    packageName: Packages
) => {
    return getFeaturesArray(communityNetwork.features).some((feature) => {
        const packageRights = FEATURES_BY_PACKAGE[packageName]

        if (packageRights && packageRights.includes(feature)) {
            return true
        }

        return false
    })
}

export const getActivationDate = (
    features: Feature[] | undefined,
    packageName: Packages
) => {
    if (!features) {
        return undefined
    }

    const packageRights = FEATURES_BY_PACKAGE[packageName]

    const feature = features.find((feature) =>
        packageRights.includes(feature.featureName)
    )

    return feature?.dateActivated || undefined
}
