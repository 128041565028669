<script setup lang="ts">
import { computed, type ComputedRef, type PropType } from 'vue'
import { useRoute } from 'vue-router'
import { Size, BadgeColor } from '../../../interfaces/UI'
import type { Link } from '../../../interfaces/menu'
import ChargemapB2BBadge from '../global/ChargemapB2BBadge.vue'

const route = useRoute()

const emits = defineEmits(['closeMobileMenu'])

const props = defineProps({
    link: {
        type: Object as PropType<Link>,
        required: true
    },
    isSubmenu: {
        type: Boolean,
        default: false
    },
    isBig: {
        type: Boolean,
        default: false
    }
})

const isActiveRoute: ComputedRef<boolean> = computed(
    () => route?.path === props.link.url
)

function closeMobileMenu() {
    emits('closeMobileMenu')
}

const fullLink = computed(() => {
    return `${props.link.url}${props.link.params || ''}`
})
</script>

<template>
    <NuxtLink
        :id="`${link.id}`"
        :key="link.title"
        :to="fullLink"
        data-testid="menu-link"
        class="min-w-max rounded-md flex gap-3 items-center justify-between transition-all duration-300 ease-in-out"
        :class="[
            isBig ? 'px-3 py-2' : 'p-3',
            isActiveRoute ? 'bg-primary-600' : 'hover:bg-primary-600'
        ]"
        @click="closeMobileMenu"
    >
        <div class="flex relative gap-3 items-center">
            <component
                :is="link.icon"
                class="h-6 w-6 text-primary-300 icon"
                is-solid
            />
            <span
                v-if="isBig"
                class="text-sm font-semibold text-primary-100"
                :class="[isSubmenu && 'pl-7.5']"
            >
                {{ link.title }}
            </span>

            <ChargemapB2BBadge
                v-if="link.count && !isBig"
                :color="BadgeColor.PURPLE"
                :size="Size.SM"
                class="absolute -top-3 -right-5"
            >
                {{ link.count }}
            </ChargemapB2BBadge>
        </div>

        <ChargemapB2BBadge
            v-if="link.count && isBig"
            :color="BadgeColor.PURPLE"
            :size="Size.SM"
        >
            {{ link.count }}
        </ChargemapB2BBadge>
    </NuxtLink>
</template>
