<script>
import NoContribution from "../../images/NoContribution.vue";
import NoContributionWithFilters from "../../images/NoContributionWithFilters.vue";
export default {
  name: "FeedbacksNotFound",
  components: {
    NoContribution,
    NoContributionWithFilters
  },
  props: {
    isFilterNotFound: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
    <div class="flex flex-col gap-4 justify-center items-center">
        <NoContributionWithFilters
            v-if="isFilterNotFound"
            class="max-w-xs m-auto w-28"
            :alt="$t('feedbacks.no_matching_feedbacks')"
        />

        <NoContribution
            v-else
            class="max-w-xs m-auto w-48"
            :alt="$t('feedbacks.no_feedbacks')"
        />

        <div v-if="isFilterNotFound" class="flex flex-col text-center">
            <div class="font-medium">
                {{ $t('feedbacks.no_matching_feedbacks') }}
            </div>
            <div class="text-gray-550 text-sm">
                {{ $t('feedbacks.try_to_update_filters') }}
            </div>
        </div>

        <div v-else class="flex flex-col text-center">
            <div class="font-medium">
                {{ $t('feedbacks.no_feedbacks') }}
            </div>
            <div class="text-gray-550 text-sm">
                {{ $t('feedbacks.no_feedbacks_text') }}
            </div>
        </div>
    </div>
</template>
