<script>
import PoolPreview from "../mapDrawer/PoolPreview.vue";
import ChargemapDrawer from "./ChargemapDrawer.vue";
import usePoolConnectorsForDisplay from "../../../composables/usePoolConnectorsForDisplay";
export default {
  name: "ChargemapMapDrawer",
  components: {
    PoolPreview,
    ChargemapDrawer
  },
  props: {
    standalone: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    pool: {
      type: Object,
      default: void 0
    },
    feedbacks: {
      type: Array,
      required: true
    },
    poolStations: {
      type: Array,
      required: true
    },
    pictures: {
      type: Array,
      required: true
    },
    position: {
      type: String,
      default: "left"
    },
    s3Url: {
      type: String,
      required: true
    },
    isPartner: {
      type: Boolean,
      required: true
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    legacyApiUrl: {
      type: String,
      required: true
    },
    chargemapUrl: {
      type: String,
      required: true
    },
    loadingInfo: {
      type: Boolean,
      default: false
    },
    loadingFeedbacks: {
      type: Boolean,
      default: false
    },
    loadingPictures: {
      type: Boolean,
      default: false
    },
    loadingStations: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object,
      default: void 0
    },
    showPartnerFullMessage: {
      type: Boolean,
      default: false
    },
    maxFeedbacks: {
      type: Boolean,
      default: false
    },
    loadingMoreFeedbacks: {
      type: Boolean,
      default: false
    },
    cdnUrl: {
      type: String,
      default: ""
    },
    premium: {
      type: Boolean,
      default: false
    },
    hasPass: {
      type: Boolean,
      default: false
    },
    supportPhoneNumber: {
      type: String,
      default: void 0
    }
  },
  emits: [
    "openPicture",
    "reliabilityClicked",
    "messageActionClick",
    "connectorClicked",
    "openFeedbackModal",
    "updateFeedbackFilters",
    "openTariff",
    "findConnectorIdClicked",
    "changedTab",
    "loadMoreFeedbacks",
    "updateVisible",
    "orderPass",
    "goToAssistance",
    "editPool"
  ],
  data() {
    return {
      drawCloseButton: false,
      closeDrawerSwitch: false
    };
  },
  computed: {
    poolConnectorsForDisplay() {
      return usePoolConnectorsForDisplay(this.poolStations).value;
    }
  },
  methods: {
    updateDrawCloseButton(value) {
      this.drawCloseButton = value;
    },
    openPicture(index, category) {
      this.$emit("openPicture", index, category);
    },
    reliabilityClicked(payload) {
      this.$emit("reliabilityClicked", payload);
    },
    messageActionClick() {
      this.$emit("messageActionClick");
    },
    connectorClicked(connector) {
      this.$emit("connectorClicked", connector);
    },
    openFeedbackModal(feedback) {
      this.$emit("openFeedbackModal", feedback);
    },
    updateFeedbackFilters(filters) {
      this.$emit("updateFeedbackFilters", filters);
    },
    openTariff() {
      this.$emit("openTariff", this.pool?.id);
    },
    findConnectorIdClicked() {
      this.$emit("findConnectorIdClicked");
    },
    loadMoreFeedbacks() {
      this.$emit("loadMoreFeedbacks");
    },
    changedTab(tabId) {
      this.$emit("changedTab", tabId);
    },
    updateVisible(val) {
      this.$emit("updateVisible", val);
    },
    closeDrawer() {
      this.closeDrawerSwitch = true;
      this.$nextTick(() => {
        this.closeDrawerSwitch = false;
      });
    }
  }
};
</script>

<template>
    <PoolPreview
        v-if="standalone"
        :show-close-button="drawCloseButton"
        :pool-connectors="poolConnectorsForDisplay"
        :has-pass="hasPass"
        v-bind="$props"
        @editPool="$emit('editPool', $event)"
        @closeDrawer="closeDrawer"
        @goToAssistance="$emit('goToAssistance')"
        @loadMoreFeedbacks="loadMoreFeedbacks"
        @openPicture="openPicture"
        @reliabilityClicked="reliabilityClicked"
        @messageActionClick="messageActionClick"
        @connectorClicked="connectorClicked"
        @openFeedbackModal="openFeedbackModal"
        @updateFeedbackFilters="updateFeedbackFilters"
        @openTariff="openTariff"
        @findConnectorIdClicked="findConnectorIdClicked"
        @changedTab="changedTab"
        @orderPass="$emit('orderPass', $event)"
    />
    <ChargemapDrawer
        v-else
        data-testid="chargemap-map-drawer"
        :close-trigger="closeDrawerSwitch"
        :can-close
        :visible
        :position
        @update-visible="updateVisible"
        @show-close-button="updateDrawCloseButton"
    >
        <PoolPreview
            :show-close-button="drawCloseButton"
            :pool-connectors="poolConnectorsForDisplay"
            :has-pass="hasPass"
            v-bind="$props"
            @editPool="$emit('editPool', $event)"
            @closeDrawer="closeDrawer"
            @goToAssistance="$emit('goToAssistance')"
            @loadMoreFeedbacks="loadMoreFeedbacks"
            @openPicture="openPicture"
            @reliabilityClicked="reliabilityClicked"
            @messageActionClick="messageActionClick"
            @connectorClicked="connectorClicked"
            @openFeedbackModal="openFeedbackModal"
            @updateFeedbackFilters="updateFeedbackFilters"
            @openTariff="openTariff"
            @findConnectorIdClicked="findConnectorIdClicked"
            @changedTab="changedTab"
            @orderPass="$emit('orderPass', $event)"
        />
    </ChargemapDrawer>
</template>
