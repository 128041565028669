import { DateTime } from 'luxon'
import type { Features } from '~/interfaces/flags'
import useAuthStore from '~/store/auth'
import useNetworksStore from '~/store/networks'
import useSessionStore from '~/store/session'
import arraysEqual from '~/utils/arrayUtils'

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (
        to.name === 'login' ||
        to.name === 'password-reset' ||
        to.name === 'password-reset-update'
    ) {
        return
    }

    const { idPartner, idNetwork } = to.params

    if (!idPartner || !idNetwork) {
        return
    }

    const { isSuperAdmin, getFeatures, refreshTokenExpiration, refreshToken } =
        useSessionStore()
    const { refreshUserToken } = useAuthStore()
    const { currentCommunityNetwork } = storeToRefs(useNetworksStore())
    const { fetchNetwork } = useNetworksStore()

    const nuxtApp = useNuxtApp()

    if (!currentCommunityNetwork.value) {
        abortNavigation()

        return navigateTo('/networks')
    }

    const refreshTokenIsValid = computed(
        () =>
            !!refreshToken &&
            refreshTokenExpiration &&
            DateTime.now().toUnixInteger() <
                DateTime.fromISO(refreshTokenExpiration).toUnixInteger()
    )

    useAsyncData(
        `currentCommunityNetwork-${idPartner}-${currentCommunityNetwork.value.id}`,
        async () => {
            if (!currentCommunityNetwork.value) {
                return Promise.resolve(undefined)
            }

            const value = await fetchNetwork(
                idPartner as string,
                currentCommunityNetwork.value.id,
                'community'
            )

            if (value) {
                currentCommunityNetwork.value = value
            }

            return value
        },
        {
            transform(input) {
                return {
                    ...input,
                    fetchedAt: new Date()
                }
            },
            getCachedData(key) {
                const data =
                    nuxtApp.payload.data[key] || nuxtApp.static.data[key]

                // If data is not fetched yet
                if (!data) {
                    // Fetch the first time
                    return
                }

                // Is the data too old?
                const expirationDate = new Date(data.fetchedAt)
                expirationDate.setTime(
                    expirationDate.getTime() + 1 * 30 * 1000 // 30 seconds TODO: Change to 5 minutes
                )
                const isExpired = expirationDate.getTime() < Date.now()

                if (isExpired) {
                    // Refetch the data
                    return
                }

                currentCommunityNetwork.value = data

                return data
            }
        }
    )

    const currentTokenRights = getFeatures(
        Number(idPartner),
        currentCommunityNetwork.value.id
    )

    // Super admins do not have packages in tokens
    if (isSuperAdmin) {
        return
    }

    const currentPartnersRights = getFeaturesArray(
        currentCommunityNetwork.value.features
    )

    if (
        (!arraysEqual(currentTokenRights, currentPartnersRights) &&
            refreshTokenIsValid.value) ||
        !currentTokenRights
    ) {
        try {
            await refreshUserToken()
        } catch {
            abortNavigation()
            await navigateTo('/login')

            return
        }
    }

    // Super admins do not have packages in tokens
    if (to.meta.packages) {
        const packages = to.meta.packages as Features[]

        if (
            !packages.some((packageId) =>
                currentTokenRights.includes(packageId)
            )
        ) {
            useNuxtApp().$toast.error('You do not have access to this page')

            abortNavigation()

            if (from.fullPath !== to.fullPath) {
                return navigateTo(from)
            } else {
                return navigateTo('/')
            }
        }
    }
})
