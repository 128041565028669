<script>
export default {
  name: "IconRestaurant",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.20899 2.19754C9.20722 1.812 8.89413 1.5 8.50817 1.5C8.12112 1.5 7.80735 1.81377 7.80735 2.20082V6.40574C7.80735 6.59926 7.65046 6.75615 7.45694 6.75615C7.26341 6.75615 7.10653 6.59926 7.10653 6.40574V2.19618C7.10403 1.81126 6.79123 1.5 6.40572 1.5C6.01867 1.5 5.7049 1.81377 5.7049 2.20082L5.7049 8.40984C5.7049 9.2202 6.3475 9.88048 7.15094 9.90888C7.12195 10.0212 7.10653 10.1389 7.10653 10.2602V21.4734C7.10653 22.2475 7.73407 22.875 8.50817 22.875C9.28228 22.875 9.90981 22.2475 9.90981 21.4734V10.2602C9.90981 10.1389 9.89439 10.0212 9.86541 9.90888C10.6688 9.88048 11.3115 9.2202 11.3115 8.40984V2.20082C11.3115 1.81377 10.9977 1.5 10.6106 1.5C10.2236 1.5 9.90981 1.81377 9.90981 2.20082V6.40574C9.90981 6.59926 9.75292 6.75615 9.5594 6.75615C9.36587 6.75615 9.20899 6.59926 9.20899 6.40574V2.19754ZM14.4652 3.60246C14.4652 2.4413 15.4065 1.5 16.5676 1.5C17.7288 1.5 18.6701 2.4413 18.6701 3.60246V11.3115L18.6701 11.3199V21.4734C18.6701 22.2475 18.0425 22.875 17.2684 22.875C16.4943 22.875 15.8668 22.2475 15.8668 21.4734V13.2943C15.0502 13.0057 14.4652 12.2269 14.4652 11.3115V3.60246Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 4.5C3 3.11929 4.11929 2 5.5 2C6.0628 2 6.58217 2.18597 7 2.49982C7.41783 2.18597 7.9372 2 8.5 2C9.0628 2 9.58217 2.18597 10 2.49982C10.4178 2.18597 10.9372 2 11.5 2C12.8807 2 14 3.11929 14 4.5V9.00004C14 10.138 13.6352 11.1609 12.8921 11.901C12.3767 12.4144 11.7267 12.7446 11 12.8986V19.5C11 20.8807 9.88071 22 8.5 22C7.11929 22 6 20.8807 6 19.5V12.8868C5.29894 12.7233 4.66519 12.3849 4.15821 11.888C3.41598 11.1606 3 10.1465 3 9V4.5ZM12 4.5L12.0001 9.00004C12.0001 9.70878 11.78 10.1859 11.4807 10.484C11.1807 10.7828 10.7025 11 10 11H9V19.5C9 19.7761 8.77614 20 8.5 20C8.22386 20 8 19.7761 8 19.5V11H7C6.38236 11 5.89106 10.7859 5.55813 10.4597C5.22824 10.1363 5 9.65037 5 9V4.5C5 4.22386 5.22386 4 5.5 4C5.77614 4 6 4.22386 6 4.5V8C6 8.55228 6.44772 9 7 9C7.55228 9 8 8.55228 8 8V4.5C8 4.22386 8.22386 4 8.5 4C8.77614 4 9 4.22386 9 4.5V8C9 8.55228 9.44772 9 10 9C10.5523 9 11 8.55228 11 8V4.5C11 4.22386 11.2239 4 11.5 4C11.7761 4 12 4.22386 12 4.5Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.3844 13.5287C15.5521 12.9963 15 12.0642 15 11.0017V4.999C15 3.34149 16.3438 2 18 2C19.6569 2 21 3.34315 21 5V19.5C21 20.8807 19.8807 22 18.5 22C17.1196 22 16 20.8814 16 19.5004V14.9988C16 14.4644 16.1397 13.963 16.3844 13.5287ZM18 4C17.4471 4 17 4.44737 17 4.999V11.0017C17 11.5529 17.4466 12 18 12H19V5C19 4.44772 18.5523 4 18 4ZM19 14C18.4469 14 18 14.4473 18 14.9988V19.5004C18 19.7762 18.2235 20 18.5 20C18.7761 20 19 19.7761 19 19.5V14Z"
        />
    </svg>
</template>
