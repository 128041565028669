import {
    NetworkPhotoType,
    type CommunityNetwork,
    type NetworkPhotoAnswer
} from '~/interfaces/network'

export default (
    currentCommunityNetwork: Ref<CommunityNetwork | null>,
    updateNetworkPhoto: (
        photo: string | null,
        type: NetworkPhotoType
    ) => Promise<NetworkPhotoAnswer>,
    updateNetworkDescription: (
        preserveDescription: boolean,
        description?: string
    ) => Promise<unknown>
) => {
    const globalDescription = ref(
        !!currentCommunityNetwork.value?.zonesGlobalDescription
    )

    const editedDescription = ref<string | undefined>(
        currentCommunityNetwork.value?.zonesGlobalDescription
    )
    const addedIcon = ref<string | null>(null)
    const addedAvatar = ref<string | null>(null)

    const deletedIcon = ref(false)
    const deletedAvatar = ref(false)

    const cachedIcon = computed<string | undefined>(() => {
        // If the icon is considered deleted, we return undefined
        if (deletedIcon.value) {
            return undefined
        }

        // We add a cache breaker to the avatar url to force the browser to reload the image
        const cacheBreaker = performance.now()

        if (!addedIcon.value && !currentCommunityNetwork.value?.iconUrl) {
            return undefined
        }

        // We return the added icon if it exists, otherwise we return the current icon
        return (
            addedIcon.value ||
            `${currentCommunityNetwork.value?.iconUrl}?${cacheBreaker}`
        )
    })

    const cachedAvatar = computed<string | undefined>(() => {
        // If the avatar is considered deleted, we return undefined
        if (deletedAvatar.value) {
            return undefined
        }

        // We add a cache breaker to the avatar url to force the browser to reload the image
        const cacheBreaker = performance.now()

        if (!addedAvatar.value && !currentCommunityNetwork.value?.avatarUrl) {
            return undefined
        }

        // We return the added avatar if it exists, otherwise we return the current avatar
        return (
            addedAvatar.value ||
            `${currentCommunityNetwork.value?.avatarUrl}?${cacheBreaker}`
        )
    })

    const cacheUpdateIcon = (icon: string | null) => {
        // If the icon is null, we consider it as deleted
        if (icon === null) {
            addedIcon.value = null
            deletedIcon.value = true

            return
        }

        addedIcon.value = icon
        // We reset the deletedIcon value
        deletedIcon.value = false
    }

    const cachedDescription = computed<string | undefined>(() => {
        if (!globalDescription.value) {
            return undefined
        }

        return editedDescription.value !== undefined
            ? editedDescription.value
            : currentCommunityNetwork.value?.zonesGlobalDescription
    })

    const cacheUpdateAvatar = (avatar: string | null) => {
        // If the avatar is null, we consider it as deleted
        if (avatar === null) {
            addedAvatar.value = null
            deletedAvatar.value = true

            return
        }

        addedAvatar.value = avatar
        // We reset the deletedAvatar value
        deletedAvatar.value = false
    }

    const handlePublishNetwork = async () => {
        if (!currentCommunityNetwork.value) {
            return
        }

        if (deletedAvatar.value || addedAvatar.value) {
            const avatar = deletedAvatar.value ? null : addedAvatar.value

            await updateNetworkPhoto(avatar, NetworkPhotoType.AVATAR)
        }

        if (deletedIcon.value || addedIcon.value) {
            const icon = deletedIcon.value ? null : addedIcon.value

            await updateNetworkPhoto(icon, NetworkPhotoType.ICON)
        }

        if (
            (globalDescription.value &&
                currentCommunityNetwork.value.zonesGlobalDescription !==
                    editedDescription.value) ||
            globalDescription.value !==
                !!currentCommunityNetwork.value.zonesGlobalDescription
        ) {
            await updateNetworkDescription(
                globalDescription.value,
                globalDescription.value ? editedDescription.value : undefined
            )
        }

        resetCache()
    }

    const resetCache = () => {
        addedIcon.value = null
        addedAvatar.value = null
        deletedIcon.value = false
        deletedAvatar.value = false
        editedDescription.value =
            currentCommunityNetwork.value?.zonesGlobalDescription
        globalDescription.value =
            !!currentCommunityNetwork.value?.zonesGlobalDescription
    }

    const hasEditedDescription = computed<boolean>(() => {
        return (
            (globalDescription.value &&
                !!editedDescription.value &&
                editedDescription.value !==
                    currentCommunityNetwork.value?.zonesGlobalDescription) ||
            globalDescription.value !==
                !!currentCommunityNetwork.value?.zonesGlobalDescription
        )
    })

    const hasEditedInfos = computed<boolean>(() => {
        return (
            !!addedIcon.value ||
            !!addedAvatar.value ||
            deletedIcon.value ||
            deletedAvatar.value ||
            hasEditedDescription.value
        )
    })

    return {
        // Cache values
        addedIcon,
        addedAvatar,
        deletedIcon,
        deletedAvatar,
        editedDescription,
        globalDescription,

        // Methods
        cacheUpdateIcon,
        cacheUpdateAvatar,
        handlePublishNetwork,
        resetCache,

        // Computed values
        cachedIcon,
        cachedAvatar,
        cachedDescription,
        hasEditedInfos,
        hasEditedDescription
    }
}
