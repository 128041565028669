<script>
import IconInfoCircle from "../../icons/IconInfoCircle.vue";
import IconStarEmpty from "../../icons/IconStarEmpty.vue";
import IconStarFull from "../../icons/IconStarFull.vue";
import IconStarHalf from "../../icons/IconStarHalf.vue";
export default {
  name: "PoolRating",
  components: {
    IconInfoCircle,
    IconStarEmpty,
    IconStarFull,
    IconStarHalf
  },
  props: {
    rating: {
      type: [String, Number],
      required: true
    },
    displayRatingAsText: {
      type: Boolean,
      default: true
    },
    displayRatingCount: {
      type: Boolean,
      default: false
    },
    displayInfoIcon: {
      type: Boolean,
      default: false
    },
    ratingCount: {
      type: Number,
      default: 0
    },
    isRatingCountFormatted: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium"
    },
    orientation: {
      type: String,
      default: "horizontal"
    },
    responsive: {
      type: Boolean,
      default: false
    },
    textSize: {
      type: String,
      default: void 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fullStars() {
      if (this.rating === "") return 0;
      const value = typeof this.rating === "string" ? parseFloat(this.rating) : this.rating;
      return this.rating ? Math.min(5, Math.floor(value)) : 0;
    },
    emptyStars() {
      const halfStar = this.hasAHalfStar ? 1 : 0;
      return this.rating ? 5 - this.fullStars - halfStar * 1 : 5;
    },
    hasAHalfStar() {
      if (this.rating === "") return false;
      const value = typeof this.rating === "string" ? parseFloat(this.rating) : this.rating;
      return this.rating ? value - this.fullStars >= 0.5 : false;
    },
    displayRating() {
      if (this.rating === "") return "";
      const value = typeof this.rating === "string" ? parseFloat(this.rating) : this.rating;
      return this.$n(value, {
        maximumFractionDigits: 1
      });
    },
    getRatingCountText() {
      if (!this.isRatingCountFormatted || this.ratingCount < 1e3) {
        return `${this.$t("reviews.count", this.ratingCount)}`;
      }
      const countInThousands = (this.ratingCount / 1e3).toFixed(1).replace(".0", "");
      return `${countInThousands}k`;
    }
  }
};
</script>

<template>
    <span
        class="pool-rating"
        :class="[orientation === 'horizontal' ? 'flex-row' : 'flex-col gap-1']"
        data-testid="pool-rating"
    >
        <span class="stars">
            <IconStarFull
                v-for="i in fullStars"
                :key="i"
                class="icon"
                :class="[
                    size,
                    responsive && 'responsive',
                    disabled ? '!text-zinc-300' : 'text-light-blue-primary'
                ]"
            />
            <IconStarHalf
                v-if="hasAHalfStar"
                class="icon"
                :class="[
                    size,
                    responsive && 'responsive',
                    disabled ? '!text-zinc-300' : 'text-light-blue-primary'
                ]"
            />
            <IconStarEmpty
                v-for="i in emptyStars"
                :key="i"
                class="icon"
                :class="[
                    size,
                    responsive && 'responsive',
                    disabled ? '!text-zinc-300' : 'text-gray-comment'
                ]"
            />
        </span>
        <div class="flex flex-row">
            <span
                v-if="displayRatingAsText"
                class="rating"
                :class="[
                    orientation === 'horizontal' ? 'ml-1.5' : 'ml-0',
                    size === 'small' && !textSize
                        ? 'text-xs font-medium'
                        : textSize,
                    disabled ? '!text-zinc-300' : 'text-gray-comment'
                ]"
            >
                {{ displayRating }}
            </span>

            <span
                v-if="displayRatingCount"
                class="ratingsCount font-normal"
                :class="[
                    size === 'small' ? 'text-xs font-normal' : '',
                    disabled ? '!text-zinc-300' : 'text-gray-comment'
                ]"
            >
                ({{ getRatingCountText }})
            </span>
            <IconInfoCircle
                v-if="displayInfoIcon"
                class="ml-1.5 text-zinc-500 h-4"
            />
        </div>
    </span>
</template>

<style scoped>
.pool-rating {
  display: flex;
  align-items: center;
}
.pool-rating .stars {
  display: flex;
  align-items: center;
  gap: 2px;
}
.pool-rating .stars .icon.extra-small {
  @apply h-5;
}
.pool-rating .stars .icon.small {
  @apply h-6;
}
.pool-rating .stars .icon.small.responsive {
  @apply md:h-6 h-5;
}
.pool-rating .stars .icon.medium {
  @apply h-[30px];
}
.pool-rating .stars .icon.medium.responsive {
  @apply md:h-[30px] h-6;
}
.pool-rating .stars .icon.big {
  @apply h-9;
}
.pool-rating .stars .icon.big.responsive {
  @apply md:h-9 h-8;
}
.pool-rating .rating {
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0080c5;
  margin-left: 6px;
}
.pool-rating .ratingsCount {
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #a1a1aa;
  margin-left: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
