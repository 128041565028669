import { defineStore } from 'pinia'
import useSessionStore from './session'

const usePoolsStore = defineStore('pools', {
    state: () => ({}),
    actions: {
        async getPricingPool(poolId: number) {
            const { $api, $toCamel, $throwError } = useNuxtApp()

            const locale = useSessionStore().user?.locale || 'en'

            return $api
                .get(`/pool-pricy-api/v3/prices/${poolId}?locale=${locale}`)
                .then((response) => $toCamel(response.data))
                .catch((error: any) => {
                    if (
                        error.response.status !== 404 ||
                        !error.response?.data
                    ) {
                        $throwError(error, `cannot get pricing pool`)
                    } else if (error.response?.data) {
                        return $toCamel(error.response.data)
                    }
                })
        }
    },
    persist: {
        storage: localStorage
    }
})

export default usePoolsStore
