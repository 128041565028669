<script lang="ts">
import { mapActions } from 'pinia'
import IconLock from '~/components/UI/icons/IconLock.vue'
import useAuthStore from '~/store/auth'

export default {
    components: { IconLock },
    setup() {
        definePageMeta({
            layout: 'clean'
        })
    },
    data: () => ({
        title: 'Reset password',
        userId: 0,
        password: '',
        passwordConfirm: '',
        invalidKey: false,
        error: '',
        subError: '',
        isLoading: false
    }),
    head() {
        return {
            title: this.title
        }
    },
    computed: {
        isValid(): boolean {
            return (
                this.password === this.passwordConfirm &&
                this.password.length > 7
            )
        },
        key(): string {
            return this.$route.query.key as string
        }
    },
    mounted() {
        setTimeout(() => {
            this.checkKey()
        }, 100)
    },
    methods: {
        ...mapActions(useAuthStore, [
            'updatePasswordReset',
            'checkPasswordResetKey'
        ]),
        controlPassword(): boolean {
            if (
                this.passwordConfirm &&
                this.password !== this.passwordConfirm
            ) {
                this.subError = this.$t('passwordReset.error.match')
            } else if (this.password && this.password.length < 8) {
                this.subError = this.$t('passwordReset.error.length')
            } else {
                this.subError = ''
            }

            return !this.subError
        },
        goBackToLogin() {
            this.$router.push('/login')
        },
        checkKey() {
            return this.checkPasswordResetKey(this.key)
                .then((response: any) => {
                    this.userId = response.data.user_id
                })
                .catch((_: any) => {
                    this.$router.push({
                        path: '/password-reset',
                        query: { error: 'keyExpired' }
                    })
                })
        },
        onSubmit(this: any): Promise<void> {
            this.error = ''
            this.isLoading = true

            if (!this.isValid) {
                this.isLoading = false

                return Promise.resolve()
            }

            return this.updatePasswordReset({
                password: this.password,
                userId: this.userId,
                key: this.key
            })
                .then((_: any) => {
                    this.$router.push({
                        path: '/login',
                        query: { success: 'passwordReset' }
                    })
                })
                .catch((_: any) => {
                    this.error = 'passwordUpdateError'
                    this.password = ''
                    this.passwordConfirm = ''
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<template>
    <div class="max-w-md w-full" data-testid="password-reset-update">
        <div class="text-center">
            <h2
                class="text-center text-2xl leading-9 font-semibold text-gray-800"
            >
                {{ $t('passwordReset.resetPasswordTitle') }}
            </h2>
        </div>
        <form
            id="update-form"
            class="mt-18 flex flex-col gap-9"
            @submit.prevent="onSubmit"
        >
            <ChargemapAlert
                v-if="!!error"
                id="password-update-error"
                class="w-full"
                identifier="password_update_error"
                data-testid="password-update-alert-error"
                type="error"
            >
                <template #title>
                    {{ $t(`passwordReset.error.${error}`) }}
                </template>
            </ChargemapAlert>

            <input type="hidden" name="remember" value="true" />
            <div class="rounded-md shadow-sm">
                <div class="flex flex-col gap-9">
                    <ChargemapInput
                        v-model="password"
                        :aria-label="$t('login.password')"
                        name="password"
                        type="password"
                        minlength="8"
                        required
                        :placeholder="$t('passwordReset.newPassword')"
                        type-input="password"
                        :is-valid="!!controlPassword()"
                    />
                    <ChargemapInput
                        v-model="passwordConfirm"
                        :aria-label="$t('passwordReset.passwordConfirmation')"
                        name="password_confirm"
                        type="password"
                        minlength="8"
                        required
                        :placeholder="$t('passwordReset.passwordConfirmation')"
                        type-input="password"
                        :is-valid="!!controlPassword()"
                        :error-message="subError"
                    />
                </div>
            </div>

            <div class="flex flex-col gap-4">
                <ChargemapButton
                    :disabled="!isValid"
                    :is-loading="isLoading"
                    btn-type="submit"
                    btn-style="relative w-full flex justify-center items-center button-sm hover:text-white"
                >
                    <IconLock class="mr-2" />
                    {{ $t('passwordReset.reset') }}
                </ChargemapButton>
                <ChargemapButton
                    btn-alt="clear"
                    :is-loading="isLoading"
                    btn-style="w-full flex justify-center button-sm"
                    @click="goBackToLogin"
                >
                    {{ $t('passwordReset.backToLogin') }}
                </ChargemapButton>
            </div>
        </form>
    </div>
</template>
