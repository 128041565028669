<script>
export default {
  name: "IconChargingBadge",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.7999 3.59998C3.47442 3.59998 2.3999 4.67449 2.3999 5.99998V18C2.3999 19.3255 3.47442 20.4 4.7999 20.4H19.1999C20.5254 20.4 21.5999 19.3255 21.5999 18V5.99998C21.5999 4.67449 20.5254 3.59998 19.1999 3.59998H4.7999ZM12.0001 16.8C14.6508 16.8 16.7999 14.6509 16.7999 12C16.7999 9.34881 14.6509 7.19998 12.0001 7.19998C9.34898 7.19998 7.1999 9.34874 7.1999 12C7.1999 14.6509 9.34912 16.8 12.0001 16.8Z"
        />
        <path
            d="M11.4 18V15C10.3868 15 9.5 14.4 9.5 13.2C9.50018 12.6 9.50065 12.23 9.50029 11.63H14.5C14.5005 12.23 14.5004 12.6 14.5004 13.2C14.5004 14.4 13.6703 15 12.6 15V18H11.4Z"
        />
        <path
            d="M10.2 10.2C10.2 9.86859 10.4686 9.59997 10.8 9.59997C11.1314 9.59997 11.4 9.86859 11.4 10.2V12.6C11.4 12.9313 11.1314 13.2 10.8 13.2C10.4686 13.2 10.2 12.9313 10.2 12.6V10.2Z"
        />
        <path
            d="M12.6 10.2C12.6 9.86859 12.8686 9.59997 13.2 9.59997C13.5314 9.59997 13.8 9.86859 13.8 10.2V12.6C13.8 12.9313 13.5314 13.2 13.2 13.2C12.8686 13.2 12.6 12.9313 12.6 12.6V10.2Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 4C4.93913 4 3.92172 4.42143 3.17157 5.17157C2.42143 5.92172 2 6.93913 2 8V16C2 17.0609 2.42143 18.0783 3.17157 18.8284C3.92172 19.5786 4.93913 20 6 20H18C19.0609 20 20.0783 19.5786 20.8284 18.8284C21.5786 18.0783 22 17.0609 22 16V8C22 6.93913 21.5786 5.92172 20.8284 5.17157C20.0783 4.42143 19.0609 4 18 4H6ZM4.58579 17.4142C4.21071 17.0391 4 16.5304 4 16V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H18C18.5304 6 19.0391 6.21071 19.4142 6.58579C19.7893 6.96086 20 7.46957 20 8V16C20 16.5304 19.7893 17.0391 19.4142 17.4142C19.0391 17.7893 18.5304 18 18 18H6C5.46957 18 4.96086 17.7893 4.58579 17.4142Z"
        />
        <path
            d="M11 18V15C10.1557 15 9 14 9 13C9.00015 12.5 9.00039 11 9.00009 10.5H9.5V8.75C9.5 8.33579 9.83579 8 10.25 8C10.6642 8 11 8.33579 11 8.75V10.5H13V8.75C13 8.33579 13.3358 8 13.75 8C14.1642 8 14.5 8.33579 14.5 8.75V10.5H14.9995C15 11 15 12.5 15 13C15 14 13.892 15 13 15V18H11Z"
        />
    </svg>
</template>
