import { defineStore } from 'pinia'
import {
    PhotoCategory,
    type Photo,
    type PhotoWithoutURL,
    type PoolPhoto
} from '#cmui/types'
import validateParams from './utils/validateParams'
import useStationStore from './station'
import useNetworksStore from './networks'

const usePhotoStore = defineStore(
    'photo',
    () => {
        const networkStore = useNetworksStore()

        const { currentCommunityNetwork } = storeToRefs(networkStore)

        const { $throwError } = useNuxtApp()

        const loadingPhotos = ref(false)
        const stationPhotos = ref<Photo[]>([])

        const getStationPhotos = async (stationId: string | number) => {
            if (
                typeof stationId === 'string' &&
                useStationStore().currentStation?.id !== parseInt(stationId, 10)
            ) {
                resetCache()
            }

            loadingPhotos.value = true

            return $fetchApi<PoolPhoto[]>(`/community-photo-api/photos/pools`, {
                params: {
                    poolId: stationId
                }
            })
                .then((response) => {
                    stationPhotos.value = response

                    return response
                })
                .catch((error) => {
                    $throwError(error, 'cannot get photos')

                    return Promise.reject(error)
                })
                .finally(() => {
                    loadingPhotos.value = false
                })
        }

        const movePhotosFromCategory = (
            photos: Photo[],
            category: PhotoCategory
        ) => {
            const { idPartner, stationId } = useRoute().params

            validateParams({ idPartner, stationId })

            const photosWithoutUrl: PhotoWithoutURL[] = photos.map((photo) => ({
                id: photo.id,
                category: photo.category
            }))

            const networkQuery = useNetworkQueryBuilder(true)

            return $fetchApi(
                `/partners-api/partners/${idPartner}/${networkQuery}/zones/${stationId}/photos`,
                {
                    method: 'PATCH',
                    body: { photos: photosWithoutUrl, newCategory: category }
                }
            )
                .then(() => {
                    stationPhotos.value = stationPhotos.value.map((photo) => {
                        // We update the category of the photos that have been moved
                        if (photos.some((p) => p.id === photo.id)) {
                            return { ...photo, category }
                        }

                        return photo
                    })
                })
                .catch((error) => {
                    $throwError(
                        error,
                        `cannot move category ${JSON.stringify(photos.map((p) => p.id))}`
                    )

                    return Promise.reject(error)
                })
        }

        const addPhotosByCategory = (
            photosContent: string[],
            category: PhotoCategory
        ) => {
            if (!currentCommunityNetwork.value) {
                return Promise.reject()
            }

            const { idPartner, stationId } = useRoute().params

            validateParams({ idPartner, stationId })

            const networkQuery = useNetworkQueryBuilder(true)

            return $fetchApi<Photo[]>(
                `/partners-api/partners/${idPartner}/${networkQuery}/zones/${stationId}/photos/batch`,
                {
                    method: 'POST',
                    body: { photos: photosContent, category }
                }
            )
                .then((response) => {
                    // We remove the old cover if there was one
                    if (category === PhotoCategory.COVER) {
                        stationPhotos.value = stationPhotos.value.filter(
                            (photo) => photo.category !== PhotoCategory.COVER
                        )
                    }

                    stationPhotos.value = [...stationPhotos.value, ...response]

                    return response
                })
                .catch((error) => {
                    $throwError(error, 'cannot add photos')

                    return Promise.reject(error)
                })
        }

        const deletePhotos = (photos: Photo[]) => {
            const { idPartner, stationId } = useRoute().params

            validateParams({ idPartner, stationId })

            const photosWithoutUrl: PhotoWithoutURL[] = photos.map((photo) => ({
                id: photo.id,
                category: photo.category
            }))

            const networkQuery = useNetworkQueryBuilder(true)

            return $fetchApi(
                `/partners-api/partners/${idPartner}/${networkQuery}/zones/${stationId}/photos/batch`,
                {
                    method: 'DELETE',
                    body: { photos: photosWithoutUrl }
                }
            )
                .then(() => {
                    stationPhotos.value = stationPhotos.value.filter(
                        (photo) => !photos.some((p) => p.id === photo.id)
                    )
                })
                .catch((error) => {
                    $throwError(
                        error,
                        `cannot delete photos ${JSON.stringify(photos.map((p) => p.id))}`
                    )

                    return Promise.reject(error)
                })
        }

        const {
            // Cache handling
            cacheMovePhotos,
            cacheDeletePhotos,
            cacheAddCoverPhoto,
            cacheAddPhotos,
            resetCache,
            handlePublishPhotos,

            // Cache computed values
            hasEditedPhotos,
            cachedPhotos,
            coverPhoto,
            cachedAllPhotos,

            // Cached values
            photosToMove,
            photosToAdd,
            photosToDelete
        } = usePhotoCache(
            stationPhotos,
            movePhotosFromCategory,
            addPhotosByCategory,
            deletePhotos
        )

        const reset = () => {
            resetCache()
            stationPhotos.value = []
        }

        return {
            loadingPhotos,
            coverPhoto,
            stationPhotos,
            getStationPhotos,
            addPhotosByCategory,
            movePhotosFromCategory,
            deletePhotos,

            // Cache handling
            cacheMovePhotos,
            cacheDeletePhotos,
            cacheAddPhotos,
            cacheAddCoverPhoto,
            handlePublishPhotos,
            resetCache,

            // Cache computed values
            cachedPhotos,
            cachedAllPhotos,
            hasEditedPhotos,

            // This is so the values are stored in the localStorage
            photosToMove,
            photosToAdd,
            photosToDelete,

            reset
        }
    },
    {
        persist: {
            storage: localStorage
        }
    }
)

type PhotoStore = Omit<
    ReturnType<typeof usePhotoStore>,
    keyof ReturnType<typeof defineStore>
>

export default usePhotoStore

export type { PhotoStore }
