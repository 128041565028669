<script lang="ts">
import { mapActions } from 'pinia'
import useAuthStore from '~/store/auth'

export default {
    setup() {
        definePageMeta({
            layout: 'login'
        })
    },
    data: () => ({
        title: '',
        email: '',
        error: '',
        sent: false,
        isLoading: false
    }),
    head() {
        return {
            title: this.title
        }
    },
    mounted() {
        this.title = this.$t('passwordReset.resetPasswordTitle')
        this.error = (this.$route.query.error as string) || ''
    },
    methods: {
        ...mapActions(useAuthStore, ['sendPasswordReset']),
        cancel() {
            this.$router.push('/login')
        },
        resetState() {
            this.sent = false
            this.error = ''
        },
        onSubmit(this: any) {
            this.isLoading = true
            this.resetState()

            return this.sendPasswordReset(this.email)
                .then((_: any) => {
                    this.sent = true
                })
                .catch((e: any) => {
                    if (e.response?.status === 500 || !e.response) {
                        this.error = '500'
                        this.$throwError(e, 'cannot send password reset')
                    } else {
                        this.error = 'unknownAccount'
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    }
}
</script>

<template>
    <div class="max-w-md w-full" data-testid="password-reset">
        <div class="text-center">
            <h2
                class="text-center text-2xl leading-9 font-semibold text-gray-800"
            >
                {{ $t('passwordReset.title') }}
            </h2>
            <p class="mt-4 text-gray-700">{{ $t('passwordReset.desc') }}</p>
        </div>
        <form
            id="reset-form"
            class="mt-18 flex flex-col gap-9"
            @submit.prevent="onSubmit"
        >
            <ChargemapAlert
                v-if="!!error"
                id="password-reset-error"
                class="w-full"
                identifier="password_reset_error"
                data-testid="password-reset-alert-error"
                type="error"
            >
                <template #title>
                    {{ $t(`passwordReset.error.${error}`) }}
                </template>
            </ChargemapAlert>

            <ChargemapAlert
                v-if="!!sent"
                id="password-reset-sent"
                class="w-full"
                identifier="password_reset_sent"
                data-testid="password-reset-alert-sent"
                type="validate"
            >
                <template #title>{{ $t('passwordReset.sent') }}</template>
            </ChargemapAlert>

            <div class="relative rounded-md">
                <ChargemapInput
                    id="email"
                    v-model="email"
                    type-input="email"
                    :is-valid="!error"
                    :aria-label="$t('passwordReset.emailAddress')"
                    :placeholder="$t('passwordReset.emailAddress')"
                    name="email"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    required
                />
            </div>

            <div class="flex flex-col gap-4">
                <ChargemapButton
                    btn-type="submit"
                    :is-loading="isLoading"
                    btn-style="flex-grow justify-center button-sm"
                    class="w-full"
                >
                    {{ $t('passwordReset.send') }}
                </ChargemapButton>

                <ChargemapButton
                    id="btn-cancel"
                    :is-loading="isLoading"
                    btn-type="button"
                    btn-alt="clear"
                    btn-style="flex-grow justify-center button-sm"
                    class="w-full"
                    @click="cancel"
                >
                    {{ $t('passwordReset.backToLogin') }}
                </ChargemapButton>
            </div>
        </form>
    </div>
</template>
