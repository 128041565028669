<script setup lang="ts">
import { Features } from '~/interfaces/flags'
import type { Menu } from '~/interfaces/menu'
import useEngagementsStore from '~/store/engagements'
import useNetworksStore from '~/store/networks'
import usePartnersStore from '~/store/partners'
import useSessionStore from '~/store/session'

defineProps({
    isBig: {
        type: Boolean,
        default: true
    }
})

const { t } = useI18n()

defineEmits(['selected', 'closeMobileMenu'])

const { isSuperAdmin } = storeToRefs(useSessionStore())
const { currentPartner } = storeToRefs(usePartnersStore())

const { currentNetwork, currentCommunityNetwork } =
    storeToRefs(useNetworksStore())

const partnerLink = computed(() => `/partners/${currentPartner.value?.id}`)

const networkLink = computed(() => {
    let url = `/partners/${currentPartner.value?.id}`

    if (currentNetwork.value) {
        url += `/networks/interop/${currentNetwork.value.id}`
    } else if (currentCommunityNetwork.value) {
        url += `/networks/community/${currentCommunityNetwork.value.id}`
    }

    return url
})

const menuSections = computed<Menu[]>(() => {
    const sections: Menu[] = [
        {
            id: 'partner-pages',
            links: [
                {
                    id: 'menu-partners',
                    title: t('appMenu.admin.partners'),
                    icon: 'IconChargemapPartners',
                    url: `/partners`,
                    authorized: isSuperAdmin.value
                }
            ],
            authorized: isSuperAdmin.value
        }
    ]

    if (currentCommunityNetwork.value || currentNetwork.value) {
        sections.push({
            id: 'networks-pages',
            links: [
                {
                    id: 'menu-data',
                    title: t('appMenu.user.data'),
                    icon: 'IconRectangleGroup',
                    url: `${networkLink.value}/data`,
                    authorized: true
                },
                {
                    id: 'menu-inbox',
                    title: t('appMenu.user.inbox'),
                    icon: 'IconInboxArrowDown',
                    count: useEngagementsStore().unreadFeedbackCount,
                    url: `${networkLink.value}/inbox`,
                    params: '?type=WITH_COMMENT%7CUNREAD',
                    authorized: true
                },
                {
                    id: 'menu-stations',
                    title: t('appMenu.user.stations'),
                    icon: 'IconChargingStation',
                    url: `${networkLink.value}/stations`,
                    authorized: checkFeaturesInPackages([
                        Features.NETWORK_BRANDING,
                        Features.ZONE_BRANDING
                    ])
                }
            ],
            authorized: true
        })
    }

    if (currentPartner.value) {
        sections.push({
            id: 'parameters',
            links: [
                {
                    id: 'menu-partner-parameters',
                    title: t('appMenu.user.parametersPartner'),
                    icon: 'IconCog',
                    url: `${partnerLink.value}/parameters`,
                    authorized: true
                }
            ],
            authorized: true
        })
    }

    return sections
})

const checkFeaturesInPackages = (features: Features[]) => {
    if (!currentPartner.value?.id || !currentCommunityNetwork.value) {
        return false
    }

    if (currentCommunityNetwork.value.features) {
        return features.some((feature) =>
            currentCommunityNetwork.value!.features.some(
                (f) => f.featureName === feature
            )
        )
    }

    return false
}
</script>

<template>
    <ChargemapMenuSection
        v-for="section in menuSections"
        :key="section.id"
        data-testid="menu-user-section"
        :list-links="section.links"
        :isBig
        :authorized="section.authorized"
    />
</template>
