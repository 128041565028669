<script setup lang="ts">
import useSessionStore from '~/store/session'
import useNetworksStore from '~/store/networks'
import useMenuStore from '~/store/menu'
import MenuUserSection from './MenuUserSection.vue'

const { t, n } = useI18n()

const { user } = storeToRefs(useSessionStore())
const menuStore = useMenuStore()

const { currentNetwork, currentCommunityNetwork } =
    storeToRefs(useNetworksStore())

const selectedNetwork = computed(() => {
    return currentNetwork.value || currentCommunityNetwork.value
})

const route = useRoute()

const isBig = ref(true as boolean)

const toggleMenuSize = () => {
    isBig.value = !isBig.value
}

const isCurrentRoute = (routeName: string) => routeName === route.name

const closeMobileMenu = () => {
    menuStore.toggleMobileMenuOpen()
}

const ratingToolTip = computed(() => {
    return t('appMenu.ratings.on_chargemap', {
        rating: selectedNetwork.value?.rating
            ? n(selectedNetwork.value.rating, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
              })
            : 0
    })
})
</script>

<template>
    <ChargemapMenu
        v-if="user"
        logo-name="LogoPartnersMenu"
        :user
        is-partner
        @closeMobileMenu="closeMobileMenu"
        @updateSize="toggleMenuSize"
    >
        <template #context-actions>
            <NuxtLink
                class="p-2 hover:bg-primary-600 border border-primary-500 text-primary-100 rounded-lg justify-start items-center gap-2 inline-flex w-full"
                to="/networks"
                :class="[
                    isCurrentRoute('networks')
                        ? 'bg-primary-600'
                        : 'bg-primary-700'
                ]"
            >
                <div
                    class="bg-primary-500 rounded-[3px] overflow-hidden flex-col justify-center items-center gap-2 inline-flex shrink-0"
                    :class="[
                        isBig ? 'size-10' : 'size-8',
                        {
                            'p-2': !currentCommunityNetwork?.avatarUrl
                        }
                    ]"
                >
                    <NuxtImg
                        v-if="currentCommunityNetwork?.avatarUrl"
                        class="size-full"
                        :src="currentCommunityNetwork?.avatarUrl"
                    />
                    <IconBuildingOffice
                        v-else
                        class="relative text-primary-50"
                        :class="[isBig ? 'size-6' : 'size-4']"
                        is-solid
                    />
                </div>
                <div
                    v-if="isBig && selectedNetwork"
                    class="flex-1 flex-col justify-start items-start inline-flex overflow-hidden"
                >
                    <ChargemapTooltip
                        class="w-full overflow-hidden"
                        placement="top"
                        :text="selectedNetwork.name"
                    >
                        <div
                            class="w-full text-base font-semibold leading-normal whitespace-nowrap text-ellipsis overflow-hidden"
                        >
                            {{ selectedNetwork.name }}
                        </div>
                    </ChargemapTooltip>
                    <ChargemapTooltip
                        placement="bottom"
                        :disabled="!selectedNetwork.rating"
                        :text="ratingToolTip"
                    >
                        <div
                            class="self-stretch text-xs font-normal leading-[18px] whitespace-nowrap"
                        >
                            {{
                                selectedNetwork.rating
                                    ? `${$n(selectedNetwork.rating, {
                                          maximumFractionDigits: 1
                                      })}/5 | `
                                    : ''
                            }}{{ selectedNetwork?.zonesCount }} stations
                        </div>
                    </ChargemapTooltip>
                </div>
                <div
                    v-else-if="isBig && !selectedNetwork"
                    class="flex-1 text-xs"
                >
                    {{ $t('appMenu.networks.select') }}
                </div>

                <IconArrowRight
                    v-if="isBig"
                    class="size-4 relative shrink-0 text-primary-50 mr-1"
                />
            </NuxtLink>
        </template>
        <template #default="slotProps">
            <MenuUserSection :is-big="slotProps.isBig" />
        </template>
    </ChargemapMenu>
</template>
