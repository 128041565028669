<script>
export default {
  name: "LogoOps",
  props: {
    link: {
      type: String,
      default: "/"
    }
  }
};
</script>

<template>
    <nuxt-link
        :to="link"
        class="flex justify-center items-center chargemap-logo"
    >
        <svg
            width="166"
            height="39"
            viewBox="0 0 166 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M57.0909 15.5217C57.0909 17.6557 55.0396 18.7226 50.9369 18.7226C46.8342 18.7226 44.7815 17.6557 44.7787 15.5217V3.70093C44.7787 1.56698 46.8314 0.5 50.9369 0.5C55.0424 0.5 57.0937 1.56698 57.0909 3.70093V5.66266H53.3985V4.95694C53.3985 3.97398 52.578 3.4825 50.9369 3.4825C49.2958 3.4825 48.4753 3.97398 48.4753 4.95694V14.5093C48.4753 15.3495 49.2944 15.7401 50.9369 15.7401C52.5794 15.7401 53.3985 15.3201 53.3985 14.5093V13.5474H57.0909V15.5217Z"
                fill="white"
            />
            <path
                d="M58.6956 0.731018H62.388V6.24234C63.455 5.28878 63.7743 5.4022 65.778 5.4022C68.5463 5.4022 70.0249 6.48177 70.0249 8.61573V18.4664H66.0847V9.6029C66.0847 8.76276 65.5092 8.37209 64.3582 8.37209C63.0433 8.37209 62.388 8.79216 62.388 9.6029V18.4664H58.6956V0.731018Z"
                fill="white"
            />
            <path
                d="M77.229 5.42354C74.0071 5.42354 72.1462 6.4149 72.0118 8.39343L75.7042 9.45201C75.7798 8.73789 76.3763 8.37663 77.3845 8.37663C78.4935 8.37663 79.2538 8.7967 79.2538 9.60743C79.2538 10.3258 78.0776 10.5442 77.0064 10.5442C73.5618 10.5442 71.6211 11.6406 71.6211 13.8417V15.522C71.6211 17.656 72.9947 18.5969 75.7168 18.6431C78.0314 18.6851 78.2372 18.7019 79.2538 17.8282C79.2538 18.1769 79.5814 18.4751 80.2367 18.4751H82.9378V8.62447C82.963 6.49052 80.5476 5.42354 77.229 5.42354ZM79.279 14.6651C79.279 15.5556 78.2792 15.9799 77.1408 15.9799C76.0024 15.9799 75.3387 15.7825 75.3387 14.6987V14.4256C75.3387 13.4469 76.1494 13.1276 77.1324 12.9974C77.8549 12.8672 78.17 12.9974 79.279 12.7622V14.6651Z"
                fill="white"
            />
            <path
                d="M146.515 5.42354C143.289 5.42354 141.433 6.4149 141.298 8.39343L144.986 9.45201C145.062 8.73789 145.658 8.37663 146.667 8.37663C147.776 8.37663 148.54 8.7967 148.54 9.60743C148.54 10.3258 147.364 10.5442 146.293 10.5442C142.844 10.5442 140.903 11.6406 140.903 13.8417V15.522C140.903 17.656 142.281 18.5969 145.003 18.6431C147.314 18.6851 147.524 18.7019 148.54 17.8282C148.54 18.1769 148.868 18.4751 149.523 18.4751H152.233V8.62447C152.233 6.49052 149.83 5.42354 146.515 5.42354ZM148.565 14.6651C148.565 15.5556 147.561 15.9799 146.427 15.9799C145.293 15.9799 144.625 15.7825 144.625 14.6987V14.4256C144.625 13.4469 145.432 13.1276 146.419 12.9974C147.141 12.8672 147.456 12.9974 148.565 12.7622V14.6651Z"
                fill="white"
            />
            <path
                d="M103.353 5.62501V6.26351C102.358 5.49058 102.513 5.36456 99.5432 5.42337C96.9933 5.49058 95.7205 6.49035 95.7205 8.62431V15.5219C95.7205 17.6558 96.9807 18.6724 99.5432 18.7228C102.185 18.7732 102.307 18.7984 103.353 17.9037V18.4161V18.9832C103.353 19.7226 102.828 20.2056 101.383 20.2056C99.9666 20.2491 98.5492 20.162 97.1488 19.9452V22.7933C97.1488 22.7933 97.8461 23.0705 101.144 23.0705C105.567 23.0705 107.025 21.6129 107.025 19.8696V5.5872L103.353 5.62501ZM103.353 14.5557C103.353 15.3958 102.698 15.7865 101.383 15.7865C100.068 15.7865 99.413 15.3664 99.413 14.5557V9.63248C99.413 8.79234 100.068 8.40167 101.383 8.40167C102.698 8.40167 103.353 8.82174 103.353 9.63248V14.5557Z"
                fill="white"
            />
            <path
                d="M122.122 5.61237H125.819V6.24668C126.73 5.51155 126.789 5.40654 128.704 5.40654C130.62 5.40654 131.271 5.48215 132.17 6.24668C133.317 5.30572 133.317 5.40654 136.14 5.40654C138.353 5.40654 139.458 6.47352 139.458 8.60747V18.4581H135.766V9.84668C135.766 8.85811 135.241 8.36523 134.191 8.36803C133.14 8.37083 132.615 8.86371 132.615 9.84668V18.4623H129.019V9.84668C129.019 8.85811 128.486 8.36523 127.419 8.36803C126.352 8.37083 125.819 8.86371 125.819 9.84668V18.4623H122.122V5.61237Z"
                fill="white"
            />
            <path
                d="M157.929 5.55798V6.2343C159.105 5.28914 159.235 5.41516 161.747 5.43616C164.259 5.45717 165.561 6.48634 165.561 8.62029V15.5472C165.561 17.6812 164.301 18.702 161.747 18.7482C159.075 18.7944 158.945 18.807 157.929 17.908V23.0959H154.232V5.55798H157.929ZM159.899 15.7909C161.209 15.7909 161.869 15.2994 161.869 14.3164V9.8511C161.869 8.86393 161.209 8.37245 159.899 8.37245C158.588 8.37245 157.929 8.86393 157.929 9.8511V14.3164C157.929 15.2994 158.585 15.7909 159.899 15.7909Z"
                fill="white"
            />
            <path
                d="M116.371 14.6819C116.296 15.4044 115.813 15.7699 114.578 15.7699C113.267 15.7699 112.607 15.3498 112.607 14.5391V13.3083H120.244V8.62447C120.244 6.49052 118.355 5.42354 114.578 5.42354C110.8 5.42354 108.912 6.49052 108.915 8.62447V15.522C108.915 17.656 110.802 18.723 114.578 18.723C118.22 18.723 119.938 17.7274 120.068 15.7405L116.371 14.6819ZM112.591 9.61164C112.591 8.7715 113.25 8.38083 114.561 8.38083C115.871 8.38083 116.531 8.8009 116.531 9.61164V10.8424H112.591V9.61164Z"
                fill="white"
            />
            <path
                d="M91.1838 9.94303L94.8762 8.88445V8.62401C94.8762 6.49005 93.8261 5.46088 91.7257 5.41047C89.6254 5.36006 89.4951 5.46508 88.571 6.25061V5.62471H84.8785V18.4746H88.571V9.61117C88.571 8.77103 88.991 8.38037 89.8774 8.38037C90.7637 8.38037 91.1838 8.80044 91.1838 9.61117V9.94303Z"
                fill="white"
            />
            <path
                d="M84.009 35.5921H89.1628C90.529 35.5921 91.5963 35.2562 92.3645 34.5842C92.7552 34.2286 93.0622 33.7907 93.2632 33.3021C93.4642 32.8135 93.5543 32.2863 93.527 31.7587C93.5278 31.2037 93.4134 30.6546 93.191 30.1461C92.9447 29.6105 92.5448 29.1603 92.042 28.8526C92.3627 28.5647 92.6152 28.2089 92.7811 27.8111C92.9437 27.4286 93.0272 27.0172 93.0264 26.6016C93.0513 26.1207 92.9717 25.6401 92.793 25.1929C92.6144 24.7458 92.341 24.3426 91.9916 24.0112C91.3197 23.3863 90.3554 23.0705 89.1527 23.0705H84.009V35.5921ZM86.2432 27.9287V25.0225H89.2669C89.4681 25.0107 89.6696 25.0387 89.8598 25.105C90.0501 25.1713 90.2254 25.2746 90.3756 25.4089C90.5154 25.5425 90.6246 25.7048 90.696 25.8845C90.7673 26.0643 90.799 26.2573 90.7888 26.4504C90.7958 26.6482 90.7628 26.8453 90.6918 27.03C90.6208 27.2148 90.5132 27.3833 90.3756 27.5255C90.229 27.6631 90.0562 27.7698 89.8674 27.839C89.6786 27.9083 89.4778 27.9388 89.277 27.9287H86.2432ZM86.2432 33.5763V29.9277H89.3946C89.6463 29.9148 89.898 29.9528 90.1346 30.0393C90.3713 30.1259 90.5881 30.2594 90.7721 30.4316C90.9511 30.6046 91.0907 30.8143 91.1811 31.0462C91.2716 31.2782 91.3108 31.527 91.2962 31.7755C91.3087 32.0238 91.2684 32.272 91.1781 32.5036C91.0877 32.7352 90.9494 32.9451 90.7721 33.1194C90.3949 33.4568 89.9 33.6318 89.3946 33.6065L86.2432 33.5763Z"
                fill="#BEDBFE"
            />
            <path
                d="M98.113 35.7939C98.6239 35.8038 99.13 35.6945 99.5913 35.4747C100.022 35.2561 100.38 34.9178 100.623 34.5004V35.5923H102.659V26.4506H100.509V31.8261C100.537 32.3568 100.355 32.8772 100.001 33.2741C99.649 33.6088 99.1818 33.7954 98.696 33.7954C98.2102 33.7954 97.7429 33.6088 97.3907 33.2741C97.0512 32.89 96.8752 32.3885 96.9002 31.8765V26.4506H94.75V32.0478C94.7056 33.032 95.0196 33.9988 95.6336 34.7692C95.9457 35.1123 96.3299 35.3821 96.7586 35.5592C97.1872 35.7364 97.6498 35.8165 98.113 35.7939Z"
                fill="#BEDBFE"
            />
            <path
                d="M107.809 35.7939C108.417 35.8132 109.021 35.6985 109.58 35.4579C110.055 35.2458 110.463 34.9091 110.762 34.4836C111.048 34.0502 111.196 33.541 111.189 33.0221C111.201 32.4183 110.991 31.8311 110.598 31.3725C110.14 30.8769 109.546 30.5264 108.891 30.3646L107.463 29.9279C107.192 29.8569 106.936 29.7374 106.707 29.5751C106.612 29.5065 106.535 29.4154 106.483 29.3099C106.432 29.2043 106.407 29.0877 106.411 28.9703C106.406 28.8415 106.431 28.7131 106.486 28.5962C106.54 28.4792 106.622 28.3771 106.724 28.2984C106.971 28.1185 107.272 28.0294 107.577 28.0464C108.01 28.0486 108.433 28.1835 108.787 28.4328C109.151 28.671 109.422 29.0267 109.556 29.4407L111.199 28.6512C110.945 27.9363 110.459 27.3271 109.818 26.9209C109.155 26.4895 108.378 26.2671 107.587 26.2826C106.994 26.2719 106.405 26.3923 105.864 26.6354C105.392 26.8534 104.989 27.1961 104.698 27.6265C104.419 28.0619 104.276 28.5708 104.288 29.0879C104.276 29.6827 104.48 30.2618 104.863 30.7174C105.325 31.2195 105.924 31.5757 106.586 31.7421L108.048 32.1654C108.31 32.2319 108.556 32.352 108.77 32.5182C108.87 32.5838 108.951 32.6738 109.005 32.7797C109.06 32.8856 109.087 33.0038 109.082 33.1229C109.086 33.2639 109.055 33.4036 108.992 33.5296C108.928 33.6556 108.835 33.764 108.72 33.8453C108.449 34.0255 108.127 34.1139 107.802 34.0972C107.313 34.1005 106.835 33.9475 106.438 33.6605C106.018 33.3466 105.685 32.9302 105.471 32.451L103.861 33.2405C104.116 34.0131 104.626 34.6763 105.306 35.122C106.055 35.5909 106.926 35.825 107.809 35.7939Z"
                fill="#BEDBFE"
            />
            <path
                d="M112.593 25.4257H114.744V23.0739H112.593V25.4257ZM112.593 35.5922H114.744V26.4504H112.593V35.5922Z"
                fill="#BEDBFE"
            />
            <path
                d="M116.484 35.5924H118.631V30.2168C118.606 29.6951 118.783 29.1838 119.125 28.789C119.293 28.6112 119.497 28.4714 119.724 28.3787C119.95 28.286 120.194 28.2426 120.438 28.2514C120.68 28.2422 120.922 28.2854 121.146 28.3782C121.369 28.471 121.57 28.6111 121.735 28.789C121.911 28.9811 122.046 29.2064 122.133 29.4517C122.22 29.6969 122.257 29.957 122.242 30.2168V35.5924H124.393V29.7095C124.405 29.0839 124.258 28.4654 123.966 27.9121C123.693 27.3961 123.276 26.9702 122.767 26.6858C122.237 26.3873 121.637 26.2364 121.03 26.249C120.506 26.2404 119.988 26.3555 119.518 26.585C119.089 26.8054 118.737 27.1511 118.51 27.5761V26.4506H116.494L116.484 35.5924Z"
                fill="#BEDBFE"
            />
            <path
                d="M130.198 35.7938C131.033 35.8123 131.857 35.597 132.577 35.1723C133.216 34.8004 133.722 34.236 134.021 33.5596L132.281 32.6861C132.082 33.0341 131.8 33.3282 131.461 33.5428C131.011 33.7938 130.494 33.901 129.98 33.85C129.467 33.7989 128.981 33.5921 128.589 33.2573C128.142 32.8334 127.872 32.2561 127.833 31.6412H134.267C134.313 31.5057 134.34 31.3644 134.347 31.2213C134.37 31.0543 134.381 30.8859 134.381 30.7173C134.391 29.9429 134.217 29.1772 133.874 28.4831C133.541 27.8185 133.029 27.26 132.395 26.8705C131.693 26.4631 130.895 26.2486 130.082 26.2486C129.27 26.2486 128.472 26.4631 127.769 26.8705C127.099 27.2867 126.55 27.8718 126.177 28.5671C125.783 29.3178 125.586 30.1556 125.602 31.0029C125.592 31.8453 125.789 32.6773 126.177 33.4252C126.547 34.1369 127.103 34.7349 127.786 35.1555C128.513 35.5923 129.35 35.8137 130.198 35.7938ZM130.034 28.0665C130.554 28.057 131.058 28.2493 131.44 28.6031C131.822 28.957 132.053 29.4449 132.083 29.9648H127.883C127.968 29.4269 128.235 28.9346 128.639 28.5705C129.026 28.2354 129.522 28.0548 130.034 28.0632V28.0665Z"
                fill="#BEDBFE"
            />
            <path
                d="M139.162 35.7939C139.771 35.8133 140.376 35.6986 140.936 35.458C141.41 35.2457 141.817 34.909 142.115 34.4836C142.402 34.0508 142.551 33.5413 142.542 33.0222C142.555 32.4181 142.345 31.8304 141.95 31.3726C141.493 30.8763 140.899 30.5257 140.244 30.3646L138.816 29.9279C138.546 29.8569 138.291 29.7373 138.063 29.5751C137.967 29.5069 137.89 29.4159 137.839 29.3103C137.787 29.2047 137.763 29.0878 137.768 28.9704C137.762 28.8415 137.787 28.7132 137.842 28.5962C137.896 28.4792 137.978 28.3771 138.08 28.2984C138.327 28.118 138.628 28.0289 138.933 28.0465C139.366 28.0424 139.789 28.1714 140.146 28.416C140.511 28.6541 140.784 29.0097 140.919 29.4239L142.559 28.6344C142.306 27.9198 141.821 27.3105 141.181 26.9042C140.518 26.4731 139.741 26.2507 138.95 26.2658C138.357 26.2551 137.768 26.3755 137.227 26.6186C136.755 26.8357 136.351 27.1786 136.061 27.6097C135.782 28.0452 135.639 28.5541 135.651 29.0712C135.639 29.666 135.843 30.245 136.225 30.7006C136.687 31.2035 137.287 31.5598 137.949 31.7253L139.407 32.1486C139.67 32.2144 139.916 32.3346 140.129 32.5014C140.229 32.567 140.31 32.657 140.365 32.7629C140.419 32.8688 140.446 32.987 140.442 33.1062C140.446 33.2468 140.415 33.3862 140.352 33.5121C140.289 33.638 140.197 33.7467 140.082 33.8285C139.81 34.0084 139.488 34.0966 139.162 34.0805C138.679 34.0808 138.208 33.9279 137.818 33.6437C137.396 33.3314 137.062 32.9146 136.85 32.4342L135.241 33.2237C135.495 33.9969 136.004 34.6606 136.686 35.1052C137.424 35.5756 138.286 35.8154 139.162 35.7939Z"
                fill="#BEDBFE"
            />
            <path
                d="M147.346 35.7939C147.954 35.8136 148.558 35.6988 149.117 35.458C149.592 35.2458 150 34.9092 150.299 34.4836C150.586 34.0508 150.735 33.5413 150.726 33.0222C150.738 32.4184 150.528 31.8312 150.135 31.3726C149.677 30.8763 149.083 30.5257 148.428 30.3646L147 29.9279C146.73 29.8576 146.474 29.738 146.247 29.5751C146.152 29.5069 146.074 29.4159 146.023 29.3103C145.971 29.2047 145.947 29.0878 145.952 28.9704C145.946 28.8419 145.971 28.7139 146.025 28.597C146.079 28.4802 146.16 28.3778 146.261 28.2984C146.508 28.1185 146.809 28.0295 147.114 28.0465C147.548 28.0423 147.972 28.1713 148.33 28.416C148.695 28.6533 148.967 29.0093 149.1 29.4239L150.743 28.6344C150.489 27.9205 150.004 27.3117 149.365 26.9042C148.701 26.473 147.923 26.2507 147.131 26.2658C146.538 26.2551 145.949 26.3756 145.408 26.6186C144.936 26.8358 144.534 27.1787 144.245 27.6097C143.964 28.0445 143.822 28.5539 143.835 29.0712C143.823 29.6592 144.023 30.2319 144.4 30.6838C144.861 31.1859 145.459 31.5421 146.12 31.7085L147.591 32.1285C147.854 32.195 148.1 32.3151 148.314 32.4813C148.413 32.5468 148.494 32.6369 148.549 32.7428C148.604 32.8487 148.63 32.9669 148.626 33.086C148.63 33.227 148.599 33.3667 148.535 33.4927C148.472 33.6187 148.379 33.7271 148.263 33.8083C147.993 33.9886 147.671 34.077 147.346 34.0603C146.857 34.0635 146.379 33.9105 145.982 33.6236C145.561 33.3097 145.228 32.8933 145.014 32.4141L143.409 33.2036C143.662 33.9759 144.17 34.6392 144.85 35.085C145.592 35.5657 146.462 35.8126 147.346 35.7939Z"
                fill="#BEDBFE"
            />
            <path
                d="M75.0884 35.605C74.0643 35.605 73.171 35.3768 72.4087 34.9204C71.6576 34.4532 71.1456 33.8284 70.8725 33.046L72.75 32.1984C72.989 32.6765 73.3133 33.0514 73.7229 33.3231C74.1326 33.5947 74.5877 33.7306 75.0884 33.7306C75.8394 33.7306 76.2149 33.4643 76.2149 32.9319C76.2149 32.5733 75.9077 32.3125 75.2932 32.1495L73.7059 31.7257C72.9435 31.5192 72.3575 31.1878 71.9478 30.7314C71.5382 30.2641 71.3333 29.7154 71.3333 29.0851C71.3333 28.5309 71.4813 28.0474 71.7771 27.6345C72.073 27.2215 72.4883 26.901 73.0231 26.6728C73.5579 26.4337 74.1724 26.3142 74.8665 26.3142C75.7768 26.3142 76.5733 26.5206 77.2561 26.9336C77.9502 27.3356 78.4395 27.9061 78.724 28.645L76.8294 29.4926C76.6928 29.1232 76.4425 28.8298 76.0784 28.6124C75.7256 28.3842 75.3217 28.2701 74.8665 28.2701C74.5365 28.2701 74.2748 28.3353 74.0814 28.4657C73.8993 28.5961 73.8083 28.7754 73.8083 29.0036C73.8083 29.3514 74.1439 29.6176 74.8153 29.8023L76.3003 30.2261C77.074 30.4434 77.6657 30.7749 78.0754 31.2204C78.485 31.6551 78.6898 32.193 78.6898 32.8341C78.6898 33.6599 78.3598 34.3282 77.6999 34.8389C77.0399 35.3497 76.1694 35.605 75.0884 35.605Z"
                fill="#BEDBFE"
            />
            <path
                d="M59.9513 38.6694V26.5098H62.3409V27.4389C63.0009 26.6891 63.951 26.3142 65.1913 26.3142C66.0789 26.3142 66.8811 26.5206 67.5979 26.9336C68.3148 27.3465 68.8838 27.9061 69.3048 28.6124C69.7258 29.3079 69.9363 30.0903 69.9363 30.9596C69.9363 31.8181 69.7258 32.6004 69.3048 33.3068C68.8951 34.0131 68.3319 34.5727 67.615 34.9856C66.8981 35.3986 66.0845 35.605 65.1742 35.605C64.6508 35.605 64.1558 35.5344 63.6893 35.3931C63.2341 35.241 62.8415 35.0182 62.5116 34.7248V38.6694H59.9513ZM64.867 33.4046C65.5725 33.4046 66.1528 33.1764 66.608 32.72C67.0631 32.2636 67.2907 31.6768 67.2907 30.9596C67.2907 30.2533 67.0631 29.6719 66.608 29.2155C66.1528 28.7483 65.5725 28.5146 64.867 28.5146C64.1729 28.5146 63.6039 28.7428 63.1602 29.1992C62.7278 29.6556 62.5116 30.2424 62.5116 30.9596C62.5116 31.6877 62.7278 32.2799 63.1602 32.7363C63.6039 33.1818 64.1729 33.4046 64.867 33.4046Z"
                fill="#BEDBFE"
            />
            <path
                d="M51.5207 35.605C50.5649 35.605 49.6773 35.4475 48.858 35.1323C48.0388 34.8172 47.3219 34.3771 46.7074 33.8121C46.1043 33.2361 45.6321 32.5678 45.2907 31.8072C44.9494 31.0465 44.7787 30.2207 44.7787 29.3296C44.7787 28.4386 44.9437 27.6127 45.2737 26.8521C45.615 26.0914 46.0873 25.4286 46.6903 24.8635C47.3048 24.2984 48.0217 23.8583 48.841 23.5432C49.6603 23.2281 50.5535 23.0705 51.5207 23.0705C52.4879 23.0705 53.3812 23.2281 54.2004 23.5432C55.0197 23.8583 55.7309 24.2984 56.334 24.8635C56.9485 25.4286 57.4207 26.0914 57.7507 26.8521C58.092 27.6127 58.2627 28.4386 58.2627 29.3296C58.2627 30.2207 58.092 31.0465 57.7507 31.8072C57.4093 32.5678 56.9314 33.2361 56.3169 33.8121C55.7139 34.3771 55.0027 34.8172 54.1834 35.1323C53.3641 35.4475 52.4765 35.605 51.5207 35.605ZM51.5207 33.3231C52.2945 33.3231 52.9886 33.1546 53.6031 32.8178C54.2289 32.47 54.7182 31.9974 55.0709 31.3997C55.4351 30.802 55.6171 30.112 55.6171 29.3296C55.6171 28.5472 55.4351 27.8572 55.0709 27.2596C54.7182 26.6619 54.2289 26.1946 53.6031 25.8578C52.9886 25.5209 52.2945 25.3525 51.5207 25.3525C50.7469 25.3525 50.0471 25.5209 49.4213 25.8578C48.8068 26.1946 48.3175 26.6619 47.9534 27.2596C47.6007 27.8572 47.4243 28.5472 47.4243 29.3296C47.4243 30.112 47.6007 30.802 47.9534 31.3997C48.3175 31.9974 48.8068 32.47 49.4213 32.8178C50.0471 33.1546 50.7469 33.3231 51.5207 33.3231Z"
                fill="#BEDBFE"
            />
            <path
                d="M0.466858 8.26369C0.466858 5.59625 0.466858 4.26463 0.979344 3.24385C1.42787 2.34354 2.14987 1.60831 3.04189 1.14351C4.05006 0.622619 5.37328 0.62262 8.01552 0.62262H29.939C32.5812 0.62262 33.9002 0.622619 34.9084 1.14351C35.8022 1.60594 36.5248 2.34183 36.9709 3.24385C37.4876 4.26463 37.4876 5.59625 37.4876 8.26369V29.2672C37.4876 31.9346 37.4876 33.2663 36.9709 34.287C36.5248 35.189 35.8022 35.9249 34.9084 36.3874C33.9002 36.9041 32.5812 36.9041 29.939 36.9041H8.01552C5.37328 36.9041 4.05006 36.9041 3.04189 36.3874C2.14987 35.9226 1.42787 35.1873 0.979344 34.287C0.466858 33.2663 0.466858 31.9346 0.466858 29.2672V8.26369Z"
                fill="#3B82F6"
            />
            <path
                d="M22.6676 9.76378V5.23962H20.2059V9.76378H17.7401V5.23962H15.2785V9.76378H12.8043V12.0616C12.8043 15.2541 14.1822 18.8079 17.7485 19.4716V27.8142C17.7485 28.6081 17.7485 29.2004 17.5049 29.4441C17.3285 29.6247 16.7992 29.9271 15.2617 29.9271C14.0603 29.9271 13.2916 29.7297 13.027 29.3684C12.7623 29.0072 12.8043 28.4695 12.8043 27.8142V23.311C12.8043 21.1393 12.4221 18.8163 7.8769 18.8163H0.471069V21.3031H7.83909C10.3595 21.3031 10.3595 21.7736 10.3595 23.3152V27.8142C10.3595 28.7341 10.3595 29.9145 11.0694 30.8765C11.8424 31.9225 13.1698 32.4097 15.2701 32.4097C17.1604 32.4097 18.4165 32.0317 19.223 31.2168C20.1891 30.238 20.2227 28.8308 20.2185 27.8142V19.4716C23.7807 18.8079 25.1628 15.2709 25.1628 12.0616V9.76378H22.6676Z"
                fill="white"
            />
        </svg>
    </nuxt-link>
</template>
<!-- 
<style>
.chargemap-logo{animation:appear 1s}@keyframes appear{0%{opacity:0}}
</style> -->
