<script>
export default {
  name: "IconRedOctogone"
};
</script>

<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
        viewBox="0 0 34 32"
    >
        <path
            fill="#dc2626"
            d="M10.039 0.972c0.263-0.263 0.62-0.411 0.992-0.411h11.621c0.372 0 0.729 0.148 0.992 0.411l8.225 8.225c0.263 0.263 0.411 0.62 0.411 0.992v11.621c0 0.372-0.148 0.729-0.411 0.992l-8.225 8.224c-0.263 0.263-0.62 0.411-0.992 0.411h-11.621c-0.372 0-0.729-0.148-0.992-0.411l-8.225-8.224c-0.263-0.263-0.411-0.62-0.411-0.992v-11.621c0-0.372 0.148-0.729 0.411-0.992l8.225-8.225zM11.613 3.368l-7.402 7.402v10.458l7.402 7.402h10.458l7.402-7.402v-10.458l-7.402-7.402h-10.458z"
        ></path>
        <path
            fill="#dc2626"
            d="M22.045 10.797c0.548 0.548 0.548 1.437 0 1.985l-8.421 8.421c-0.548 0.548-1.437 0.548-1.985 0s-0.548-1.437 0-1.985l8.421-8.421c0.548-0.548 1.437-0.548 1.985 0z"
        ></path>
        <path
            fill="#dc2626"
            d="M11.639 10.797c0.548-0.548 1.437-0.548 1.985 0l8.421 8.421c0.548 0.548 0.548 1.437 0 1.985s-1.437 0.548-1.985 0l-8.421-8.421c-0.548-0.548-0.548-1.437 0-1.985z"
        ></path>
    </svg>
</template>
