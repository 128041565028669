<script>
export default {
  name: "IconStarHalf"
};
</script>

<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        class="fill-current"
    >
        <path
            d="M16.002 22.378v-15.963l3.050 6.934 7.813 0.635-5.922 4.943 1.766 7.346-6.707-3.896zM31.558 13.725c0.852-0.71 0.397-2.057-0.727-2.148l-9.817-0.801-3.84-8.717c-0.439-0.997-1.907-0.997-2.346 0l-3.84 8.717-9.821 0.802c-1.123 0.092-1.578 1.437-0.727 2.148l7.444 6.217-2.227 9.241c-0.254 1.056 0.935 1.887 1.9 1.327l8.444-4.904 8.439 4.901c0.965 0.56 2.155-0.272 1.899-1.328l-2.24-9.237 7.457-6.216z"
        ></path>
    </svg>
</template>
