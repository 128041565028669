<script>
export default {
  name: "IconLoader"
};
</script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_4568:13276)">
            <circle
                cx="12"
                cy="12.0002"
                r="9.3"
                stroke="#E5E7EB"
                stroke-width="3"
            />
            <path
                d="M14.1276 1.41266C15.9874 1.78635 17.716 2.64331 19.1393 3.8973C20.5627 5.1513 21.6307 6.75808 22.2359 8.55596C22.841 10.3538 22.9619 12.2794 22.5864 14.1388C22.2109 15.9983 21.3522 17.726 20.0968 19.1482L17.407 16.7738C18.2453 15.8241 18.8187 14.6704 19.0695 13.4286C19.3202 12.1869 19.2395 10.9011 18.8354 9.70046C18.4313 8.49986 17.7181 7.42687 16.7676 6.58947C15.8171 5.75207 14.6627 5.17981 13.4208 4.93026L14.1276 1.41266Z"
                class="fill-current"
            />
            <path
                d="M10.4157 22.6839C8.53843 22.4054 6.76774 21.6371 5.28191 20.4565C3.79608 19.2759 2.64759 17.7245 1.9521 15.9588C1.2566 14.1931 1.03867 12.2752 1.32024 10.3985C1.60181 8.52168 2.37295 6.75223 3.55599 5.26833L6.36143 7.50496C5.57141 8.49589 5.05646 9.6775 4.86843 10.9308C4.6804 12.1841 4.82593 13.4648 5.29037 14.6439C5.75482 15.8231 6.52177 16.859 7.51398 17.6474C8.5062 18.4358 9.68864 18.9488 10.9422 19.1348L10.4157 22.6839Z"
                class="fill-current"
            />
        </g>
        <defs>
            <clipPath id="clip0_4568:13276">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
