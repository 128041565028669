<script>
export default {
  name: "DrawerInfoCard"
};
</script>

<template>
    <div data-testid="drawer-tab-info-card">
        <div v-if="$slots.title" class="tab-info__title">
            <slot name="title" />
        </div>
        <div class="tab-info__container">
            <slot />
        </div>
    </div>
</template>

<style>
.tab-info__title {
  @apply text-secondary-neutral text-center font-medium tracking-wider w-full mx-auto mb-4 uppercase;
}
.tab-info__container {
  @apply flex flex-col justify-self-center w-full px-6 divide-y rounded-2xl border border-solid border-slate-300;
}
.tab-info__container > * {
  @apply py-4;
}
.tab-info__container .line {
  @apply flex items-center justify-between py-4 text-fifteen-exploitation font-normal leading-120;
}
.tab-info__container .line :first-child {
  @apply text-secondary-neutral text-left;
}
.tab-info__container .line :last-child {
  @apply text-primary-neutral text-right;
}
</style>
