<script>
import NoContribution from "../../images/NoContribution.vue";
export default {
  name: "RatingInfosPopup",
  components: {
    NoContribution
  },
  emits: ["closePopUp"],
  methods: {
    redirectOnSupportPage() {
      const urlLocale = ["es", "de", "fr"].includes(this.$i18n.locale) ? this.$i18n.locale : "en";
      window.open(
        `https://support.chargemap.com/l/${urlLocale}/article/si5ze858b1-chargemap-evaluation-policy`,
        "_blank"
      );
      this.$emit("closePopUp");
    }
  }
};
</script>

<template>
    <div
        class="text-seventeen-exploitation flex flex-col bg-white p-4 rounded-2xl shadow-lower-4 max-w-md"
        data-testid="rating-info-popup"
    >
        <NoContribution
            class="max-w-xs m-auto w-80 mb-5"
            :alt="$t('reviews.about.title')"
        />

        <h1 class="text-center text-twenty-two font-bold mb-2 text-gray-l-900">
            {{ $t('reviews.about.title') }}
        </h1>
        <p class="text-center text-gray-l-700 tracking-light">
            {{ $t('reviews.about.description') }}
        </p>
        <div class="flex gap-2 mt-4">
            <button
                class="w-full p-2 border-gray-l-800 border rounded-lg text-gray-l-800 font-medium"
                @click="$emit('closePopUp')"
            >
                {{ $t('close') }}
            </button>
            <button
                class="w-full bg-lb-400 text-white p-2 rounded-lg font-medium"
                @click="redirectOnSupportPage"
            >
                {{ $t('learn_more') }}
            </button>
        </div>
    </div>
</template>
