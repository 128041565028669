<script lang="ts">
import * as Sentry from '@sentry/browser'
import { mapState } from 'pinia'
import usePartnersStore from '@/store/partners'
import useNetworksStore from '@/store/networks'

export default {
    name: 'Error',
    props: {
        error: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        ...mapState(usePartnersStore, ['currentPartner']),
        ...mapState(useNetworksStore, [
            'currentNetwork',
            'currentCommunityNetwork'
        ]),
        errorMessage() {
            if ([401, 403, 404, 410].includes(this.error.statusCode)) {
                return this.$t(`errors.message.${this.error.statusCode}`)
            }

            return this.$t(`errors.message.500`)
        },
        nodeEnvDev() {
            return this.$config.public.NODE_ENV === 'dev'
        }
    },
    mounted() {
        if (![401, 403, 404].includes(this.error?.statusCode))
            Sentry.captureException(
                new Error(
                    `Error ${this.error?.statusCode} : ${this.error.stack}`
                )
            )
    },
    methods: {
        goToDashboard() {
            let url = ''

            if (
                this.currentPartner &&
                (this.currentCommunityNetwork || this.currentNetwork)
            ) {
                url += `/partners/${this.currentPartner.id}/networks/`

                if (!this.currentNetwork) {
                    url += `community/${this.currentCommunityNetwork?.id}/data`
                } else {
                    url += `interop/${this.currentNetwork.id}/data`
                }
            } else {
                url += '/'
            }

            this.$router.push(url)
        },
        goToNetworks() {
            let url = ''

            if (this.currentPartner) {
                url += `/partners/${this.currentPartner.id}/networks`
            } else {
                url += '/'
            }

            this.$router.push(url)
        }
    }
}
</script>

<template>
    <div
        class="min-h-login p-4 lg:p-9 flex flex-col h-full w-full items-center justify-center"
    >
        <div class="flex flex-col items-start">
            <h1 class="text-gray-600 text-6xl font-medium mb-2">
                {{ $t('errors.oops') }}
            </h1>
            <p class="text-gray-600 text-3xl mb-8 font-medium">
                {{ errorMessage }}
            </p>
            <p class="text-gray-500 text-lg mb-8 font-medium">
                {{ $t('errors.errorCode') }}:
                <span class="font-semibold">{{ error.statusCode }}</span>
            </p>
            <p class="text-gray-500 text-lg mb-5 font-medium">
                {{ $t('errors.usefulLinks') }}
            </p>

            <div class="flex flex-col gap-2">
                <span
                    class="text-primary-500 font-medium cursor-pointer"
                    @click="goToDashboard"
                >
                    {{ $t('appMenu.user.dashboard') }}
                </span>
                <span
                    class="text-primary-500 font-medium cursor-pointer"
                    @click="goToNetworks"
                >
                    {{ $t('appMenu.user.myNetworks') }}
                </span>
            </div>
        </div>

        <div v-show="nodeEnvDev" class="mt-4 flex flex-col gap-4">
            <p>
                <span class="font-bold">Message :</span>
                {{ error.message }}
            </p>
            <span>{{ error.stack }}</span>
        </div>
    </div>
</template>
