<script>
export default {
  name: "IconArrayReturnRight"
};
</script>

<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29 32"
        class="fill-current"
    >
        <path
            d="M4.334 2.459c0-1.248-0.97-2.259-2.167-2.259s-2.167 1.012-2.167 2.259v11.296c0 4.991 3.881 9.037 8.668 9.037h13.278l-4.968 5.18c-0.395 0.426-0.613 0.997-0.608 1.589s0.233 1.159 0.635 1.578c0.402 0.419 0.945 0.656 1.513 0.662s1.116-0.223 1.524-0.634l8.668-9.037c0.406-0.424 0.634-0.998 0.634-1.597s-0.228-1.174-0.634-1.597l-8.668-9.037c-0.406-0.424-0.957-0.661-1.532-0.661s-1.126 0.238-1.532 0.661c-0.406 0.424-0.634 0.998-0.634 1.597s0.228 1.174 0.634 1.597l4.969 5.18c-4.426 0-8.852 0-13.279 0-2.394 0-4.334-2.023-4.334-4.518v-11.296z"
        ></path>
    </svg>
</template>
