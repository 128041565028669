<script>
import ClickOutside from "../../../utils/ClickOutsideDirective";
import ChargemapDropdown from "../../b2b/global/ChargemapDropdown.vue";
import IconDotsVertical from "../../icons/IconDotsVertical.vue";
export default {
  name: "ChargemapCard",
  components: { ChargemapDropdown, IconDotsVertical },
  directives: {
    ClickOutside
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    hasShadow: {
      type: Boolean,
      default: true
    },
    hasPadding: {
      type: Boolean,
      default: true
    },
    textSpaceBetween: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
    <div
        class="rounded-xl"
        :class="[
            { 'relative pb-14': $slots.quickActions },
            hasBorder && 'border border-gray-100',
            hasShadow && hasBorder && 'shadow',
            hasShadow && !hasBorder && 'shadow-md',
            hasPadding && 'p-6'
        ]"
        v-bind="$attrs"
        data-testid="chargemap-card"
    >
        <div
            class="flex flex-col h-full"
            :class="[textSpaceBetween ? 'justify-between' : 'justify-start']"
        >
            <div
                v-if="$slots.actionsList || $slots.title"
                class="flex justify-between"
            >
                <slot v-if="$slots.title" name="title" />
                <div v-if="$slots.actionsList">
                    <ChargemapDropdown position="left">
                        <template #button="slotProps">
                            <ChargemapButton
                                :ref="(el) => slotProps.setButtonRef(el)"
                                class="inline-flex w-full justify-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                                btn-type="button"
                                btn-alt="clear-full"
                                @click="slotProps.toggleShowList"
                            >
                                <IconDotsVertical
                                    class="size-5"
                                    data-testid="app-dropdown-icon-dots-vertical"
                                />
                            </ChargemapButton>
                        </template>

                        <template #actionsList="slotProps">
                            <slot
                                name="actionsList"
                                :toggle-show-list="slotProps.toggleShowList"
                            />
                        </template>
                    </ChargemapDropdown>
                </div>
            </div>
            <slot />
            <div v-if="$slots.details" aria-hidden="true">
                <div class="py-4">
                    <div class="border-t border-gray-200" />
                </div>
            </div>
            <div v-if="$slots.details" class="flex flex-col space-y-2">
                <slot name="details" />
            </div>
        </div>
        <div v-if="$slots.quickActions" class="quick-actions">
            <slot name="quickActions" />
        </div>
    </div>
</template>

<style scoped>
.quick-actions {
  @apply absolute w-full bottom-0 left-0 right-0 flex divide-x divide-gray-200 border-gray-200 border-t;
}

.quick-actions > button {
  @apply flex justify-center items-center flex-1 text-sm text-gray-700 bg-gray-100 p-3 hover:bg-gray-200;
}

.quick-actions > button svg {
  @apply mr-2 float-left;
}

.quick-actions > button:first-child {
  @apply rounded-bl-lg;
}

.quick-actions > button:last-child {
  @apply rounded-br-lg;
}
</style>
