<script>
export default {
  name: "IconWarning",
  props: {
    isSolid: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
    <svg
        v-if="isSolid"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        aria-hidden="true"
    >
        <path
            fill-rule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
        />
    </svg>
    <svg
        v-else
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        aria-hidden="true"
    >
        <path
            fill-rule="evenodd"
            d="M7.40204 1.4998C8.55699 -0.499609 11.4429 -0.499609 12.5979 1.4998L19.5259 13.4998C20.681 15.4995 19.2378 18 16.928 18H3.07196C0.76222 18 -0.680997 15.4997 0.473927 13.5C0.473888 13.5001 0.473965 13.4999 0.473927 13.5L7.40204 1.4998ZM9.13387 2.50019L2.20599 14.5C1.82113 15.1662 2.30177 16 3.07196 16H16.928C17.6981 16 18.1789 15.1664 17.794 14.5002L10.866 2.50019C10.481 1.8336 9.51892 1.8336 9.13387 2.50019ZM9.99996 5.99999C10.5522 5.99999 11 6.44771 11 6.99999V8.99999C11 9.55228 10.5522 9.99999 9.99996 9.99999C9.44767 9.99999 8.99996 9.55228 8.99996 8.99999V6.99999C8.99996 6.44771 9.44767 5.99999 9.99996 5.99999ZM8.99996 13C8.99996 12.4477 9.44767 12 9.99996 12H10.01C10.5622 12 11.01 12.4477 11.01 13C11.01 13.5523 10.5622 14 10.01 14H9.99996C9.44767 14 8.99996 13.5523 8.99996 13Z"
            clip-rule="evenodd"
        />
    </svg>
</template>
