<script>
import { DateTime } from 'luxon'
import IconNoPass from '../../icons/IconNoPass.vue'
import IconPassActivated from '../../icons/IconPassActivated.vue'
import IconPassNotActivated from '../../icons/IconPassNotActivated.vue'

export default {
    name: 'InfoBanner',
    components: { IconPassActivated, IconPassNotActivated, IconNoPass },
    props: {
        user: {
            type: Object,
            default() {
                return {}
            }
        },
        creationDate: {
            type: Object,
            default: DateTime.now()
        },
        vehicle: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    emits: ['goToUserDetail'],
    computed: {
        date() {
            return DateTime.fromISO(this.creationDate).toFormat('dd/LL/yyyy')
        },
        hour() {
            return DateTime.fromISO(this.creationDate).toFormat('HH:mm:ss')
        },
        hasActivatedPass() {
            return this.user.passState && this.user.passState === 'ACTIVATED'
        },
        hasNotActivatedPass() {
            return this.user.passState && this.user.passState !== 'ACTIVATED'
        },
        getUsername() {
            return this.user?.username || ''
        },
        hasVehicle() {
            return (
                this.vehicle?.brandName !== undefined &&
                this.vehicle?.modelName !== undefined
            )
        },
        getVehicleModelName() {
            return this.vehicle?.modelName || ''
        },
        getVehicleBrandName() {
            return this.vehicle?.brandName || ''
        },
        getVehicleVersionName() {
            return this.vehicle?.versionName || ''
        },
        getPassIcon() {
            if (this.hasActivatedPass) return 'IconPassActivated'

            if (this.hasNotActivatedPass) return 'IconPassNotActivated'

            return 'IconNoPass'
        }
    }
}
</script>

<template>
    <div
        data-testid="info-banner"
        class="flex flex-row justify-between gap-4 p-2.5 rounded-md font-normal text-thirteen-exploitation leading-4 text-white bg-gray-500 items-center hover:bg-blue-500"
    >
        <div class="flex gap-2.5 items-center">
            <component :is="getPassIcon" class="h-4" />
            <div
                data-testid="username"
                class="cursor-pointer underline text-white"
                @click="$emit('goToUserDetail', user.id)"
            >
                {{ getUsername }}
            </div>
        </div>
        <div class="flex gap-1">
            <div>{{ date }}</div>
            <div>{{ hour }}</div>
        </div>
        <div v-if="hasVehicle" class="tracking-light">
            {{ getVehicleBrandName }} {{ getVehicleModelName }}
            {{ getVehicleVersionName }}
        </div>
        <div v-else class="tracking-tight text-yellow-400">
            {{ $t('unknown_vehicle') }}
        </div>
    </div>
</template>
