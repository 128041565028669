<script lang="ts">
export default {
    name: 'IconExclamation'
}
</script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.40208 3.4998C10.557 1.50039 13.443 1.50039 14.5979 3.4998L21.5259 15.4998C22.681 17.4995 21.2378 20 18.928 20H5.072C2.76226 20 1.31904 17.4997 2.47396 15.5C2.47393 15.5001 2.474 15.4999 2.47396 15.5L9.40208 3.4998ZM11.1339 4.50019L4.20603 16.5C3.82117 17.1662 4.30181 18 5.072 18H18.928C19.6982 18 20.1789 17.1664 19.7941 16.5002L12.8661 4.50019C12.481 3.8336 11.519 3.8336 11.1339 4.50019ZM12 7.99999C12.5523 7.99999 13 8.44771 13 8.99999V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V8.99999C11 8.44771 11.4477 7.99999 12 7.99999ZM11 15C11 14.4477 11.4477 14 12 14H12.01C12.5623 14 13.01 14.4477 13.01 15C13.01 15.5523 12.5623 16 12.01 16H12C11.4477 16 11 15.5523 11 15Z"
        />
    </svg>
</template>
