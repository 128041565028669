<script>
import { DateTime } from "luxon";
import { Day } from "../../../../../interfaces/poolDetail";
import DrawerInfoCard from "./DrawerInfoCard.vue";
export default {
  name: "DrawerInfoHours",
  components: { DrawerInfoCard },
  props: {
    isAlwaysOpen: {
      type: Boolean,
      default: false
    },
    schedules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      days: Day
    };
  },
  computed: {
    isCurrentlyOpen() {
      const now = DateTime.now();
      if (this.isAlwaysOpen) return true;
      if (this.schedules.length === 0) return false;
      const todaySchedule = this.schedules.find(
        (schedule) => Object.keys(Day).indexOf(schedule.day.toUpperCase()) + 1 === DateTime.now().weekday
      );
      if (!todaySchedule) return false;
      const openingTime = todaySchedule?.openingTime?.split(":");
      const closingTime = todaySchedule?.closingTime?.split(":");
      if (!openingTime || !closingTime) return false;
      const openingHour = parseInt(openingTime[0], 10);
      const closingHour = parseInt(closingTime[0], 10);
      if (now.hour < openingHour || now.hour > closingHour) {
        return false;
      }
      const openingMinute = parseInt(openingTime[1], 10);
      const closingMinute = parseInt(closingTime[1], 10);
      if (now.minute < openingMinute && now.hour === openingHour || now.minute > closingMinute && now.hour === closingHour) {
        return false;
      }
      return true;
    },
    todaysIndex() {
      const today = this.schedules.find(
        (schedule) => schedule.day === Object.keys(Day)[DateTime.now().weekday - 1]
      );
      if (!today) return -1;
      return this.schedules.indexOf(today);
    }
  },
  methods: {
    getDayIndex(day) {
      return Object.keys(Day).indexOf(day.toUpperCase());
    },
    openingTime(day) {
      if (!day) return "";
      const openingTime = day?.openingTime.split(":");
      return `${openingTime[0]}:${openingTime[1]}`;
    },
    closingTime(day) {
      if (!day) return "";
      const closingTime = day?.closingTime.split(":");
      return `${closingTime[0]}:${closingTime[1]}`;
    },
    openingHours(day) {
      const currentDay = this.schedules?.find(
        (schedule) => schedule.day.toUpperCase().match(day)
      );
      if (!currentDay) return this.$t("closed");
      return `${this.openingTime(currentDay)} - ${this.closingTime(
        currentDay
      )}`;
    },
    isToday(index) {
      return index === DateTime.now().weekday - 1;
    }
  }
};
</script>

<template>
    <div data-testid="drawer-info-hours">
        <DrawerInfoCard class="leading-tight">
            <template #title>{{ $t('tab_info.hours.title') }}</template>
            <div>
                <div v-if="isCurrentlyOpen">
                    <span class="text-secondary-positive">
                        {{ $t('open') }}
                    </span>
                    <span v-if="!isAlwaysOpen">
                        –
                        {{
                            $t('close_at', {
                                time: closingTime(schedules[todaysIndex])
                            })
                        }}
                    </span>
                </div>
                <span v-else>
                    <span class="text-secondary-negative">
                        {{ $t('closed') }}
                    </span>
                </span>
            </div>
            <div v-if="isAlwaysOpen">
                {{ $t('open_always') }}
            </div>
            <div v-else class="flex flex-col gap-2">
                <div
                    v-for="day in days"
                    :key="day"
                    class="inline-flex"
                    :class="isToday(getDayIndex(day)) && 'font-semibold'"
                >
                    <span class="w-40">
                        {{ $t('days.' + day.toLowerCase()) }}
                    </span>
                    <span class="text-left">
                        {{ openingHours(day) }}
                    </span>
                </div>
            </div>
        </DrawerInfoCard>
    </div>
</template>
